import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./location.css";

const MainHalifax = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainHalifaxReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainHalifaxReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay uses best in class encryption to ensure your personal
            information is only used with your authorization. We don’t share
            your payday loan application information with anyone else.
          </div>
        </div>
      ) : null;

    const MainHalifaxReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is one of the top, and most trusted sources of online payday
            loans in Halifax and from coast to coast across Canada. But you
            don’t have to take our word for it – take a look at our customer
            reviews on TrustPilot, check our A+ rating from the Better Business
            Bureau, or review our Nova Scotia Payday Loan License before
            starting your payday loan with us.
          </div>
        </div>
      ) : null;

    const MainHalifaxReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            We are 100% online. You don’t have to walk into a retail store for a
            payday loan in Halifax ever again. The average GoDay online payday{" "}
            <a href="/application">loan application</a> takes five minutes to
            complete. Better yet, the fully automated GoDay system will provide
            you a decision instantly so you can get your online payday loan and
            get back to what matters.
          </div>
        </div>
      ) : null;

    const MainHalifaxReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            We’re here to help! Any time you phone our customer service line
            you’ll be speaking with a real person from our office who will help
            you with any questions you may have. Let us do the work for you.
          </div>
        </div>
      ) : null;

    const MainHalifaxReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            No matter where you’re located; whether it is downtown Halifax or
            off the coast of the Atlantic, GoDay offers speedy, smart and secure
            online payday loans.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainHalifaxReason1 />
        <MainHalifaxReason2 />
        <MainHalifaxReason3 />
        <MainHalifaxReason4 />
        <MainHalifaxReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainHalifaxOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainHalifaxOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainHalifaxOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainHalifaxOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainHalifaxOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainHalifaxOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Nova Scotia</small>
      </h3>
    </section>
  );

  const MainHalifaxSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainHalifaxInitialContent = () => (
    <section>
      <p>
        So how do you get a payday loan online? All you have to do is go to our
        website and complete a quick and easy online payday loan application. As
        long as you put in the correct personal, banking, employment, and
        payroll information into the online payday loan form and we approve you,
        you will have your funds in less than 24 hours.
      </p>
      <p>
        Once your online payday loan application is complete our office will
        contact you to set up your account and discuss how funding works. Our
        automated and online cash advance system will review your application
        and let you know how much we can approve you for. For all Halifax
        residents, online payday loans are regulated by the province of Nova
        Scotia. As a licensed Nova Scotia payday lender our Halifax online
        payday loans range from $100 to $1,500 in value.
      </p>
    </section>
  );

  const MainHalifaxMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          Once you have reviewed and signed your online payday loan documents,
          we will use the industry’s fastest tools to ensure your loan is
          delivered as quickly as possible. Depending on what time throughout
          the day you sign your loan agreement and the hours of your financial
          institution, your payday loan will either be delivered to you in the
          same day or the next morning. A GoDay representative can provide you
          all the necessary information once your online payday loan is
          approved.
        </p>
        <p>
          No matter where you are in Halifax, GoDay can provide you with an
          online payday loan in Halifax on the spot! Join the thousands of
          Canadians from coast to coast that trust GoDay for their online payday
          loan needs. Enjoy that fresh lobster and head down to{" "}
          <a
            href="https://www.novascotia.com/explore/top-25/peggys-cove"
            target="_blank"
            rel="noopener noreferrer"
          >
            Peggy’s Cove
          </a>{" "}
          all while knowing GoDay can provide you with the financial assistance
          quickly and easily.
        </p>
        <p>
          Actions speak louder than words. Here is some feedback from some of
          our existing payday loan customers who have submitted feedback for
          GoDay.ca through TrustPilot – an independent third party website. Be
          sure to check out the{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            GoDay.ca TrustPilot Reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainHalifaxApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Halifax
      </h1>
      <div className="location-text">
        <p>
          Are you trying to stay afloat financially? Here at GoDay we understand
          that life happens and there are unexpected things that pop up that can
          affect your finances. Since 2012, we have been helping Canadians from
          coast to coast get through tough times, and you can now find us
          operating in Nova Scotia! Whether you’re looking for relief to get out
          of a financial bind or help to keep you afloat until your next
          paycheck, learn more about our online payday loans in Halifax are
          helping people like you.
        </p>
        <p>Getting a payday loan in Halifax could not be any simpler!</p>
        <p>
          GoDay offers quick and easy payday or short term loans. And the best
          part? You don’t have to walk into a retail store to apply for a payday
          loan with us. Our online loan applications take less than five minutes
          to complete as we offer one of the fastest payday loan services in
          Canada. We are accepting and approving online payday loan applications
          24/7, which means that you can apply from your computer, smartphone or
          tablet anytime and our highly automated system will do all it can to
          ensure you receive your funds as soon as possible.
        </p>
        <p>
          We make payday loans in Halifax easy so you can get back to what
          matters.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Here is why Halifax trusts GoDay for payday loans
        </h2>
        <MainHalifaxReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Halifax. We
            make it simple for you to access a payday loan by offering:
          </div>
          <MainHalifaxOffer1 />
          <MainHalifaxOffer2 />
          <MainHalifaxOffer3 />
          <MainHalifaxOffer4 />
          <MainHalifaxOffer5 />
          <MainHalifaxOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          You may need an online payday loan if you live in Halifax
        </h2>
        <div className="location-text">
          <p>
            There is no question that Halifax is a beautiful city, but such a
            beautiful place to discover comes at a cost. The price of food and
            housing is continuing to rise and to help you with the prices going
            up in Halifax; you can always turn to GoDay’s online payday loans.
          </p>
          <p>
            Halifax is known for so many wonderful things! From the seafood to
            breweries and natural scenery, there is no shortage of things to do
            and fully understand why Halifax is one of the places to be. And
            GoDay is here to help you enjoy all of these things with our online
            payday loan services.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          What’s the process and how does it work?
        </h2>
        <div className="location-details location-text">
          <MainHalifaxInitialContent />
          <MainHalifaxSeeMore />
          <MainHalifaxMoreContent />
          <MainHalifaxApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainHalifax;
