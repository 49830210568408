import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainPickering = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainPickeringReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainPickeringReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            Responsible online payday loan providers (like us here at GoDay) use
            top level encryptions. This measure is taken to ensure the safety
            and security of your personal information. Furthermore, we only
            use/access your it as authorized and we do not share it with our
            contemporaries from other online payday loan provider or any other
            company that may be interested in such sensitive information.
          </div>
        </div>
      ) : null;

    const MainPickeringReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay online payday loans are fast and easy. Our intuitive website
            makes it easy to{" "}
            <a href="/application">apply online for a payday loan</a> rather
            than worrying about faxing or printing documents and manually
            delivering them to a retail location. From the comfort of your home
            or office (or anywhere you take your mobile devices) your online
            payday loan is processed automatically, and in certain cases can be
            approved in as little as 1 hour! Payday loans in Pickering don’t get
            faster or easier than this.
          </div>
        </div>
      ) : null;

    const MainPickeringReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            We loan you only what you need. If you only need to borrow $100,
            don’t borrow more than that! The goal is to avoid debt, so our
            online payday loans can be adjusted for the amount you need. The
            goal is to avoid debt, not create it.
          </div>
        </div>
      ) : null;

    const MainPickeringReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            GoDay online payday loans aren’t restricted. We respect your
            privacy, and you don’t need to explain what the payday loan is for
            or how you plan to spend it. It’s your right to make your decisions
            with your money, and we encourage that. Unlike other personal or
            installment loan providers that require you to disclose the reason
            for your loan in the application process, we don’t require that
            information and respect your privacy.
          </div>
        </div>
      ) : null;

    const MainPickeringReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            GoDay is a fully licensed, experienced and professional online
            payday lender. We believe you should always know who you’re doing
            business with we encourage you to see our credentials before you get
            started. Many of our clients have rated us online and left positive
            feedback comments, and we encourage you to check them out!
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainPickeringReason1 />
        <MainPickeringReason2 />
        <MainPickeringReason3 />
        <MainPickeringReason4 />
        <MainPickeringReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainPickeringOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 submissions</strong>
        <br />
        <small>for any hour of the day</small>
      </h3>
    </section>
  );

  const MainPickeringOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Secure online processing</strong>
        <br />
        <small>of any online payday loan application you submit to us</small>
      </h3>
    </section>
  );

  const MainPickeringOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Upfront fee disclosure</strong>
        <br />
        <small>so that there will be no surprises later on</small>
      </h3>
    </section>
  );

  const MainPickeringOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Fast cash</strong>
        <br />
        <small>so you can get loans as quickly as you apply for them</small>
      </h3>
    </section>
  );

  const MainPickeringOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>No paperwork or faxing</strong>
        <br />
        <small>for environmental and security purposes</small>
      </h3>
    </section>
  );

  const MainPickeringOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>We are licensed</strong>
        <br />
        <small>in Ontario, and our licensing is easily accessed</small>
      </h3>
    </section>
  );

  const MainPickeringSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainPickeringInitialContent = () => (
    <section>
      <p>
        With nearly 100,000 residents, Pickering is an important hub as nearly 4
        million people live within a 50-km range of the city. Over the years,
        we’ve helped hundreds of customers get an online payday loan in
        Pickering, and we can help you as well. Here at GoDay, we want to help
        educate our customers and build lasting relationships, which is why we
        can lend higher amounts in a Pickering online payday loan to recurring
        customers. New customers can also visit the{" "}
        <a href="/blog/" target="_blank" rel="noopener noreferrer">
          GoDay Blog
        </a>{" "}
        for financial tips and hacks to save money in everyday life and when
        making important financial decisions.
      </p>
      <p>
        Residents of Pickering know just how beautiful it is and how many
        wonderful sights there are to see and activities to engage in. There’s
        the Pickering Museum Village where one could engage in "old timey"
        activities from picking gooseberries to viewing hearth cooking
        demonstrations. There’s Petticoat Creek Conservation Area which has
        ideal trails for biking and a pool area with clean and safe water for
        the kids. You can even visit Waterfront Trail, a historic interconnected
        series of trails that intertwine throughout southern Ontario all the way
        to the border of Quebec.
      </p>
    </section>
  );

  const MainPickeringMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          With so many things of wonderful value, it’s no wonder the residents
          of Pickering eager to fulfill their social responsibilities. At
          GoDay.ca, we understand that and want to do whatever we can to help by
          issuing you an online payday loan so that you can keep everything in
          line.
        </p>
        <p>
          Whether you’re hoping to get groceries, cover some unforeseen expenses
          or head out of for an urgent matter, GoDay.ca is here to help finance
          whatever need you may have. We are willing to do whatever we can to
          help you get an online payday loan in a timely fashion so that you can
          get life going back on a reasonable pace. All you have to do is submit
          an online application, and representatives from GoDay will do what
          they can to help you out.
        </p>
        <p>
          Don’t be afraid to reach out to us. Our entire online payday loan
          process is 100% confidential. We do not contact your workplace and do
          not engage the reference you place on file.
        </p>
        <p>
          GoDay is a fully licensed online payday loan provider, and we care
          about what our customers think. Thankfully, many have shared their
          pleasant experiences and you can learn about the benefits we offer and
          understand <Link to="/why-us">why you should pick GoDay</Link> as your
          online payday loan provider.
        </p>
        <p>
          In addition to the satisfying services we have provided to our
          customers, GoDay.ca is a trusted online payday loan provider. Being
          fully licensed and having A+ accreditation from the Better Business
          Bureau, we have been able to gradually extend our services to other
          provinces over the years and are looking to further expand in over to
          help more Canadians make ends meet should they happen to be in a tight
          spot.
        </p>
      </section>
    );
  };

  const MainPickeringApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Pickering
      </h1>
      <div className="location-text">
        <p>
          Everyday across the country, Canadians are facing tough financial
          decisions due to unexpected expenses. From shockingly high hydro bills
          to higher than anticipated car repairs, a few hundred dollars in
          unforeseen costs can be enough to ruin your monthly budget. Besides
          the aggravation, these bills often come with interest payments and add
          up to more than just debt.
        </p>
        <p>
          Borrowing money can be stressful, but it doesn’t have to be. GoDay is
          your source for online <Link to="/">payday loans</Link> in Pickering,
          and we’re here to help you get through unforeseen bills and expenses.
          We’re a professional, licensed and experienced Canadian online payday
          loan provider that has helped thousands of customers across the
          country. We simplify the payday loan process, offering online payday
          loans that can be approved in as little as 30 mins, without any
          surprises.
        </p>
        <p>
          Since 2012, GoDay.ca has been offering services throughout Canada,
          helping everyday individuals to get by in an unexpected time of need.
          We have maintained many consistent customers who keep coming back
          because our service helps them to regulate imbalances in their
          financial lives. You can feel free to engage this service and receive
          the best customer service GoDay has to offer. All you need to do is
          submit an application for one of GoDay.ca’s{" "}
          <Link to="/education">online payday loan</Link> application.
        </p>
        <p>
          You won’t have to leave the comfort of your home in Pickering to come
          to our office in Toronto. Everything is done 100% online so that you
          can avoid the social hiccups of walking into a payday loan office. We
          know how trying that can be and have resolved to offer loans online.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">Is a payday loan right for you?</h2>
        <div className="location-text">
          Whether it’s a car loan, mortgage or investment, it’s important to
          understand the implications of the financial products you choose,
          especially their costs. Online payday loans are based on your
          paycheck, and the amount you can borrow depends on the amount you have
          coming in. GoDay payday loans in Pickering make it easy to get the
          money you need to solve your short term financial issues, without
          taking on unnecessary debt. We want you to understand{" "}
          <Link to="/education">how a payday loan works</Link>, and our easy to
          calculate expense tool makes sure you won’t be surprised! Wondering if
          a payday loan is right for you? Learn more about how{" "}
          <Link to="/how-it-works">GoDay payday loans work</Link> or contact us
          anytime for more information.
        </div>
        <MainPickeringReasons />
        <div className="location-offers">
          <div className="location-offers-text">
            Our license with the province of Ontario ensures that GoDay.ca can
            issue only online payday loans to residents of Pickering and make it
            easy with:
          </div>
          <MainPickeringOffer1 />
          <MainPickeringOffer2 />
          <MainPickeringOffer3 />
          <MainPickeringOffer4 />
          <MainPickeringOffer5 />
          <MainPickeringOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Choose GoDay online payday loans in Pickering
        </h2>
        <div className="location-details location-text">
          <MainPickeringInitialContent />
          <MainPickeringSeeMore />
          <MainPickeringMoreContent />
          <MainPickeringApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainPickering;
