import { Link } from "react-router-dom";
import "./main-how-it-works.css";

const MainHowItWorks = () => {
  const MainHowItWorksStep1 = () => (
    <section
      className="main-how-it-works-step content clearfix"
      id="main-how-it-works-step-1"
    >
      <div
        className="main-how-it-works-step-icon gallery"
        id="main-how-it-works-step-icon-1"
      >
        <span></span>
      </div>
      <div className="main-how-it-works-step-text">
        <a href="/application">
          submit a GoDay short-term loan application online
        </a>
        , specifying how you’d like your funds delivered and how much you’d like
        to borrow.
      </div>
    </section>
  );

  const MainHowItWorksStep2 = () => (
    <section
      className="main-how-it-works-step content clearfix"
      id="main-how-it-works-step-2"
    >
      <div
        className="main-how-it-works-step-icon gallery"
        id="main-how-it-works-step-icon-2"
      >
        <span></span>
      </div>
      <div className="main-how-it-works-step-text">
        Within seconds of submitting your application, we will let you know if
        you have been pre-approved. Payday loan and installment loan
        applications are accepted and processed instantly by our automated
        system 24/7. Should we need any additional information from you, our
        system will let you know and we’ll be in touch promptly by phone or
        email. If approved, we will use the industry’s fastest tools to transfer
        your loan as soon as possible.
      </div>
    </section>
  );

  const MainHowItWorksStep3 = () => (
    <section
      className="main-how-it-works-step content clearfix"
      id="main-how-it-works-step-3"
    >
      <div
        className="main-how-it-works-step-icon gallery"
        id="main-how-it-works-step-icon-3"
      >
        <span></span>
      </div>
      <div className="main-how-it-works-step-text">
        We’re here to help every step of the way. If you have questions or
        concerns before or after applying, just reach out to one of our friendly
        customer service representatives. They’re standing by at 888-984-6329.
      </div>
    </section>
  );

  const MainHowItWorksStep4 = () => (
    <section
      className="main-how-it-works-step content clearfix"
      id="main-how-it-works-step-4"
    >
      <div
        className="main-how-it-works-step-icon gallery"
        id="main-how-it-works-step-icon-4"
      >
        <span></span>
      </div>
      <div className="main-how-it-works-step-text">
        We keep no secrets about <Link to="/why-us">short-term loans</Link> so
        you’ll have no surprises. Before giving you cash, we promise to disclose
        the full terms of your loan, including loan interest, loan fees, and any
        penalties you’ll be required to pay should you default on your payday
        loan. Depending on your province, payroll cycle, and borrowing history,
        you may be eligible for an installment loan. This will be disclosed to
        you before you sign your loan agreement.
      </div>
    </section>
  );

  const MainHowItWorksStep5 = () => (
    <section
      className="main-how-it-works-step content clearfix"
      id="main-how-it-works-step-5"
    >
      <div
        className="main-how-it-works-step-icon gallery"
        id="main-how-it-works-step-icon-5"
      >
        <span></span>
      </div>
      <div className="main-how-it-works-step-text">
        Once you’re approved, we’ll advance your cash. On the agreed repayment
        date, we’ll collect funds owing directly from your bank account. It’s
        that simple.
      </div>
    </section>
  );

  const MainHowItWorksApplyNow = () => (
    <section className="main-how-it-works-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="main-how-it-works">
      <h1 className="main-how-it-works-title content">
        Get a fast online loan from GoDay
      </h1>
      <div className="main-how-it-works-text content">
        We issue speedy <a href="/application">online payday loans</a> between
        $100 and $1,500 and{" "}
        <Link to="/installment-loans">installment loans</Link> between $1,000
        and $15,000. Create an online account and our system will quickly and
        efficiently process your application for approval.
      </div>
      <MainHowItWorksStep1 />
      <MainHowItWorksStep2 />
      <MainHowItWorksStep3 />
      <MainHowItWorksStep4 />
      <MainHowItWorksStep5 />
      <MainHowItWorksApplyNow />
    </main>
  );
};

export default MainHowItWorks;
