import { useState } from "react";
import { Link } from "react-router-dom";
import "./main-faq.css";

const MainFAQ = () => {
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [isExpanded5, setIsExpanded5] = useState(false);
  const [isExpanded6, setIsExpanded6] = useState(false);
  const [isExpanded7, setIsExpanded7] = useState(false);
  const [isExpanded8, setIsExpanded8] = useState(false);
  const [isExpanded9, setIsExpanded9] = useState(false);
  const [isExpanded10, setIsExpanded10] = useState(false);
  const [isExpanded11, setIsExpanded11] = useState(false);
  const [isExpanded12, setIsExpanded12] = useState(false);
  const [isExpanded13, setIsExpanded13] = useState(false);
  const [isExpanded14, setIsExpanded14] = useState(false);
  const [isExpanded15, setIsExpanded15] = useState(false);
  const [isExpanded16, setIsExpanded16] = useState(false);
  const [isExpanded17, setIsExpanded17] = useState(false);
  const [isExpanded18, setIsExpanded18] = useState(false);
  const [isExpanded19, setIsExpanded19] = useState(false);
  const [isExpanded20, setIsExpanded20] = useState(false);
  const [isExpanded21, setIsExpanded21] = useState(false);
  const [isExpanded22, setIsExpanded22] = useState(false);
  const [isExpanded23, setIsExpanded23] = useState(false);
  const [isExpanded24, setIsExpanded24] = useState(false);
  const [isExpanded25, setIsExpanded25] = useState(false);

  const MainFAQEntry1 = () => {
    const bullet = isExpanded1 ? "remove" : "add";
    const className = isExpanded1 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded1(!isExpanded1);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>What
          service does GoDay provide?
        </h2>
        {isExpanded1 && (
          <p>
            GoDay provides Canadians with online payday loans. Our payday loans
            are not intended to provide a long-term financial solution. Rather,
            they are intended to solve urgent but temporary cash-flow needs.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry2 = () => {
    const bullet = isExpanded2 ? "remove" : "add";
    const className = isExpanded2 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded2(!isExpanded2);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>How do
          I apply for a GoDay payday loan?
        </h2>
        {isExpanded2 && (
          <>
            <p>
              You can apply for an online payday loan with GoDay by completing
              our simple <a href="/application">online application form</a>{" "}
              24/7. The process is quick and easy; we are committed to offering
              one of the fastest, payday loan services in Canada. Within seconds
              of submitting your application, we will let you know if you have
              been pre-approved.
            </p>
            <p>
              Should we need any additional information from you, we’ll be in
              touch promptly by phone or email. If approved, we will use the
              industry’s fastest tools to transfer your online payday loan as
              soon as possible. Our Toronto based Customer Service team is
              available to help along the way should you have any questions or
              concerns. Get started by submitting a loan application{" "}
              <a href="/application">here</a>.
            </p>
          </>
        )}
      </div>
    );
  };

  const MainFAQEntry3 = () => {
    const bullet = isExpanded3 ? "remove" : "add";
    const className = isExpanded3 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded3(!isExpanded3);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>How
          quickly can I receive my payday loan?
        </h2>
        {isExpanded3 && (
          <p>
            GoDay partners with the most reputable financial institutions and
            uses the fastest tools in the industry to ensure you get the
            speediest payday loan possible. Your loan is quite literally on it’s
            way to you once you receive an email from us confirming it is in
            transit. GoDay funds payday loans by direct deposit and email money
            transfer. Actual time to receive your funds depends on your
            financial institution and the time of your application. Once your
            application has been funded, our Customer Service Team will be happy
            to provide an estimated delivery time for your cash advance.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry4 = () => {
    const bullet = isExpanded4 ? "remove" : "add";
    const className = isExpanded4 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded4(!isExpanded4);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>How
          much can I borrow?
        </h2>
        {isExpanded4 && (
          <p>
            Our payday loans range from $100 to $1,500. Please note that there
            are a variety of factors that we consider when reviewing your payday
            loan application. In order to find out how much you qualify for, you
            will need to submit an <a href="/application">online application</a>
            .
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry5 = () => {
    const bullet = isExpanded5 ? "remove" : "add";
    const className = isExpanded5 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded5(!isExpanded5);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>What
          do I need in order to get a GoDay payday loan?
        </h2>
        {isExpanded5 && (
          <>
            <p>To get a GoDay payday loan, you must:</p>
            <p>
              <ul>
                <li>be a Canadian resident over the age of 18</li>
                <li>
                  have an open bank account with a Canadian bank or credit union
                </li>
                <li>get paid by direct deposit</li>
                <li>have your own, valid email account</li>
                <li>provide us with your home or cell phone number</li>
                <li>
                  get paid by direct deposit with “recieve a steady source of
                  income”
                </li>
              </ul>
            </p>
          </>
        )}
      </div>
    );
  };

  const MainFAQEntry6 = () => {
    const bullet = isExpanded6 ? "remove" : "add";
    const className = isExpanded6 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded6(!isExpanded6);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>Do I
          need to be employed?
        </h2>
        {isExpanded6 && (
          <p>
            No! We are accepting customers and funding loans on a variety of
            incomes including your employment income, Canada Child Benefit,
            Disability Tax Credit, Old Age Security, private pensions, and
            Canadian Pension Plan. Complete a fast{" "}
            <a href="/application">payday loan application</a> so we can
            determine if you qualify for an online loan!
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry7 = () => {
    const bullet = isExpanded7 ? "remove" : "add";
    const className = isExpanded7 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded7(!isExpanded7);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>Can I
          get a payday loan if I am not a homeowner?
        </h2>
        {isExpanded7 && (
          <p>
            Yes. Renters are welcome. As long as you can provide a current
            address, details of a regular income, and a guarantee to repay your
            payday loan, we’re happy to consider your application.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry8 = () => {
    const bullet = isExpanded8 ? "remove" : "add";
    const className = isExpanded8 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded8(!isExpanded8);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>Do I
          need a bank account to get an online payday loan from GoDay?
        </h2>
        {isExpanded8 && (
          <p>
            Yes. To take out an online payday loan with GoDay, you must have an
            open bank account with a Canadian bank or credit union so we have
            somewhere to deposit your funds!
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry9 = () => {
    const bullet = isExpanded9 ? "remove" : "add";
    const className = isExpanded9 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded9(!isExpanded9);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>What
          personal information does GoDay need?
        </h2>
        {isExpanded9 && (
          <p>
            GoDay considers personal contact information, employment
            information, and banking information when reviewing online
            applications for payday loans. All information collected is held in
            strict confidence in accordance with user information and security
            guidelines.{" "}
            <Link to="/privacy">
              View our online payday loan privacy policy
            </Link>
            .
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry10 = () => {
    const bullet = isExpanded10 ? "remove" : "add";
    const className = isExpanded10 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded10(!isExpanded10);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>Does
          GoDay perform credit checks?
        </h2>
        {isExpanded10 && (
          <p>
            Yes. GoDay may conduct a credit check as part of our automated
            decision making process. It is likely this will have no impact on
            your credit score, though this does depend on your credit history.
            Please keep in mind that we consider much more than just your credit
            when reviewing your application. To give yourself the best chance of
            acceptance, make sure you provide accurate information on your
            online application. If you're an existing customer, you can always
            update your information with us by contacting GoDay Customer Service
            at{" "}
            <a href="mailto:customerservice@goday.ca">
              customerservice@goday.ca
            </a>
            .
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry11 = () => {
    const bullet = isExpanded11 ? "remove" : "add";
    const className = isExpanded11 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded11(!isExpanded11);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>What
          if I have bad credit?
        </h2>
        {isExpanded11 && (
          <p>
            Credit is one of the many factors our automated system considers
            when reviewing a loan application. GoDay will not necessarily
            decline you if you have poor credit. To give yourself the best
            chance of acceptance, please make sure you provide accurate
            information on your online application. Please note that if you have
            any items in collections with other payday or short term lender our
            system is significantly less likely to accept your loan application.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry12 = () => {
    const bullet = isExpanded12 ? "remove" : "add";
    const className = isExpanded12 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded12(!isExpanded12);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>Do I
          need to give a reason for my application?
        </h2>
        {isExpanded12 && (
          <p>
            No. We do not need to know how you plan to use the money. As long as
            you borrow responsibly and are able to pay your loan back on time,
            we are happy to bridge your cash flow gaps.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry13 = () => {
    const bullet = isExpanded13 ? "remove" : "add";
    const className = isExpanded13 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded13(!isExpanded13);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>What
          if I am already in debt?
        </h2>
        {isExpanded13 && (
          <p>
            GoDay strives to be a responsible lender. Our service is not
            intended to be part of a long-term financial solution, and should
            not be used to repay existing debt. We ask that you apply for a
            GoDay loan only if you are confident in your ability to pay it back
            within the specified time frame – without incurring further debt.
            Please borrow responsibly, and be sure to visit our learning centre,{" "}
            <Link to="/education">Finance 101</Link>, for valuable personal
            finance insights.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry14 = () => {
    const bullet = isExpanded14 ? "remove" : "add";
    const className = isExpanded14 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded14(!isExpanded14);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>How
          much will a loan cost me?
        </h2>
        {isExpanded14 && (
          <p>
            There are no fee surprises! Exact fees are always disclosed on your
            application page, as well as on your actual loan agreement. However,
            should you fail to repay your loan within the specified term, you
            will incur a one time missed payment fee &amp; interest will
            continue to accumulate on your outstanding balance until paid in
            full. This will affect your GoDay Trust Rating.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry15 = () => {
    const bullet = isExpanded15 ? "remove" : "add";
    const className = isExpanded15 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded15(!isExpanded15);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>When
          will I need to pay back my loan?
        </h2>
        {isExpanded15 && (
          <p>
            If you take out a GoDay payday loan, it's due on your next regularly
            scheduled pay date unless your next pay date is in the next two
            days, in which case it's extended to your following pay date. Please
            note that some provinces have extended payment plans available based
            on your borrowing history with GoDay. If this option is available to
            you, it will be disclosed during the loan approval process.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry16 = () => {
    const bullet = isExpanded16 ? "remove" : "add";
    const className = isExpanded16 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded16(!isExpanded16);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>How
          soon can I borrow again?
        </h2>
        {isExpanded16 && (
          <p>
            Since GoDay is committed to responsible lending, our policy is that
            you must repay your current loan with us in full before applying for
            another. Once your payment clears, you are free to apply again! If
            you have any questions or concerns about your long-term financial
            security, please check out our{" "}
            <Link to="/education">Finance 101</Link> page.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry17 = () => {
    const bullet = isExpanded17 ? "remove" : "add";
    const className = isExpanded17 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded17(!isExpanded17);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>How do
          I pay back my loan?
        </h2>
        {isExpanded17 && (
          <p>
            On the agreed date, GoDay will automatically withdraw payment
            directly from your bank account. If you wish to make alternative
            payment arrangements please contact Customer Service at least two
            business days prior to your repayment date. Reach customer service
            toll free at 888-984-6329 or by email{" "}
            <a href="mailto:customerservice@goday.ca">
              customerservice@goday.ca
            </a>
            .
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry18 = () => {
    const bullet = isExpanded18 ? "remove" : "add";
    const className = isExpanded18 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded18(!isExpanded18);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>What
          happens if I don't repay my loan?
        </h2>
        {isExpanded18 && (
          <p>
            If you fail to repay your loan within the specified term, we will
            contact you to arrange payment. Please note that additional fees may
            apply and will be outlined in your GoDay Loan Agreement. Your
            account will be handled by our professional collections team. They
            will do their best to help you manage repayment. If our collections
            team is unable to recover your past due balance within a reasonable
            time frame, we will forward your account to an external collections
            agency. We will all do our best to help you figure out a solution.
            If, however, you fail to cooperate, your credit score will be
            impacted for up to six years, as will your relationship with GoDay.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry19 = () => {
    const bullet = isExpanded19 ? "remove" : "add";
    const className = isExpanded19 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded19(!isExpanded19);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>How do
          public holidays and weekends impact GoDay service?
        </h2>
        {isExpanded19 && (
          <p>
            A loan may only be deposited into your financial institution if it
            is open for business. For regular hours and details of bank holiday
            closures, please consult your financial institution. Also, please
            note that GoDay will be closed on bank and statutory holidays.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry20 = () => {
    const bullet = isExpanded20 ? "remove" : "add";
    const className = isExpanded20 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded20(!isExpanded20);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>What
          if I'm disappointed by GoDay's service?
        </h2>
        {isExpanded20 && (
          <p>
            We want your GoDay experience to be easy and enjoyable. If it is
            anything less, please let us know right away by completing the{" "}
            <a href="/feedback">feedback form</a> or{" "}
            <Link to="/contact">contacting us</Link> by telephone so that we can
            make things right. If, after speaking with one of our customer
            service representatives, your concerns are unresolved, you may{" "}
            <a href="mailto:customerservice@goday.ca">
              submit a formal complaint via email
            </a>
            , after which a member of our senior management team will handle
            your concern personally.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry21 = () => {
    const bullet = isExpanded21 ? "remove" : "add";
    const className = isExpanded21 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded21(!isExpanded21);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>Do you
          have any retail stores?
        </h2>
        {isExpanded21 && (
          <p>
            No. We operate solely online because we understand the need for
            super fast service and the desire for privacy.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry22 = () => {
    const bullet = isExpanded22 ? "remove" : "add";
    const className = isExpanded22 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded22(!isExpanded22);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>How do
          I apply?
        </h2>
        {isExpanded22 && (
          <p>
            Easy! Click the big orange <a href="/application">APPLY NOW</a>{" "}
            button and follow the simple application through to completion. If
            approved, you could see your funds in as little as an hour.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry23 = () => {
    const bullet = isExpanded23 ? "remove" : "add";
    const className = isExpanded23 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded23(!isExpanded23);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>I'm a
          new customer. How much can I be approved for the first time?
        </h2>
        {isExpanded23 && (
          <p>
            We offer loans to approved new customers of up to $500 on their
            first loan with us.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry24 = () => {
    const bullet = isExpanded24 ? "remove" : "add";
    const className = isExpanded24 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded24(!isExpanded24);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>What
          is the maximum I can borrow from GoDay if I've been with you for a
          long time and have repaid every time?
        </h2>
        {isExpanded24 && (
          <p>
            You may be eligible to borrow up to $1,500. Log into your{" "}
            <a href="/account">GoDay Account</a> and submit an{" "}
            <a href="/reloan">Express Re-Loan</a> application online.
          </p>
        )}
      </div>
    );
  };

  const MainFAQEntry25 = () => {
    const bullet = isExpanded25 ? "remove" : "add";
    const className = isExpanded25 ? "expanded" : "collapsed";
    const onExpand = () => setIsExpanded25(!isExpanded25);

    return (
      <div className={`main-faq-entry ${className}`}>
        <h2
          className={`main-faq-entry-question ${className}`}
          onClick={onExpand}
        >
          <i className="material-icons main-faq-entry-bullet">{bullet}</i>Can I
          visit your office to get a payday loan?
        </h2>
        {isExpanded25 && (
          <p>
            No need to visit us! All applications for payday loans are collected
            and processed online. As such, we do not accept in person
            applications or dispense cash at our office locations. If you wish
            to get an online payday loan you must complete an application{" "}
            <a href="/application">here</a>. If you have questions along the way
            about your application for an online cash advance, give us a call
            toll free at 888-984-6329.
          </p>
        )}
      </div>
    );
  };

  const MainFAQContent1 = () => (
    <section className="main-faq-left-group">
      <MainFAQEntry1 />
      <MainFAQEntry2 />
      <MainFAQEntry3 />
      <MainFAQEntry4 />
      <MainFAQEntry5 />
      <MainFAQEntry6 />
      <MainFAQEntry7 />
      <MainFAQEntry8 />
      <MainFAQEntry9 />
      <MainFAQEntry10 />
      <MainFAQEntry11 />
      <MainFAQEntry12 />
      <MainFAQEntry13 />
    </section>
  );

  const MainFAQContent2 = () => (
    <section className="main-faq-right-group">
      <MainFAQEntry14 />
      <MainFAQEntry15 />
      <MainFAQEntry16 />
      <MainFAQEntry17 />
      <MainFAQEntry18 />
      <MainFAQEntry19 />
      <MainFAQEntry20 />
      <MainFAQEntry21 />
      <MainFAQEntry22 />
      <MainFAQEntry23 />
      <MainFAQEntry24 />
      <MainFAQEntry25 />
    </section>
  );

  return (
    <main className="main-faq">
      <h1 className="main-faq-title content">Payday loan FAQ</h1>
      <div className="main-faq-text content">
        We’ve done our best to cover them all, but if we haven’t answered your
        question below, please feel free to{" "}
        <Link to="/contact">contact us</Link>.
      </div>
      <MainFAQContent1 />
      <MainFAQContent2 />
    </main>
  );
};

export default MainFAQ;
