import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./location.css";

const MainHamilton = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainHamiltonReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainHamiltonReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            Your privacy matters. At GoDay, we use the best in class encryption
            to ensure your personal information is only used with your
            authorization. We don’t share your payday loan application
            information with anyone else including other online payday loan
            contemporaries or in person payday loan lenders.
          </div>
        </div>
      ) : null;

    const MainHamiltonReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            People know the GoDay name. GoDay is one of the top, and most
            trusted sources of online payday loans in Hamilton and across
            Canada. But you don’t have to take our word for it – take a look at
            our customer reviews on TrustPilot, check our A+ rating from the
            Better Business Bureau, or review our Ontario Payday Loan License
            before starting your payday loan with us.
          </div>
        </div>
      ) : null;

    const MainHamiltonReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            Everything is 100% online. Why walk into a retail store for a payday
            loan in Hamilton ever again? In five minutes you can complete a
            GoDay payday <a href="/application">loan application</a> online and
            our automated system will let you know instantly if you’re
            pre-approved.
          </div>
        </div>
      ) : null;

    const MainHamiltonReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            We’re a Canadian company! GoDay loves Hamilton and we are here for
            you when you find yourself in a financial bind. Our customer service
            team is readily available to help you with any questions or concerns
            you may have when it comes to your payday loan. GoDay’s online
            payday loans in Hamilton are made to be simple – so give us a shout
            and say hi to our team.
          </div>
        </div>
      ) : null;

    const MainHamiltonReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            No matter where you’re located; at the top of the Hamilton Mountain
            or right downtown, GoDay offers speedy, smart and secure online
            payday loans.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainHamiltonReason1 />
        <MainHamiltonReason2 />
        <MainHamiltonReason3 />
        <MainHamiltonReason4 />
        <MainHamiltonReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainHamiltonOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainHamiltonOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainHamiltonOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainHamiltonOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainHamiltonOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainHamiltonOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Ontario</small>
      </h3>
    </section>
  );

  const MainHamiltonSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainHamiltonInitialContent = () => (
    <section>
      <p>
        An online payday loan from GoDay.ca can help you explore the city of
        Hamilton without having to worry about unplanned expenses. So how do you
        get a payday loan online with us? The first step is to head to our
        website to complete our simple online payday loan application. We will
        then ask you for some personal information such as your personal
        banking, employment status, and payroll information. Once you have
        filled out the online payday loan form with the correct information, you
        will then be approved and have your funds within 24 hours. Simple as
        that!
      </p>
      <p>
        Once your online payday loan application is complete our office will
        contact you to set up your account and discuss how funding works. Our
        automated and online cash advance system will review your application
        and let you know how much we are able to approve you for. For Hamilton
        residents, online payday loans are regulated by the province of Ontario.
        GoDay is a licensed Ontario payday lender, and our Hamilton online
        payday loans range from $100 to $1,500 in value.
      </p>
    </section>
  );

  const MainHamiltonMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          Once you have reviewed and signed your online payday loan documents,
          we will use the industry’s fastest tools to ensure your loan is
          delivered as quickly as possible. The tools that we use are specific
          to our industry, reliable and able to ensure that your cash advance is
          delivered quickly. Receiving your payday loan on the same day or
          overnight depends on a few small factors: the time of day you are
          approved, the time you sign the loan agreement and your financial
          institution’s operating hours. A GoDay representative can provide you
          all the necessary information once your online payday loan is
          approved.
        </p>
        <p>
          GoDay can provide you with an online payday loan in Hamilton on the
          spot! Join the thousands of Canadians from coast to coast that trust
          GoDay for their online payday loan needs. So enjoy the newest brewery
          or head down to{" "}
          <a
            href="https://tourismhamilton.com/webster-falls-guide"
            target="_blank"
            rel="noopener noreferrer"
          >
            Webster Falls
          </a>
          , you will have peace of mind knowing that GoDay can provide you with
          the financial assistance you may need quickly and easily.
        </p>
        <p>
          Don’t take our word for it. Check out the feedback from some of our
          existing payday loan customers who have submitted feedback for
          GoDay.ca through TrustPilot – an independent third party website. See
          what they’re saying about us here:{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            GoDay.ca TrustPilot Reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainHamiltonApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Hamilton
      </h1>
      <div className="location-text">
        <p>
          Life happens! And sometimes it comes at a cost. You cannot always
          predict when your car will need repairs, your laptop will decide to
          shut down permanently or how much an unexpected trip to the dentist
          may cost you. No matter the situation, here at GoDay we understand
          that life happens and are here to help you get through these
          financially tough times. Our online payday loans are available in
          Hamilton for the days your car breaks down on the side of the Hamilton
          Mountain. Since 2012, we have been helping Canadians get over those
          financial hurdles. Learn more about our online payday loans in
          Hamilton are helping people like you.
        </p>
        <p>
          Getting a payday loan in Hamilton could not be easier! So how do you
          get a simple and secure online payday loan in Hamilton? At GoDay we
          like to keep it simple; fast cash for you with the security and
          professionalism along with it. GoDay partners with the most reputable
          financial institutions and uses the fastest and most secure tools in
          the industry to ensure you get the speediest service possible. Not to
          mention GoDay is accepting and approving online payday loan
          applications in Hamilton and across Canada 24 hours a day, seven days
          a week. GoDay also has a “what you see is what you get” policy in
          place because we don’t believe in hidden fees. This means that all
          fees are disclosed up-front so when you’re looking at a payday loan
          with GoDay, the numbers you see won’t change. So why walk into a store
          when you can get an online payday loan with us?
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why Hamilton trusts GoDay for payday loans
        </h2>
        <MainHamiltonReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So what are you waiting for Hamilton? Apply for a payday loan
              online. We make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Hamilton. We
            make it simple for you to access a payday loan by offering:
          </div>
          <MainHamiltonOffer1 />
          <MainHamiltonOffer2 />
          <MainHamiltonOffer3 />
          <MainHamiltonOffer4 />
          <MainHamiltonOffer5 />
          <MainHamiltonOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          You may need an online payday loan if you live in Hamilton
        </h2>
        <div className="location-text">
          <p>
            It’s no surprise that Hamilton has become one of the most liveable
            cities in Ontario, but with such a rise in popularity, it comes with
            a cost. And as much as Hamilton may be considered affordable in
            comparison to its neighbouring cities, it can still cost you. That’s
            why you can always turn to GoDay’s online payday loans to help you
            with the rising costs in Hamilton.
          </p>
          <p>
            Hamilton is known for so many wonderful things and continues to be a
            growing city! From chasing waterfalls to the festivals and rich
            history of the city, there is no shortage of things to do in
            Hamilton and we understand why it is continues to be of the best
            liveable cities. And here at GoDay, we want to make sure you are
            getting all you can from the place you call home with our online
            payday loan services.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          What’s the process and how does it work?
        </h2>
        <div className="location-details location-text">
          <MainHamiltonInitialContent />
          <MainHamiltonSeeMore />
          <MainHamiltonMoreContent />
          <MainHamiltonApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainHamilton;
