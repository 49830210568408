import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainManitoba = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainManitobaReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainManitobaReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            Are you looking to get a quick and easy payday loan in Manitoba?
            GoDay gets you fast cash when you need it most with the security and
            professionalism along with it. As an online company, your privacy
            and security are our top priority which is why we use the most
            advanced tools and only use your personal information with your
            authorization. You can also count on us to make sure your
            information is never shared.
          </div>
        </div>
      ) : null;

    const MainManitobaReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            In Manitoba and across Canada, people have been turning to GoDay
            when they need us the most. We have an A+ accreditation rating from
            the Better Business Bureau and also have hundreds of reviews on{" "}
            <a
              href="https://ca.trustpilot.com/review/goday.ca?utm_medium=trustbox&amp;utm_source=Carousel"
              target="_blank"
              rel="noopener noreferrer"
            >
              TrustPilot
            </a>{" "}
            from our customers just like you. See what they have to say about us
            today.
          </div>
        </div>
      ) : null;

    const MainManitobaReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            At GoDay, we want to make sure you never have to have the hassle of
            walking into a store in Manitoba for a payday loan again. GoDay is
            100% online and you can apply for a payday loan in Manitoba or from
            anywhere on your personal device. And with our automated system, you
            will have an instant decision about your payday loan. Why wait to
            get the cash you need? Visit our website and start an{" "}
            <a href="/application">online payday loan application</a> today.
          </div>
        </div>
      ) : null;

    const MainManitobaReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            Have questions for us? GoDay may be 100% online but give us a call
            us to speak with one of our customer service team members. They will
            be able to help answer any of the questions you may have when it
            comes to getting an online payday loan with us.{" "}
            <Link to="/contact">Contact us</Link> today to see how to can help.
          </div>
        </div>
      ) : null;

    const MainManitobaReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            Since 2012, GoDay has been offering speedy, smart and secure online
            payday loans to people like you. So, whether you are located in
            Manitoba, choose GoDay for your next online payday loan.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainManitobaReason1 />
        <MainManitobaReason2 />
        <MainManitobaReason3 />
        <MainManitobaReason4 />
        <MainManitobaReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainManitobaOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainManitobaOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainManitobaOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainManitobaOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainManitobaOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainManitobaOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Manitoba</small>
      </h3>
    </section>
  );

  const MainManitobaSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainManitobaInitialContent = () => (
    <section>
      <p>
        It has never been easier to get an online payday loan with GoDay. And
        the first step is to get started with our online application process. We
        will ask you a few questions such as your banking, employment and
        payroll information to get you started. Once you have submitted the most
        up to date and correct information, our automated system will give you
        an immediate decision and let you know if you have been approved.
      </p>
      <p>
        Once your online loan application has been signed and submitted, it will
        then be reviewed by our automated system to determine how much we can
        approve your loan for, and our customer service team will get in touch
        with you to get your GoDay account set up and go over your funding
        options. At that time, we will also let you know how we will be getting
        you the cash you need. Our online payday loans are regulated by the
        province of Manitoba and we’re able to provide our customers with loans
        ranging from $100 to $1,500.
      </p>
    </section>
  );

  const MainManitobaMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          After reviewing your loan details, you will sign your payday loan
          documents and then leave the rest to us. Once your online payday loan
          has been approved, we use the industry’s fastest tools to get your
          loan as soon as possible. Based on the business hours of your
          financial institution and when we receive your signed payday loan
          agreement, this will determine when you receive your payday loan. At
          GoDay we work hard to make sure you get the cash you need within the
          same day or within the next business day. That’s right, within 24
          hours you will see your payday loan in your account. A GoDay
          representative will be in touch along the way and be able to provide
          you with all the necessary information and assist with any questions
          you may have about your loan.
        </p>
        <p>
          From coast to coast, Canadians like you choose GoDay for their online
          payday loans. Want to know what our customers have to say about us?
          Take a look at our{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            TrustPilot reviews
          </a>{" "}
          to see why our customers continually choose GoDay for their payday and
          short-term loans.
        </p>
      </section>
    );
  };

  const MainManitobaApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast, online payday loan in Manitoba
      </h1>
      <div className="location-text">
        <p>
          Is it payday yet? Here at GoDay, we know that it can’t come soon
          enough. When bills are due or there has been a financial emergency and
          you need help bridging the gap - that’s where GoDay can help. GoDay
          has been helping Canadians get to their next paycheque since 2012 by
          offering online payday loans in Manitoba and across Canada. Our
          customers trust GoDay to get them the cash they need quickly and
          easily. Learn more about how our online payday loans are helping
          Manitoba resident’s get the financial relief they need today.
        </p>
        <p>
          Payday loan or short-term loan, no matter what you like to call it, we
          understand that you have options when it comes to your finances. When
          it comes to getting a payday loan in Manitoba, look no further GoDay.
          Why do people choose GoDay for their payday loans? It’s our easy
          application process, how quickly we get you the cash you need, our
          amazingly responsive customer service team, and more. Another one of
          the best things about GoDay is never having to walk into a physical
          store for a payday loan again. With GoDay you can apply for an online
          payday loan with us from virtually anywhere! Our application tool is
          available 24 hours a day, seven days a week. Get an online payday loan
          with us from the comfort and privacy of your own home or while you are
          on the go with your favourite device.
        </p>
        <p>
          Whether you are looking for fast cash, to get yourself out of a
          financial bind, or to help keep yourself afloat until your next
          paycheck, GoDay is here for the residents of Manitoba when you need us
          the most.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why Manitoba trusts GoDay for online payday loans
        </h2>
        <MainManitobaReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So, what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Manitoba. We
            make it simple for you to access a payday loan by offering:
          </div>
          <MainManitobaOffer1 />
          <MainManitobaOffer2 />
          <MainManitobaOffer3 />
          <MainManitobaOffer4 />
          <MainManitobaOffer5 />
          <MainManitobaOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Choose GoDay for a payday loan in Manitoba Today
        </h2>
        <div className="location-text">
          <p>
            As a one of Canada’s prairie provinces, Manitoba offers fascinating
            historical and artistic landmarks. Whether you are headed to the
            Canadian Museum for Human Rights or visiting the polar bears at the
            Assiniboine Park Zoo, GoDay is here for Manitoba. Don’t worry about
            making time to have to go get a payday loan ever again – because in
            Manitoba, GoDay’s quick and easy payday loans provide you with the
            cash you need to help you get back on track.
          </p>
          <p>
            There are many reasons why people love to call Manitoba home. The 54
            provincial parks and optimal green space for outdoor adventures, to
            the rich history of the Forks with its central market with paths
            overlooking the riverbank. With all of this and more - GoDay’s
            online payday loans are here for you so you can enjoy all the
            amazing things Manitoba has to offer.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Getting an online payday loan in Manitoba has never been easier
        </h2>
        <div className="location-details location-text">
          <MainManitobaInitialContent />
          <MainManitobaSeeMore />
          <MainManitobaMoreContent />
          <MainManitobaApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainManitoba;
