import { Link } from "react-router-dom";
import "./main-why-us.css";

const MainWhyUs = () => {
  const MainWhyUsReason1 = () => (
    <section
      className="main-why-us-reason content clearfix"
      id="main-why-us-reason-1"
    >
      <div
        className="main-why-us-reason-icon gallery"
        id="main-why-us-reason-icon-1"
      >
        <span></span>
      </div>
      <div className="main-why-us-reason-group">
        <h2 className="main-why-us-reason-title">
          GoDay gives fast and easy access to cash.
        </h2>
        <div className="main-why-us-reason-text">
          GoDay partners with the most reputable financial institutions and uses
          the fastest tools in the industry to ensure you get the speediest
          service possible. We accept and approve{" "}
          <a href="/application">
            online payday loan and online installment loan applications
          </a>{" "}
          24/7. Apply from your computer, smart phone or tablet, and our highly
          automated system will do all it can to ensure you receive your funds
          as soon as possible.
        </div>
      </div>
    </section>
  );

  const MainWhyUsReason2 = () => (
    <section
      className="main-why-us-reason content clearfix"
      id="main-why-us-reason-2"
    >
      <div
        className="main-why-us-reason-icon gallery"
        id="main-why-us-reason-icon-2"
      >
        <span></span>
      </div>
      <div className="main-why-us-reason-group">
        <h2 className="main-why-us-reason-title">Same day cash.</h2>
        <div className="main-why-us-reason-text">
          Get your cash in as little as one hour once your loan has been
          approved. We can deposit your money straight into your account or
          transfer it to you via Interac<sup>&reg;</sup> Email Money Transfer
          (conditions apply).
        </div>
      </div>
    </section>
  );

  const MainWhyUsReason3 = () => (
    <section
      className="main-why-us-reason content clearfix"
      id="main-why-us-reason-3"
    >
      <div
        className="main-why-us-reason-icon gallery"
        id="main-why-us-reason-icon-3"
      >
        <span></span>
      </div>
      <div className="main-why-us-reason-group">
        <h2 className="main-why-us-reason-title">We lend responsibly.</h2>
        <div className="main-why-us-reason-text">
          Some lenders would be willing to give you the shirts off their backs
          if they could charge you interest indefinitely. Not us. Our{" "}
          <Link to="/how-it-works">short-term payday loan</Link> is intended to
          help get you back on track - not further off it. Be sure to check out
          our <Link to="/education">Finance 101</Link> page as well as the{" "}
          <a href="/blog/" target="_blank" rel="noopener noreferrer">
            GoDay Blog
          </a>{" "}
          for resources and weekly updates ranging from budgeting tips and
          tricks to payday loan related educational material and resources.
        </div>
      </div>
    </section>
  );

  const MainWhyUsReason4 = () => (
    <section
      className="main-why-us-reason content clearfix"
      id="main-why-us-reason-4"
    >
      <div
        className="main-why-us-reason-icon gallery"
        id="main-why-us-reason-icon-4"
      >
        <span></span>
      </div>
      <div className="main-why-us-reason-group">
        <h2 className="main-why-us-reason-title">
          What you see is what you get.
        </h2>
        <div className="main-why-us-reason-text">
          Nobody likes surprises when it comes to money. That’s why we keep no
          secrets. Totally transparent, we disclose all fees up-front, including
          those you’ll be required to pay if you default on your loan, which we
          hope never happens. Unlike many online lenders, we do not exist in
          cyberspace alone. We’re a bona fide payday lender with physical
          offices in <Link to="/location-services/toronto">Toronto</Link>,{" "}
          <Link to="/location-services/vancouver">Vancouver</Link>, Calgary,{" "}
          <Link to="/location-services/regina">Regina</Link>, Fredericton, and
          Halifax. What’s more, it’s staffed by real people who care about your
          financial wellness.
        </div>
      </div>
    </section>
  );

  const MainWhyUsReason5 = () => (
    <section
      className="main-why-us-reason content clearfix"
      id="main-why-us-reason-5"
    >
      <div
        className="main-why-us-reason-icon gallery"
        id="main-why-us-reason-icon-5"
      >
        <span></span>
      </div>
      <div className="main-why-us-reason-group">
        <h2 className="main-why-us-reason-title">
          Our professional service means your peace of mind.
        </h2>
        <div className="main-why-us-reason-text">
          A payday loan or personal loan should alleviate your financial stress,
          not add to it. That’s why it’s so important to borrow from a lender
          you can truly trust. At GoDay, we’re compliant with provincial loan
          regulations, which means you’re protected legally with every cash
          advance. What’s more, our customer service representatives are
          professional, experienced, and available to answer any questions you
          may have with respect to your privacy, your obligations, and your
          rights.
        </div>
      </div>
    </section>
  );

  const MainWhyUsReason6 = () => (
    <section
      className="main-why-us-reason content clearfix"
      id="main-why-us-reason-6"
    >
      <div
        className="main-why-us-reason-icon gallery"
        id="main-why-us-reason-icon-6"
      >
        <span></span>
      </div>
      <div className="main-why-us-reason-group">
        <h2 className="main-why-us-reason-title">Our customers come first.</h2>
        <div className="main-why-us-reason-text">
          While we welcome the opportunity to provide you with an online payday
          loan or personal loan, we want your{" "}
          <a href="/application">short-term loan</a> needs to be few and far
          between. That’s why you’ll notice a prominent link to{" "}
          <Link to="/education">Finance 101</Link> on every page of this
          website. Finance 101 is our learning centre. There, you’ll find handy
          calculators, links to credit counsellors, useful apps, budget
          worksheets, and more. We’ve also gone to lengths to create a customer
          community where you can exchange ideas and insights with others in
          your situation. To become part of this community, follow our{" "}
          <a href="/blog/" target="_blank" rel="noopener noreferrer">
            blog
          </a>{" "}
          and join us on{" "}
          <a
            href="https://www.facebook.com/GoDay.ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
          .
        </div>
      </div>
    </section>
  );

  const MainWhyUsApplyNow = () => (
    <section className="main-why-us-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="main-why-us">
      <h1 className="main-why-us-title content">
        Fast and efficient loans since 2012
      </h1>
      <MainWhyUsReason1 />
      <MainWhyUsReason2 />
      <MainWhyUsReason3 />
      <MainWhyUsReason4 />
      <MainWhyUsReason5 />
      <MainWhyUsReason6 />
      <MainWhyUsApplyNow />
    </main>
  );
};

export default MainWhyUs;
