import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./location.css";

const MainWaterloo = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainWaterlooReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainWaterlooReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay takes your privacy seriously. GoDay uses the most advanced
            industry practices to ensure your data is safe and never used
            without your consent. We don’t share any of your private online
            payday loan information with anyone else.
          </div>
        </div>
      ) : null;

    const MainWaterlooReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is one of the top, and most trusted sources of online payday
            loans in Waterloo and from across Canada. And you don’t have to take
            our word for it, take a look at our customer reviews on TrustPilot,
            check our A+ rating from the Better Business Bureau, or review our
            Ontario Payday Loan License before starting your payday loan with
            us.
          </div>
        </div>
      ) : null;

    const MainWaterlooReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            GoDay operates 100% online, which means you don’t have to walk into
            a retail store for a payday loan in Waterloo ever again. Our online
            payday loan application takes five minutes to complete and our fully
            automated system will provide you a decision in just minutes. Get
            your online payday loan in Waterloo quickly so you can get back to
            what matters.
          </div>
        </div>
      ) : null;

    const MainWaterlooReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            We’re here to help! Do you have questions about your online payday
            loan? Give us a call. Any time you phone our customer service line
            you’ll be speaking with a real person from our office who will help
            you with any questions you may have. Let us do the work for you.
          </div>
        </div>
      ) : null;

    const MainWaterlooReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            No matter where you’re located in Waterloo or the surrounding area,
            GoDay offers speedy, smart and secure online payday loans for people
            like you.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainWaterlooReason1 />
        <MainWaterlooReason2 />
        <MainWaterlooReason3 />
        <MainWaterlooReason4 />
        <MainWaterlooReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainWaterlooOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainWaterlooOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainWaterlooOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainWaterlooOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainWaterlooOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainWaterlooOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Ontario</small>
      </h3>
    </section>
  );

  const MainWaterlooSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainWaterlooInitialContent = () => (
    <section>
      <p>
        It’s easy getting a payday loan online with GoDay. Head over to our
        website and complete a quick and easy online payday loan application.
        You will need to provide us with some personal information such as your
        banking, employment, and payroll into the online payday loan
        application. As long as your information is correct and you are
        approved, you will have your funds in less than 24 hours.
      </p>
      <p>
        Once your online payday loan application is complete our office will
        reach out to you to set up your GoDay account and discuss how funding
        works. Our automated and online cash advance system will review your
        application and let you know how much we can approve you for. For all
        Waterloo residents, online payday loans are regulated by the province of
        Ontario and as a licensed payday lender of Ontario, our Waterloo online
        payday loans range from $100 to $1,500 in value.
      </p>
    </section>
  );

  const MainWaterlooMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          After you’ve signed your payday loan documents, we will use the
          industry’s best tools to get you your online payday loan quickly and
          easily.
        </p>
        <p>
          From there, you will receive your payday loan! Now depending on what
          time of the day you sign your loan agreement and the hours of your
          financial institution, your payday loan will either be delivered to
          you that the same day or overnight and you will wake up to it in your
          bank account the next morning. A GoDay representative can provide you
          with further details once your online payday loan is approved.
        </p>
        <p>
          No matter where you are in Waterloo Region, GoDay can provide you with
          an online payday loan on the spot! So go visit the animals at Waterloo
          Park, have a drink at one of the craft breweries or grab your skates
          and head to the rink at Waterloo Public Square all while knowing GoDay
          is here for you whenever you need us.
        </p>
        <p>
          Want to learn more about us? Here from our clients first hand by
          checking out some of our{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            TrustPilot Reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainWaterlooApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Waterloo
      </h1>
      <div className="location-text">
        <p>
          Sudden expenses pop up unexpectedly all the time and you may not have
          the cash readily available to help cover those costs. There are
          options out there for you. It may not be easy for you to go to a
          family member to borrow some cash and banks aren’t always willing to
          give people a loan. Whatever the case may be, there are many reasons
          why people turn to payday loans. And since 2012, people have been
          turning to GoDay for their online payday loans in Waterloo because of
          how quick and easy we are.
        </p>
        <p>Getting a payday loan in Waterloo could not be any easier!</p>
        <p>
          Why GoDay? For an easy and quick provider of online payday loans in
          Waterloo, we work hard to make sure that you get the cash you need
          quickly. In fact, our application process takes only minutes as we are
          accepting and approving online payday loan applications 24/7. And with
          our online payday loans you can receive up to $1,500 within 24 hours
          with our highly automated system. We work quickly so you can get your
          funds as soon as possible and get back to what matters.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why choose GoDay for online payday loans in Waterloo?
        </h2>
        <MainWaterlooReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              Go ahead Waterloo; apply for a payday loan online. We make it
              quick and simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Waterloo. We
            make it simple for you to access a payday loan by offering:
          </div>
          <MainWaterlooOffer1 />
          <MainWaterlooOffer2 />
          <MainWaterlooOffer3 />
          <MainWaterlooOffer4 />
          <MainWaterlooOffer5 />
          <MainWaterlooOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          You may need an online payday loan if you live in Waterloo
        </h2>
        <div className="location-text">
          <p>
            We know what it’s like to live in Waterloo. In fact, it was the city
            that the idea of GoDay first started in, which is why we understand
            that online payday loans from GoDay can be needed.
          </p>
          <p>
            Waterloo is known for so many wonderful things! From being home to
            Google’s Canadian office and one of the biggest technology hubs in
            Canada, to having a huge student population at some of Canada’s
            leading Universities, Waterloo can be a busy place filled with
            opportunity. And GoDay is here to help you enjoy all of these things
            with our online payday loan services.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          What’s the process and how does it work?
        </h2>
        <div className="location-details location-text">
          <MainWaterlooInitialContent />
          <MainWaterlooSeeMore />
          <MainWaterlooMoreContent />
          <MainWaterlooApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainWaterloo;
