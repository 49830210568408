declare module "@material-ui/styles" {
  interface DefaultTheme {
    breakpoints: {
      up: (key: number) => string;
    };
    palette: {
      primary: string;
      secondary: string;
      text: string;
      border: string;
      background: string;
    };
    typography: {};
    spacing: (...args: any[]) => string;
  }
}

const createBreakpoints = () => {
  const up = (value: number) => `@media (min-width: ${value}px)`;
  return { up };
};

const createPalette = () => ({
  primary: "#77bb1f",
  secondary: "#fcb713",
  text: "#77777a",
  border: "#f5f5f5",
  background: "#f5f5f5",
});

const createTypography = () => ({});

const createSpacing = () => (...args: any[]) => {
  const base = 10;
  const transform = (factor: number | string) =>
    typeof factor === "number" ? `${base * factor}px` : factor;
  switch (args.length) {
    case 0:
      return transform(1);
    case 1:
      return transform(args[0]);
    default:
      return args
        .slice(0, 4)
        .map((factor) => transform(factor))
        .join(" ");
  }
};

export const createTheme = () => {
  const breakpoints = createBreakpoints();
  const palette = createPalette();
  const typography = createTypography();
  const spacing = createSpacing();
  return { breakpoints, palette, typography, spacing };
};
