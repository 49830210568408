import { Link } from "react-router-dom";
import "./basic-header.css";

const BasicHeader = () => (
  <section className="basic-header content gallery">
    <Link className="basic-header-logo" to="/" />
  </section>
);

export default BasicHeader;
