import { Link } from "react-router-dom";
import pages from "configurations/pages.json";
import "./basic-location.css";
import flag from "assets/images/flag.png";

const BasicLocation = () => {
  const locationIds = [
    "ontario",
    "british-columbia",
    "nova-scotia",
    "alberta",
    "new-brunswick",
    "saskatchewan",
    "prince-edward-island",
    "manitoba",
    "toronto",
    "vancouver",
    "halifax",
    "calgary",
    "edmonton",
    "charlottetown",
    "mississauga",
    "regina",
    "saskatoon",
    "ottawa",
    "hamilton",
    "kitchener",
    "victoria",
    "scarborough",
    "barrie",
    "oshawa",
    "london",
    "oakville",
    "kingston",
    "ajax",
    "waterloo",
    "pickering",
    "brampton",
    "guelph",
    "surrey",
    "dartmouth",
    "winnipeg",
  ];
  const BasicLocationServices = () => (
    <section className="basic-location-services">
      <h2 className="basic-location-title">
        Online payday loans across Canada
      </h2>
      <div>
        At GoDay we’re a licensed online payday lender, a proud member of our
        community, and we care about our customers. Try our service and let us
        show you why thousands of Canadians turn to GoDay when they need a
        payday loan. We proudly serve locations across Canada including:
      </div>
      <nav className="basic-location-cities">
        <ul>
          {locationIds.map((locationId) => (
            <li key={`location-${locationId}`}>
              <Link to={`/location-services/${locationId}`}>
                {(pages as any)[locationId].name}
              </Link>
            </li>
          ))}
          <li>
            <Link to="/location-services">More</Link>
          </li>
        </ul>
      </nav>
    </section>
  );

  const BasicLocationFlag = () => (
    <section className="basic-location-flag gallery">
      <img src={flag} alt="Canadian Flag" />
    </section>
  );

  return (
    <section className="basic-location content">
      <BasicLocationServices />
      <BasicLocationFlag />
    </section>
  );
};

export default BasicLocation;
