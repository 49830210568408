import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./location.css";

const MainLondon = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainLondonReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainLondonReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay uses the most secure encryption to ensure your personal
            information is safe and never used without your authorization. We
            never share your online payday loan application information with
            anyone else.
          </div>
        </div>
      ) : null;

    const MainLondonReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is a trusted source for online payday loans in London and
            across Canada. Don’t take our word for it – check out our reviews on
            TrustPilot from customers like you, our A+ accreditation by the
            Better Business Bureau, or review our Ontario Payday Loan License
            before choosing us for your payday loan.
          </div>
        </div>
      ) : null;

    const MainLondonReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            GoDay is 100% online. We do have physical offices across Canada, but
            we only operate online. This way you don’t have to walk into a
            retail store for a payday loan in London again. The average GoDay
            online payday loan application takes five minutes to complete.
            Better yet, our fully automated GoDay system provides you with a
            decision about your loan 24/7 so you can get back to what matters.
          </div>
        </div>
      ) : null;

    const MainLondonReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            We’re here to help! Any time you phone our customer service line
            you’ll be speaking with a real person from our office who will be
            happy to help you with any questions you may have. Feel free to
            reach out to us today.
          </div>
        </div>
      ) : null;

    const MainLondonReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            No matter where you’re located; whether it is downtown London or
            anywhere in between, GoDay offers speedy, smart and secure online
            payday loans for people like you.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainLondonReason1 />
        <MainLondonReason2 />
        <MainLondonReason3 />
        <MainLondonReason4 />
        <MainLondonReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainLondonOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainLondonOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainLondonOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainLondonOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainLondonOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainLondonOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Ontario</small>
      </h3>
    </section>
  );

  const MainLondonSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainLondonInitialContent = () => (
    <section>
      <p>
        When it comes to getting a GoDay payday loan, it’s simple, speedy and
        secure. All you have to do is head to our website to fill out an online
        payday loan application with all of your correct personal information.
        Make sure you double check your application as we need the most accurate
        information in order to process your online payday loan. Your
        application for may be declined if the information you provide is
        inaccurate and/or unable to be validated by our online payday loan
        system.
      </p>
      <p>
        Once we have received your loan application our customer service team
        will contact you by phone or email to set up your GoDay account and
        discuss your funding options. Your application will be reviewed by our
        automated and online cash advance system to determine how much we can
        approve you for. Our online payday loans are regulated by the province
        of Ontario for London residents and, as a licensed payday lender, our
        online payday loans for London range from $100 to $1,500.
      </p>
    </section>
  );

  const MainLondonMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          From there, you will review and sign your online payday loan documents
          and once completed we use the industry’s most reliable tools to ensure
          your cash advance is delivered as quickly as possible. Depending on
          the time of day that we receive your signed loan application and the
          business hours of your financial institution, your payday loan will
          either be delivered to you in the same day or the next morning. That’s
          right, within 24 hours you will have the cash you need. A GoDay
          representative can provide you all the necessary information once your
          online payday loan is approved.
        </p>
        <p>
          No matter your address in London or the surrounding area, GoDay
          provides people with instant online payday loans. So head to the
          Mustang Drive-In to see your favourite movie and get tickets when your
          favourite band comes to town, because GoDay is here to provide you
          with our quick and easy payday loans.
        </p>
        <p>
          Actions speak louder than words. Here is some feedback from some of
          our existing payday loan customers who have submitted feedback for
          GoDay.ca through TrustPilot – an independent third party website. Be
          sure to check out the{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            GoDay.ca TrustPilot Reviews
          </a>
          . Become one of the thousands of Canadians who trust GoDay for their
          online payday loans.
        </p>
      </section>
    );
  };

  const MainLondonApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in London
      </h1>
      <div className="location-text">
        <p>
          Do you find that by the time payday comes you wonder where all your
          money has gone? Just when you feel you finally paid all your bills and
          caught up on all your payments, there’s always something else to pay
          for. Unexpected costs happen. We know that spending more than expected
          that can get you off track, but also why we’re here – to get you back
          on track. GoDay payday loans are flexible and speedy options that can
          help you solve those urgent but temporary cash-flow needs.
        </p>
        <p>An online payday loan in London may be right for you.</p>
        <p>
          Since 2012, GoDay has been helping people like you with online payday
          loans in London and across Canada. By offering quick and easy online
          payday loans, you don’t have to walk into a retail store again. From
          the comfort of your home, you can apply for a payday loan with GoDay
          24 hours a day, seven days a week. And here at GoDay we offer online
          payday loans in London to help you get through until your next
          paycheck. No one likes to be surprised with costs and having to pay
          for things you haven’t budgeted for. That’s why an online payday loan
          with GoDay can be the solution you need to help cover unexpected costs
          while managing debt.
        </p>
        <p>
          Since 2012, GoDay has been proud to issue payday loans online to the
          London area.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Learn why London trusts GoDay for online payday loans
        </h2>
        <MainLondonReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in London. We make
            it simple for you to access a payday loan by offering:
          </div>
          <MainLondonOffer1 />
          <MainLondonOffer2 />
          <MainLondonOffer3 />
          <MainLondonOffer4 />
          <MainLondonOffer5 />
          <MainLondonOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why You Should Choose a Payday Loan in London
        </h2>
        <div className="location-text">
          <p>
            London has always been a busy city where you can always find
            something to do. And you shouldn’t have to be worried about pulling
            out your wallet every time your favourite band comes to Budweiser
            Gardens or the London Knights are playing a home game. GoDay is here
            to help you take the financial stress away with our online payday
            loans in London.
          </p>
          <p>
            No matter where you live, the price of food and housing is
            continuing to rise across Canada. And GoDay has been helping serve
            the London area with online payday loans since 2012. From the
            breweries to the music scene and everything in between, there is no
            shortage of things to do and we understand why London is one of the
            places to be. And GoDay is here to help you enjoy all of these
            things with our online payday loan services.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          So how do you get an online payday loan London?
        </h2>
        <div className="location-details location-text">
          <MainLondonInitialContent />
          <MainLondonSeeMore />
          <MainLondonMoreContent />
          <MainLondonApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainLondon;
