import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainKitchener = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainKitchenerReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainKitchenerReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay uses top level encryption to ensure your personal information
            is safe and never used without your authorization. We never share
            your online payday <a href="/application">loan application</a>{" "}
            information with any other online payday loan operators, in person
            payday lenders, or any other third parties in Kitchener or
            otherwise.
          </div>
        </div>
      ) : null;

    const MainKitchenerReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is a trusted source of online payday loans in Kitchener, the
            greater tri-city area and across Canada. If you don’t believe us, be
            sure to check out customer reviews on TrustPilot, view our A+
            accreditation by the Better Business Bureau, or review our Ontario
            Payday Loan License before getting a payday loan online with us.
          </div>
        </div>
      ) : null;

    const MainKitchenerReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            Our process is 100% online. You’ll never have to walk into a retail
            store in Kitchener or the tri-city area for a payday loan again. Our
            average online payday <a href="/application">loan application</a>{" "}
            takes less than 5 minutes to complete. Even better, our automated
            system can deliver a decision in seconds so you can get your payday
            loan online and get back to what matters.
          </div>
        </div>
      ) : null;

    const MainKitchenerReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            We love to help. When you call our customer’s service line, you’ll
            speak with a friendly representative eager to help you get back on
            track. Get your payday loan online with GoDay today!
          </div>
        </div>
      ) : null;

    const MainKitchenerReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            Regardless of if you live in Waterloo, Cambridge, New Hamburg or the
            city of Kitchener, GoDay.ca provides the fastest online payday
            loans. No driving, no faxing, no face to face communication, no
            parking tickets, no traffic, and no waiting in line for an online
            payday loan from GoDay.ca.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainKitchenerReason1 />
        <MainKitchenerReason2 />
        <MainKitchenerReason3 />
        <MainKitchenerReason4 />
        <MainKitchenerReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainKitchenerOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 instant decision</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainKitchenerOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Secure online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainKitchenerOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Upfront fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainKitchenerOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Fast cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainKitchenerOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>No paperwork or faxing required</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainKitchenerOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>We are licensed</strong>
        <br />
        <small>to issue online payday loans in Ontario</small>
      </h3>
    </section>
  );

  const MainKitchenerSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainKitchenerInitialContent = () => (
    <section>
      <p>
        To get a payday loan online all you have to do is go to our website and
        complete a quick and easy online payday loan application. Be sure that
        you accurately and correctly enter your personal, banking, employment,
        and payroll information into the online payday loan application form.
        Your application for a payday loan online may be declined if the
        information you provide is inaccurate and/or unable to be validated by
        our online payday loan system. We recommend that you take an extra 30
        seconds to make sure your online payday loan application information on
        your is correct before pressing “Submit”. If you fail to do this we may
        not be able to offer you a payday loan online.
      </p>
      <p>
        Once our Toronto-based office has received your application for an
        online payday loan, we will reach out to you by phone or email to to set
        up your account and discuss your funding options. Our automated and
        online cash advance system will review your application and let you know
        how much we can approve you for. For all Kitchener residents, payday
        loans are regulated by the province of Ontario. A licensed ontario
        payday lender our Kitchener online payday loans range from $100 to
        $1,500 in value and and is due on your next regularly scheduled pay
        date.
      </p>
    </section>
  );

  const MainKitchenerMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          Once you have reviewed and signed your online payday loan documents,
          we will use the industry’s fastest tools to ensure your cash advance
          is delivered as quickly as possible. Depending on the time of day you
          sign your agreement as well as your financial institution, your online
          payday loan may be delivered the same day, or overnight. A GoDay
          representative will be able to provide you additional information once
          your application for an online payday loan has been approved.
        </p>
        <p>
          Regardless of your neighborhood, GoDay.ca can provide you with a
          Kitchener Payday Loan on the spot! Join the thousands of Canadians
          from coast to coast that trust GoDay.ca for their online payday loan
          needs.
        </p>
        <p>
          Actions speak louder than words. Here is some feedback from some of
          our existing payday loan customers who have submitted feedback for
          GoDay.ca through TrustPilot - an independent third party website. Be
          sure to check out the{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            GoDay.ca TrustPilot Reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainKitchenerApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in in Kitchener
      </h1>
      <div className="location-text">
        <p>
          Borrowing money is a fact of life, and unexpected expenses even more
          so. Don’t stress about it - we’re here to help. Whether something
          broke down, you’re a bit short on rent or you just want to take
          advantage of a reasonable financial opportunity but are short on cash
          flow, apply for a payday loan online in Kitchener, Ontario today.
        </p>
        <p>
          Join a legion of Canadians that already trust GoDay.ca for their
          short-term financial needs! Our easy and fast online payday loan
          application takes minutes to complete and if approved for one of our{" "}
          <Link to="/">payday loans</Link> you can have up to $1,500 in your
          bank account as soon as the same day.
        </p>
        <p>
          We use the industry’s fastest tools to review your online payday loan
          application, set up your account, and get your payday loan on it’s
          way!
        </p>
        <p>
          Issuing Kitchener <Link to="/blog/">payday loans online</Link> since
          2012, GoDay.ca is proud to offer you access to a fast and easy payday
          loan process.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Reasons Kitchener trusts GoDay.ca for an online payday loan
        </h2>
        <MainKitchenerReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            So what are you waiting for? Apply for a payday loan online. We make
            it easy.
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Kitchener. We
            make it easy for you to get a payday loan by offering:
          </div>
          <MainKitchenerOffer1 />
          <MainKitchenerOffer2 />
          <MainKitchenerOffer3 />
          <MainKitchenerOffer4 />
          <MainKitchenerOffer5 />
          <MainKitchenerOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          You may need an online payday loan if you live in the Tri-City area
        </h2>
        <div className="location-text">
          <p>
            Doesn’t matter where you live - the cost of everything is
            increasing. To help you cope with the rising prices and unexpected
            expenses in Kitchener you can always turn to online payday loans. We
            also suggest that you check out some of our staff’s favorite
            Kitchener more budget friendly suggestions - after all, our team has
            been living and providing the best way to get a payday loan online
            in Kitchener since 2012.
          </p>
          <p>
            Kitchener is home to so many amazing things - the biggest
            Oktoberfest celebration in the country, Bingemans water park, St.
            Jacobs Farmer’s Market is just on the outskirts, incredible shopping
            and beautiful GRCA trails, waterways and more. We’re so happy to be
            able to serve this incredible location with our payday loan
            services.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          What’s the process and how does it work?
        </h2>
        <div className="location-details location-text">
          <MainKitchenerInitialContent />
          <MainKitchenerSeeMore />
          <MainKitchenerMoreContent />
          <MainKitchenerApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainKitchener;
