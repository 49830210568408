import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  const FooterBreadcrumbs = () => (
    <nav className="footer-breadcrumbs">
      <ul>
        <li>
          <Link to="/">GoDay</Link>
        </li>
        <li>
          <Link to="/how-it-works">How It Works</Link>
        </li>
        <li>
          <Link to="/why-us">Why GoDay</Link>
        </li>
        <li>
          <Link to="/education">Finance 101</Link>
        </li>
        <li>
          <Link to="/faq">FAQ</Link>
        </li>
        <li>
          <a href="/blog/" target="_blank" rel="noopener noreferrer">
            Blog
          </a>
        </li>
        <li>
          <a href="/application">Apply Now</a>
        </li>
      </ul>
    </nav>
  );

  const FooterCopyright = () => {
    const year = new Date().getFullYear();
    return (
      <div className="footer-copyright">
        <div>
          <span>&copy; {year} GoDay Inc.</span> <span>Made in Canada.</span>{" "}
          <span>Operated in Canada.</span>
        </div>
      </div>
    );
  };

  return (
    <footer className="footer content">
      <FooterBreadcrumbs />
      <FooterCopyright />
    </footer>
  );
};

export default Footer;
