import { useState } from "react";
import "./basic-faq.css";

const InstallmentFAQ = () => {
  const [isFullContent, setIsFullContent] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [isExpanded5, setIsExpanded5] = useState(false);
  const [isExpanded6, setIsExpanded6] = useState(false);
  const [isExpanded7, setIsExpanded7] = useState(false);
  const [isExpanded8, setIsExpanded8] = useState(false);

  const BasicFAQSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="basic-faq-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons basic-faq-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const BasicFAQEntry1 = () => {
    const bullet = isExpanded1 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded1 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded1(!isExpanded1);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>Is an
          installment loan right for me?
        </h3>
        {isExpanded1 && (
          <p>
            Every year, a large proportion of Canadians face unplanned emergency
            expenses. And not everyone has the savings set aside when those
            unplanned expenses pop up. When it comes to making sure all your
            bills get paid an installment loan may be for you. GoDay’s
            installment loans are fixed-rate loans. What does this mean? When
            you get an installment loan with GoDay, the interest rate that is
            charged over the time period of your loan is fixed or set at the
            time of borrowing and will not change. Your payment amount is the
            same each month and is automatically withdrawn from your account
            making it easy for you and your budget. With better interest rates
            then what you would be paying on your credit cards, we can help get
            you on track towards a stronger financial future.
          </p>
        )}
      </div>
    );
  };

  const BasicFAQEntry2 = () => {
    const bullet = isExpanded2 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded2 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded2(!isExpanded2);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>Why
          choose GoDay for your installment loans?
        </h3>
        {isExpanded2 && (
          <>
            <p>
              GoDay is here to help you get back on track with our financial
              assistance. You can apply for an installment loan online with
              GoDay by completing our simple online application form. The
              process is quick and easy and available 24 hours a day, seven days
              a week. Since GoDay is committed to offering one of the fastest,
              installment loan services in Canada, within minutes of submitting
              your application, our automated system will review your
              application and let you know how much we are able to approve you
              for. At GoDay, what you see is what you pay. All installment loan
              pricing is disclosed to you on your loan agreement and those
              numbers don’t change. In addition to offering you quick and easy
              ways for you to apply for installment loans, GoDay is also
              dedicated to providing you with all the tools you need to help
              make the big decisions when it comes to your finances. From our
              instant approvals process to email notifications on the status of
              your loan, we stay in touch so you know when you’re getting the
              cash you need. And if you have questions about the application
              process or how our loans work, our customer service team is
              available to assist you along the way.
            </p>
            <p>Get started by submitting a loan application here.</p>
          </>
        )}
      </div>
    );
  };

  const BasicFAQEntry3 = () => {
    const bullet = isExpanded3 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded3 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded3(!isExpanded3);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>What
          is the difference between an installment loan and payday loan?
        </h3>
        {isExpanded3 && (
          <p>
            The amount of money that is offered and the repayment terms –
            including how long you have to pay back the loan are the big
            differences between a payday loan and an installment loan. Since
            2012, GoDay has been offering quick and easy online payday loans
            across Canada that provides quick cash to solve those urgent but
            temporary cash-flow needs. You can request a payday loans with us of
            up to $500 for up to 30 days as a first time customer. If you are
            visiting us again, we gradually increase loan amounts up to a
            maximum of $1,500 for any future finances you may need. This is
            different than our installment loans, as we offer up to $15,000 over
            a set amount of time. Unlike Goday’s payday loans, our installment
            loans do not need to be repaid in full all at once and have longer
            repayment terms. Installment loans with GoDay are a different
            flexible option that will give you the cash you need quickly,
            without having to pay back the full amount right away.
          </p>
        )}
      </div>
    );
  };

  const BasicFAQEntry4 = () => {
    const bullet = isExpanded4 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded4 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded4(!isExpanded4);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>Why
          do so many canadians trusts GoDay for online installment loans?
        </h3>
        {isExpanded4 && (
          <p>
            <ul>
              <li>
                GoDay uses the most secure technology to ensure your personal
                information is safe and never used without your authorization.
                We never share your application or personal information with
                anyone else.
              </li>
              <li>
                GoDay is a trusted source for installment loans across Canada.
                Don’t take our word for it – check out our reviews on{" "}
                <a
                  href="https://ca.trustpilot.com/review/goday.ca"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TrustPilot
                </a>{" "}
                from customers like you, our A+ accreditation by the Better
                Business Bureau.
              </li>
              <li>
                GoDay is 100% online. We have physical offices across Canada,
                but we only operate online. The average installment loan
                application takes five minutes to complete and is available 24
                hours a day seven days a week. Better yet, our fully automated
                GoDay system provides you with a decision about your loan
                instantly.
              </li>
              <li>
                We are here to help! Despite us being an online company, any
                time you phone our customer service line you will be speaking
                with a real person from our office who will be happy to help you
                with any questions you may have. Feel free to reach out to us
                today.
              </li>
              <li>
                Our online application tools are available anytime and anywhere,
                so you can get back to what matters.
              </li>
            </ul>
          </p>
        )}
      </div>
    );
  };

  const BasicFAQEntry5 = () => {
    const bullet = isExpanded5 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded5 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded5(!isExpanded5);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>What
          do I need in order to get a GoDay personal loan?
        </h3>
        {isExpanded5 && (
          <>
            <p>In order to get a GoDay Loan you must:</p>
            <p>
              <ul>
                <li>be a Canadian resident over the age of 18</li>
                <li>
                  have an open bank account with a Canadian bank or credit union
                </li>
                <li>receive steady income by direct deposit</li>
                <li>have a valid email address and phone number</li>
              </ul>
            </p>
          </>
        )}
      </div>
    );
  };

  const BasicFAQEntry6 = () => {
    const bullet = isExpanded6 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded6 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded6(!isExpanded6);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>Do
          you offer loans in person or can I visit your office for a loan?
        </h3>
        {isExpanded6 && (
          <p>
            At this time, we only issue online installment loans. Please do not
            attempt to visit us in person. To complete an online application,
            click <a href="/application">here</a>. If you have questions or
            concerns along the way, please feel free to contact us.
          </p>
        )}
      </div>
    );
  };

  const BasicFAQEntry7 = () => {
    const bullet = isExpanded7 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded7 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded7(!isExpanded7);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>Can I
          re-pay early?
        </h3>
        {isExpanded7 && (
          <p>
            Yes, there are no pre-payment penalties. You can pay off your loan
            balance in full at any time with no additional fees. Please contact
            our office or log into your GoDay account to confirm your current
            outstanding loan balance.
          </p>
        )}
      </div>
    );
  };

  const BasicFAQEntry8 = () => {
    const bullet = isExpanded8 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded8 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded8(!isExpanded8);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>What
          fees do I need to know about when taking a personal loan?
        </h3>
        {isExpanded8 && (
          <p>
            At GoDay, we keep it simple. What you see is what you pay. Many
            lenders will have hidden fees and additional insurance coverage in
            their loan agreements. Please carefully review your loan agreements
            and ensure you compare all agreements equally when making an
            informed borrowing decision.
          </p>
        )}
      </div>
    );
  };

  const BasicFAQApplyNow = () => (
    <section className="basic-faq-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  const BasicFAQInitialContent = () => (
    <section className="basic-faq-left-group">
      <BasicFAQEntry1 />
      <BasicFAQEntry2 />
      <BasicFAQEntry3 />
      <BasicFAQEntry4 />
    </section>
  );

  const BasicFAQMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`basic-faq-right-group ${className}`}>
        <BasicFAQEntry5 />
        <BasicFAQEntry6 />
        <BasicFAQEntry7 />
        <BasicFAQEntry8 />
      </section>
    );
  };

  return (
    <section className="basic-faq content">
      <h2 className="basic-faq-title">Common questions</h2>
      <BasicFAQInitialContent />
      <BasicFAQSeeMore />
      <BasicFAQMoreContent />
      <BasicFAQApplyNow />
    </section>
  );
};

export default InstallmentFAQ;
