import pages from "configurations/pages.json";

const Page = (props: any) => {
  const page = (pages as any)[props.id];
  const layout = ["common/document-head", ...page.layout];
  return (
    <div className={props.id}>
      {layout.map((blockId: string, index: number) => {
        const Block = require(`components/${blockId}`).default;
        return <Block {...props} key={`${props.id}-${index + 1}`} />;
      })}
    </div>
  );
};

export default Page;
