import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainToronto = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainTorontoReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainTorontoReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay uses top level encryption to ensure your personal information
            is safe and never used without your authorization. We never share
            your online payday <a href="/application">loan application</a>{" "}
            information with any other online payday loan operators, in person
            payday lenders, or any other third parties in Toronto or otherwise.
          </div>
        </div>
      ) : null;

    const MainTorontoReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is a trusted source of online payday loans in Toronto, the
            GTA, and across Canada. If you don’t believe us, be sure to check
            out customer reviews on TrustPilot, view our A+ accreditation by the
            Better Business Bureau, or review our Ontario Payday Loan License
            before getting a payday loan online with us.
          </div>
        </div>
      ) : null;

    const MainTorontoReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            Our process is 100% online. You’ll never have to walk into a retail
            store in Toronto or the GTA for a payday loan again. Our average
            online payday <a href="/application">loan application</a> takes less
            than 5 minutes to complete. Even better, our automated system can
            deliver a decision in seconds so you can get your payday loan online
            and get back to what matters.
          </div>
        </div>
      ) : null;

    const MainTorontoReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            Toronto is our house! GoDay.ca was started and continues to be the
            best way to get a payday loan online in downtown Toronto. That
            means, when you call our customer’s service line or speak with a
            friendly representative, they are also from Toronto and care about
            you! Whether it’s discussing the latest Raptors, Leaf’s, or Jay’s
            game, to discussing weekend plans on Toronto Island, our team cares
            about you and are experts in issuing online payday loans in Toronto
            and the GTA (Greater Toronto Area). Get your payday loan online with
            GoDay today!
          </div>
        </div>
      ) : null;

    const MainTorontoReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            Regardless of if you live in Halton, Peel, York, Durham, or the city
            of Toronto, GoDay.ca provides the fastest online payday loans. No
            driving, no faxing, no face to face communication, no parking
            tickets, no traffic, and no waiting in line for an online payday
            loan from GoDay.ca.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainTorontoReason1 />
        <MainTorontoReason2 />
        <MainTorontoReason3 />
        <MainTorontoReason4 />
        <MainTorontoReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainTorontoOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 instant decision</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainTorontoOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Secure online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainTorontoOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Upfront fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainTorontoOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Fast cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainTorontoOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>No paperwork or faxing required</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainTorontoOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>We are licensed</strong>
        <br />
        <small>to issue online payday loans in Ontario</small>
      </h3>
    </section>
  );

  const MainTorontoSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainTorontoInitialContent = () => (
    <section>
      <p>
        Congratulations, you live in Toronto, the largest city in Canada! By the
        way, did we mention how expensive Toronto is? Did we mention that a
        drink could cost $7, or $10, or $12? Also, all the fun things you wanted
        to do -- climb the CN tower, visit the ROM, explore the beautiful
        Toronto Islands, see the sharks at Ripley’s Aquarium, or take in a Jay’s
        game -- it all costs a lot of money!
      </p>
      <p>
        To help you cope with the rising prices and unexpected expenses in
        Toronto you can always turn to online payday loans. We also suggest that
        you check out some of our staff’s favorite Toronto more budget friendly
        suggestions - after all, our team has been living and providing the best
        way to get a payday loan online in Toronto since 2012.
      </p>
    </section>
  );

  const MainTorontoMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          Toronto is full of free activities that don’t require an online payday
          loan, if you have a pair of good shoes on your feet, a backpack with
          snacks and some water, and area ready for an adventure, here are some
          of our team’s favorite activities.
        </p>
        <div className="location-quote">
          <p>
            In the summer, we love the St. Lawrence Market, the Toronto Islands,
            the Harbourfront Centre, and the Beaches. All places are free (or
            almost free) to visit and have so much to offer depending on the
            weather.
          </p>
          <p>
            In the winter, we love Yonge-Dundas square, Skating in Nathan
            Phillips Square, and shopping on Queen Street West. Be sure to take
            a walk up and down Yonge Street, filled everything from clothing and
            specialty shops to storefront payday loan companies. Be sure to skip
            the lineup at the store and visit GoDay.ca for an online payday loan
            instead!
          </p>
        </div>
        <p>
          So now that you have a few free (or nearly free) activity ideas in
          Toronto, let’s discuss what to do if you need to consider a payday
          loan online in Toronto. What’s the process and how does it work?
        </p>
        <p>
          To get a payday loan online all you have to do is go to our website
          and complete a quick and easy online payday loan application. Be sure
          that you accurately and correctly enter your personal, banking,
          employment, and payroll information into the online payday loan
          application form. Your application for a payday loan online may be
          declined if the information you provide is inaccurate and/or unable to
          be validated by our online payday loan system. We recommend that you
          take an extra 30 seconds to make sure your online payday loan
          application information on your is correct before pressing “Submit”.
          If you fail to do this we may not be able to offer you a payday loan
          online.
        </p>
        <p>
          Once our Toronto based office has received your application for an
          online payday loan, we will reach out to you by phone or email to to
          set up your account and discuss your funding options. Our automated
          and online cash advance system will review your application and let
          you know how much we can approve you for. For all Toronto resident,
          payday loans are regulated by the province of Ontario. A licensed
          ontario payday lender our Toronto online payday loans range from $100
          to $1,500 in value and and is due on your next regularly scheduled pay
          date.
        </p>
        <p>
          Once you have reviewed and signed your online payday loan documents,
          we will use the industry’s fastest tools to ensure your cash advance
          is delivered as quickly as possible. Depending on the time of day you
          sign your agreement as well as your financial institution, your online
          payday loan may be delivered the same day, or overnight. A GoDay
          representative will be able to provide you additional information once
          your application for an online payday loan has been approved.
        </p>
        <p>
          Regardless of your neighborhood, GoDay.ca can provide you with a
          Toronto Payday Loan on the spot! Join the thousands of Canadians from
          coast to coast that trust GoDay.ca for their online payday loan needs.
          Whether you’re exploring the Annex, the Beaches or the Toronto
          Islands, this can be an expensive city. Don’t fret, knowing that
          GoDay.ca can provide you with a payday loan online quickly and easily.
        </p>
        <p>
          Actions speak louder than words. Here is some feedback from some of
          our Toronto payday loan customers who have submitted feedback for
          GoDay.ca through TrustPilot - an independent third party website. Be
          sure to check out the{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            GoDay.ca TrustPilot Reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainTorontoApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Toronto
      </h1>
      <div className="location-text">
        <p>
          Unexpected expenses can pop up at any time. Whether it’s an urgent car
          repair, a broken pipe, or a malfunctioning heater, you don’t have time
          to waste when you face a serious problem. For fast access to a{" "}
          <Link to="/">payday loan</Link> online in Toronto, trust GoDay.ca. Our
          easy and fast online payday loan application takes minutes to complete
          and if approved for one of our payday loans you can have up to $1,500
          in your bank account as soon as the same day.
        </p>
        <p>
          An <Link to="/education">online payday loan</Link> from GoDay.ca can
          get you back on track fast. A payday loan online or in person is an
          unsecured loan that’s designed to cover any unexpected expenses
          between today and your next regularly scheduled pay date. We use the
          industry’s fastest tools to review your online payday loan
          application, set up your account, and get you your payday loan on it’s
          way! Issuing Toronto payday loans online since 2012, GoDay.ca is proud
          to offer each resident access to a fast and easy payday loan process.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Reasons Toronto trusts GoDay.ca for an online payday loan
        </h2>
        <MainTorontoReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            Go ahead, Toronto! Apply for a payday loan online. We make it easy.
            We’re provincially licensed to issue payday loans in Toronto.
          </div>
          <div className="location-offers-text">
            We make it easy for you to get a payday loan by offering:
          </div>
          <MainTorontoOffer1 />
          <MainTorontoOffer2 />
          <MainTorontoOffer3 />
          <MainTorontoOffer4 />
          <MainTorontoOffer5 />
          <MainTorontoOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          You may need an online payday loan if you live in the 416...
        </h2>
        <div className="location-details location-text">
          <MainTorontoInitialContent />
          <MainTorontoSeeMore />
          <MainTorontoMoreContent />
          <MainTorontoApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainToronto;
