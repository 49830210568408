import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainBarrie = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainBarrieReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainBarrieReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay is 100% online. And as an online company, your privacy and
            security has always been our top priority. We use best in class
            encryption to ensure your personal information is only used with
            your authorization and never shared with any other online payday
            loan operators, in person payday lenders, or any other third
            parties.
          </div>
        </div>
      ) : null;

    const MainBarrieReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is a trusted name for online payday loans in Barrie and across
            Ontario. And we have an A+ accreditation rating from the Better
            Business Bureau to prove it. If you want to hear what our customers
            have to say about us, check out our hundreds of reviews on{" "}
            <a
              href="https://ca.trustpilot.com/review/goday.ca?utm_medium=trustbox&amp;utm_source=Carousel"
              target="_blank"
              rel="noopener noreferrer"
            >
              TrustPilot
            </a>
            .
          </div>
        </div>
      ) : null;

    const MainBarrieReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            GoDay is 100% online. We make it easy for our customers in Barrie to
            get the cash you need, when you need it. Say goodbye visiting retail
            stores in Barrie for payday loans again. Our{" "}
            <a href="/application">online payday loan application tool</a> takes
            less than five minutes to complete and provides you with a decision
            instantly.
          </div>
        </div>
      ) : null;

    const MainBarrieReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            We’re here to help! If you have questions about our online payday
            loans, our customer service team is happy to help. Feel free to{" "}
            <Link to="/contact">contact us</Link> today.
          </div>
        </div>
      ) : null;

    const MainBarrieReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            Speedy, smart, secure. That is the GoDay way. GoDay is here to get
            you the cash you need when you need it the most. No matter where
            you’re located in Barrie or Ontario - GoDay is here for you.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainBarrieReason1 />
        <MainBarrieReason2 />
        <MainBarrieReason3 />
        <MainBarrieReason4 />
        <MainBarrieReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainBarrieOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainBarrieOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainBarrieOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainBarrieOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainBarrieOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainBarrieOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Ontario</small>
      </h3>
    </section>
  );

  const MainBarrieSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainBarrieInitialContent = () => (
    <section>
      <p>
        An online payday loan from GoDay can help you with any unplanned
        expenses that may pop up. So how do you get a payday loan online with
        us? The first step is to head to our website to complete our simple
        online payday loan application. It is quick and easy and will ask you
        for a few things such as your banking, employment, and payroll
        information. If we need more information from you, we will also let you
        know. Our automated system will be able to give you an instant decision
        on whether or not you have been approved for one of our payday loans.
      </p>
      <p>
        Once your loan application has been submitted, someone from our customer
        service team will reach out to you to help you set up your GoDay account
        and walk you through the step-by-step process for getting the cash you
        need. They will also provide you with your payday loan documents, and
        when you are ready you will review and complete them. Once they are
        signed, all you have to do is send the documents back to us, and from
        there we use the industry’s most reliable tools to get you the cash you
        need as quickly as possible. GoDay’s payday loans are regulated by the
        province of Ontario and as a licensed payday lender, we offer loans in
        the amounts of $100 to $1,500.
      </p>
    </section>
  );

  const MainBarrieMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          One of the main reasons why people choose GoDay is because we work
          fast. We want to make sure you get the cash you need as soon as
          possible. And depending on the time of day we receive your signed loan
          application and the business hours of your financial institution, your
          payday loan will either be delivered to you in the same day or the
          next morning. That’s right, with GoDay you will have the cash you need
          within 24 hours. And if you have any questions along the way, our
          GoDay customer service team is available to help.
        </p>
        <p>
          GoDay can provide you with an online payday loan on the spot! Join the
          thousands of customers across Canada who turn to GoDay in a time of
          need. So, no matter where in Barrie or along Lake Simcoe you are
          headed to next, you will have peace of mind knowing that GoDay is here
          to help you along the way. Want to hear what customers like you have
          to say about us? Take a look at our{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            TrustPilot reviews
          </a>{" "}
          and become one of the thousands of Canadians who trust GoDay for their
          online payday loans.
        </p>
      </section>
    );
  };

  const MainBarrieApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a quick and easy online payday loan in Barrie
      </h1>
      <div className="location-text">
        <p>
          Your car broke down. There was a flood in the basement. You have
          unexpectedly lost your job. These things happen and it is always when
          you least expect them to. And not everyone has the immediate cash
          available to make sure all the bills get paid. That’s why people turn
          to GoDay. As a trusted and reliable source for online payday loans,
          GoDay gets you the cash you need quickly and easily – because no one
          needs the added stress.
        </p>
        <p>
          Since 2012, Canadians have been turning to GoDay for online payday
          loans. Do you know what the best part about GoDay’s online payday
          loans is? You can apply for a loan with us virtually anywhere! No
          matter where you are located in Barrie or just outside the city, you
          shouldn’t have to wait for stores to open to get the cash you need. We
          work directly with your financial institution and use the fastest
          tools in the industry to ensure you get your loan quickly. Not to
          mention the application process with GoDay is very easy. Get an online
          payday loan with us from the comfort and privacy of your own home and
          forget about the hassle of having to physically go into a store.
        </p>
        <p>
          We are here for you in Barrie and across Ontario, which is why our
          application tool is available 24 hours a day, seven days a week.
          Whether it’s help keeping you afloat until your next paycheque or a
          financial emergency, no matter the reason, you can count on GoDay to
          get you the cash you need.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Barrie relies on GoDay for online payday loans
        </h2>
        <MainBarrieReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Barrie. We make
            it simple for you to access a payday loan by offering:
          </div>
          <MainBarrieOffer1 />
          <MainBarrieOffer2 />
          <MainBarrieOffer3 />
          <MainBarrieOffer4 />
          <MainBarrieOffer5 />
          <MainBarrieOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Getting a payday loan in Barrie has never been easier
        </h2>
        <div className="location-text">
          <p>
            Barrie is a busy place! And no matter Canadian season is your
            favourite, there is something to do in Barrie all year round.
            Whether you are hanging out on Lake Simcoe in the summer or headed
            to the ski hill in the winter, don’t let your finances get in the
            way. Try our services and see why Barrie residents turn to GoDay
            when they need a fast cash.
          </p>
          <p>
            No matter where you are headed in Barrie or the surrounding area,
            unplanned expenses pop up. And GoDay has been proud to help Barrie
            residents stay afloat financially since 2012. Our online payday
            loans are intended to help get you back on track – not further from
            it. Which is why you can count on GoDay to be there for you whenever
            you need us. Even if you are on the GO Train headed into Toronto. Go
            ahead and apply for an online payday loan today.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Get your quick and easy payday loan in Barrie today
        </h2>
        <div className="location-details location-text">
          <MainBarrieInitialContent />
          <MainBarrieSeeMore />
          <MainBarrieMoreContent />
          <MainBarrieApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainBarrie;
