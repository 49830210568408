import { Link } from "react-router-dom";
import "./basic-why-us-1.css";
import logo from "assets/images/logo.png";

const BasicWhyUs1 = () => {
  const BasicWhyUs1Tab1 = () => (
    <section className="basic-why-us-1-tab" id="basic-why-us-1-tab-1">
      <input
        type="radio"
        name="basic-why-us-1-tab-header"
        id="basic-why-us-1-tab-header-1"
        defaultChecked={true}
      />
      <label htmlFor="basic-why-us-1-tab-header-1">Speedy</label>
      <div className="basic-why-us-1-tab-content">
        <h4 className="basic-why-us-1-tab-title">
          Go ahead. Apply. We specialize in fast payday loans.
        </h4>
        <div className="basic-why-us-1-tab-text">
          <Link to="/how-it-works">
            Online payday loans, short-term loans, cash advances
          </Link>{" "}
          - call them what you like. We all need help bridging the gap from time
          to time. That’s why there’s GoDay.
        </div>
      </div>
    </section>
  );

  const BasicWhyUs1Tab2 = () => (
    <section className="basic-why-us-1-tab" id="basic-why-us-1-tab-2">
      <input
        type="radio"
        name="basic-why-us-1-tab-header"
        id="basic-why-us-1-tab-header-2"
      />
      <label htmlFor="basic-why-us-1-tab-header-2">Smart</label>
      <div className="basic-why-us-1-tab-content">
        <h4 className="basic-why-us-1-tab-title">
          Get emergency cash or avoid late payment fees.
        </h4>
        <div className="basic-why-us-1-tab-text">
          Getting an online payday loan from GoDay.ca can be the smart way to
          get fast access to the cash you need. Our smart online loan system
          requires no faxing, all fees are disclosed up front, and best of all,
          you can apply on the go!
        </div>
      </div>
    </section>
  );

  const BasicWhyUs1Tab3 = () => (
    <section className="basic-why-us-1-tab" id="basic-why-us-1-tab-3">
      <input
        type="radio"
        name="basic-why-us-1-tab-header"
        id="basic-why-us-1-tab-header-3"
      />
      <label htmlFor="basic-why-us-1-tab-header-3">Secure</label>
      <div className="basic-why-us-1-tab-content">
        <h4 className="basic-why-us-1-tab-title">
          Our 100% online payday loan application is secured by industry
          leaders.
        </h4>
        <div className="basic-why-us-1-tab-text">
          We use top-level encryption to ensure that your data is safe and never
          used without your authorization. Since 2012, our team has been issuing
          fast online payday loans while maintaining a secure and robust lending
          system.
        </div>
      </div>
    </section>
  );

  const BasicWhyUs1Reason1 = () => (
    <section className="basic-why-us-1-reason">
      <div
        className="basic-why-us-1-reason-icon gallery"
        id="basic-why-us-1-reason-icon-1"
      ></div>
      <h4 className="basic-why-us-1-reason-title">24/7 instant decision</h4>
    </section>
  );

  const BasicWhyUs1Reason2 = () => (
    <section className="basic-why-us-1-reason">
      <div
        className="basic-why-us-1-reason-icon gallery"
        id="basic-why-us-1-reason-icon-2"
      ></div>
      <h4 className="basic-why-us-1-reason-title">Upfront fee disclosure</h4>
    </section>
  );

  const BasicWhyUs1Reason3 = () => (
    <section className="basic-why-us-1-reason">
      <div
        className="basic-why-us-1-reason-icon gallery"
        id="basic-why-us-1-reason-icon-3"
      ></div>
      <h4 className="basic-why-us-1-reason-title">Easy and fast experience</h4>
    </section>
  );

  const BasicWhyUs1Reason4 = () => (
    <section className="basic-why-us-1-reason">
      <div
        className="basic-why-us-1-reason-icon gallery"
        id="basic-why-us-1-reason-icon-4"
      ></div>
      <h4 className="basic-why-us-1-reason-title">No paperwork required</h4>
    </section>
  );

  const BasicWhyUs1Sidebar = () => (
    <section className="basic-why-us-1-sidebar">
      <div className="basic-why-us-1-sidebar-line-1">
        Get Your
        <br />
        Cash In
        <br />
        As Little As
      </div>
      <div className="basic-why-us-1-sidebar-line-2">1 Hour</div>
      <div className="basic-why-us-1-sidebar-apply-now button texture">
        <a href="/application">Apply Now</a>
      </div>
    </section>
  );

  return (
    <section className="basic-why-us-1 content">
      <h2 className="basic-why-us-1-title">Fast online payday loans</h2>
      <div className="basic-why-us-1-reasons">
        <div className="basic-why-us-1-tabs">
          <div className="basic-why-us-1-logo">
            <img src={logo} alt="GoDay" />
          </div>
          <BasicWhyUs1Tab1 />
          <BasicWhyUs1Tab2 />
          <BasicWhyUs1Tab3 />
        </div>
        <BasicWhyUs1Reason1 />
        <BasicWhyUs1Reason2 />
        <BasicWhyUs1Reason3 />
        <BasicWhyUs1Reason4 />
        <BasicWhyUs1Sidebar />
      </div>
    </section>
  );
};

export default BasicWhyUs1;
