import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainGuelph = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainGuelphReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainGuelphReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay is 100% online and we are proud to have been offering online
            payday loans since 2012. And as an online company, your privacy and
            security has always been our top priority. GoDay uses top level
            encryption to ensure your personal information is safe and never
            used without your authorization, which means we never share your
            data with any other online payday loan operators, in person payday
            lenders, or any other third parties in Guelph or otherwise.
          </div>
        </div>
      ) : null;

    const MainGuelphReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is a trusted name for online payday loans in Guelph and
            throughout Ontario and our A+ accreditation rating from the Better
            Business Bureau proves it. Want to hear what others have to say
            about us? Customers like you have left hundreds of reviews on{" "}
            <a
              href="https://ca.trustpilot.com/review/goday.ca?utm_medium=trustbox&amp;utm_source=Carousel"
              target="_blank"
              rel="noopener noreferrer"
            >
              TrustPilot
            </a>
            .
          </div>
        </div>
      ) : null;

    const MainGuelphReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            Speedy, smart and secure. That’s the GoDay way. GoDay makes it easy
            for our customers in Guelph to get you the cash you need. We are
            100% online so you will never have to walk into a retail store in
            Guelph again. Our{" "}
            <a href="/application">online payday loan application tool</a> takes
            less than five minutes to complete and will provide you with a
            decision instantly.
          </div>
        </div>
      ) : null;

    const MainGuelphReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            Have questions about our payday loans? Feel free to{" "}
            <Link to="/contact">contact us</Link>. Even though we are an online
            company, our customer service representatives are ready to help you.
            Our team is ready to help with any questions you may have about your
            application or payday loan.
          </div>
        </div>
      ) : null;

    const MainGuelphReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            GoDay is here to get you the cash you need when you need it the
            most. If we can help you get through a few financial hurdles,
            terrific. If we can help you avoid them altogether, even better. No
            matter where you’re located in Ontario - GoDay is here for you.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainGuelphReason1 />
        <MainGuelphReason2 />
        <MainGuelphReason3 />
        <MainGuelphReason4 />
        <MainGuelphReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainGuelphOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainGuelphOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainGuelphOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainGuelphOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainGuelphOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainGuelphOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Ontario</small>
      </h3>
    </section>
  );

  const MainGuelphSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainGuelphInitialContent = () => (
    <section>
      <p>
        Ready to get started with an online payday loan? Take the first step by
        heading to our website filling out our online payday loan application.
        It is quick and easy and will ask you for a few things such as your
        banking, employment, and payroll information. Our automated system will
        then take the information you provided us and will give you an instant
        decision on whether or not you have been approved for one of our payday
        loans.
      </p>
      <p>
        Someone from our customer service team will then be in touch once your
        loan application has been submitted and they will help you set up your
        GoDay account and walk you through the step-by-step process for getting
        the cash you need. When you are ready you will receive and sign your
        online payday loan documents. And once reviewed and completed, all you
        have to do is send the documents back to us, and from there we use the
        industry’s most reliable tools to get you the cash you need as quickly
        as possible. Our payday loans are regulated by the province of Ontario
        and as a licensed payday lender, we offer loans in the amounts of $100
        to $1,500.
      </p>
    </section>
  );

  const MainGuelphMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          When will you get your loan? Depending on the time of day we receive
          your signed loan application and the business hours of your financial
          institution, your payday loan will either be delivered to you in the
          same day or the next morning. That’s right, with GoDay you will have
          the cash you need within 24 hours. And if you have questions along the
          way, one of our GoDay representatives can provide you with the
          necessary information once your online payday loan is approved.
        </p>
        <p>
          No matter what you need in Guelph, GoDay is here for you when you need
          an online payday loan. Not only does GoDay have an easy to use
          application process and a step-by-step guide to help you along the
          way, but we also have our{" "}
          <a href="/blog/" target="_blank" rel="noopener noreferrer">
            personal blog
          </a>{" "}
          where we cover a variety of topics related to different money matters.
          We also have our GoDay <Link to="/education">education section</Link>{" "}
          on our website, to support – not replace – the advice of a financial
          professional, which is our learning center to help you understand your
          payday loans and personal finance options.
        </p>
        <p>
          Our reviews say a lot about us. See what our customers are saying
          about us on{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            TrustPilot
          </a>{" "}
          and become one of the thousands of Canadians who trust GoDay for their
          online payday loans.
        </p>
      </section>
    );
  };

  const MainGuelphApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast, online payday loan in Guelph
      </h1>
      <div className="location-text">
        <p>
          When it comes to getting fast cash, Canadians turn to GoDay as a
          trusted and reliable source for online payday loans. Here at GoDay, we
          understand that life happens and there are things that you may not be
          financially prepared for. Whether you need help bridging the gap until
          your next paycheque or need assistance to get through a financial
          emergency, no matter the reason, you can count on GoDay to get you the
          cash you need.
        </p>
        <p>
          Canadians have been turning to GoDay since 2012. We offer online
          payday loans in Guelph and throughout Ontario, so you don’t have to go
          to physical location and can get the cash you need from virtually
          anywhere. We partner directly with the most reputable financial
          institutions and use the fastest tools in the industry to ensure the
          best service possible. Our application tool is available 24/7, on any
          device, so you don’t need to wait for stores to open and work around
          their business hours anymore. Our highly automated system does all the
          work, so you don’t have to. See why people trust GoDay when it comes
          to their online payday loans.
        </p>
        <p>
          Getting an online payday loan in Guelph has never been easier. If we
          can help you get through a few financial hurdles, terrific. If we can
          help you avoid them altogether, even better. Learn more about why
          people turn to GoDay.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          People in Guelph trust GoDay for online payday loans
        </h2>
        <MainGuelphReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Guelph. We make
            it simple for you to access a payday loan by offering:
          </div>
          <MainGuelphOffer1 />
          <MainGuelphOffer2 />
          <MainGuelphOffer3 />
          <MainGuelphOffer4 />
          <MainGuelphOffer5 />
          <MainGuelphOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Getting a payday loan in Guelph has never been easier
        </h2>
        <div className="location-text">
          <p>
            We know that living in southern Ontario can be expensive- one of the
            most expensive places to live in Canada. And when those unplanned
            expenses pop up, GoDay has been proud to help Guelph residents stay
            afloat financially. Voted as one of the best cities to live in
            Canada, Guelph has a booming craft beer scene, and vast array of
            independent eateries. Try our services and see why Guelph residents
            turn to GoDay when they need cash quickly and easily.
          </p>
          <p>
            Whether you are out enjoying the Guelph Radial Line Trail or are
            headed to a local farmers market, our online payday loans are
            intended to help get you back on track – not further from it. Which
            is why you can count on GoDay to be there for you whenever you need
            us. Go ahead and apply for an online payday loan today.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Get your quick and easy payday loan in Guelph today
        </h2>
        <div className="location-details location-text">
          <MainGuelphInitialContent />
          <MainGuelphSeeMore />
          <MainGuelphMoreContent />
          <MainGuelphApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainGuelph;
