import { Link } from "react-router-dom";
import "./quick-links.css";

const QuickLinks = () => {
  const QuickLinksSocial = () => (
    <nav className="quick-links-social-icons">
      <ul className="gallery">
        <li>
          <a
            href="https://www.facebook.com/GoDay.ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              className="quick-links-social-icon"
              id="quick-links-social-icon-facebook"
            ></span>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/godayca"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              className="quick-links-social-icon"
              id="quick-links-social-icon-twitter"
            ></span>
          </a>
        </li>
        <li>
          <a
            href="https://plus.google.com/109801055148329104490"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              className="quick-links-social-icon"
              id="quick-links-social-icon-google-plus"
            ></span>
          </a>
        </li>
        <li>
          <a
            href="https://www.pinterest.com/GodayLoans"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              className="quick-links-social-icon"
              id="quick-links-social-icon-pinterest"
            ></span>
          </a>
        </li>
      </ul>
    </nav>
  );

  const QuickLinksMenu = () => (
    <nav className="quick-links-menu">
      <ul>
        <li>888-984-6329</li>
        <li>
          <Link to="/faq">FAQ</Link>
        </li>
        <li>
          <a href="/blog/" target="_blank" rel="noopener noreferrer">
            Blog
          </a>
        </li>
        <li>
          <Link to="/contact">Help</Link>
        </li>
        <li>
          <a href="/login">Login</a>
        </li>
      </ul>
    </nav>
  );

  return (
    <section className="quick-links content">
      <QuickLinksSocial />
      <QuickLinksMenu />
    </section>
  );
};

export default QuickLinks;
