import { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./basic-why-us-3.css";

const BasicWhyUs3 = () => {
  const [slideIndex, setSlideIndex] = useState(1);
  const [slideCount, setSlideCount] = useState(4);

  const moveBack = () => setSlideIndex(Math.max(slideIndex - 1, 1));
  const moveForward = () => setSlideIndex(Math.min(slideIndex + 1, slideCount));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const BasicWhyUs3Reason1 = () => (
    <section className="basic-why-us-3-reason">
      <div className="basic-why-us-3-reason-container">
        <div className="basic-why-us-3-reason-block">
          <h4 className="basic-why-us-3-reason-title">Why GoDay?</h4>
        </div>
        <div className="basic-why-us-3-reason-block">
          <div className="basic-why-us-3-reason-text">
            We’re responsible, professional, and we genuinely care about our
            customers. Plus, we use the industry’s fastest tools to get you your
            payday loan fast!
          </div>
        </div>
        <div className="basic-why-us-3-reason-block">
          <div className="basic-why-us-3-reason-button button orange">
            <a href="/application">Get Started</a>
          </div>
        </div>
      </div>
    </section>
  );

  const BasicWhyUs3Reason2 = () => (
    <section className="basic-why-us-3-reason">
      <div className="basic-why-us-3-reason-container">
        <div className="basic-why-us-3-reason-block">
          <h4 className="basic-why-us-3-reason-title">Loans Simplified.</h4>
        </div>
        <div className="basic-why-us-3-reason-block">
          <div className="basic-why-us-3-reason-text">
            What do you need to get an{" "}
            <a href="/application">online payday loan</a>? What happens if you
            don’t repay? We’ve got answers to all of your questions.
          </div>
        </div>
        <div className="basic-why-us-3-reason-block">
          <div className="basic-why-us-3-reason-button button orange">
            <Link to="/faq">See FAQs</Link>
          </div>
        </div>
      </div>
    </section>
  );

  const BasicWhyUs3Reason3 = () => (
    <section className="basic-why-us-3-reason">
      <div className="basic-why-us-3-reason-container">
        <div className="basic-why-us-3-reason-block">
          <h4 className="basic-why-us-3-reason-title">Finance 101.</h4>
        </div>
        <div className="basic-why-us-3-reason-block">
          <div className="basic-why-us-3-reason-text">
            We want you to enjoy a sound and happy financial future. That’s why
            we’ve compiled these valuable resources.
          </div>
        </div>
        <div className="basic-why-us-3-reason-block">
          <div className="basic-why-us-3-reason-button button orange">
            <Link to="/education">Get Educated on Payday Loans</Link>
          </div>
        </div>
      </div>
    </section>
  );

  const BasicWhyUs3Reason4 = () => (
    <section className="basic-why-us-3-reason">
      <div className="basic-why-us-3-reason-container">
        <div className="basic-why-us-3-reason-block">
          <h4 className="basic-why-us-3-reason-title">How are we doing?</h4>
        </div>
        <div className="basic-why-us-3-reason-block">
          <div className="basic-why-us-3-reason-text">
            The more we know about your GoDay experience, the better we can
            serve you. Please take a minute to share your thoughts.
          </div>
        </div>
        <div className="basic-why-us-3-reason-block">
          <div className="basic-why-us-3-reason-button button orange">
            <a href="/feedback">Give feedback</a>
          </div>
        </div>
      </div>
    </section>
  );

  const BasicWhyUs3Navigator = () => {
    const moveBackClassName =
      slideIndex === 1 ? "navigator-first" : "navigator-previous";
    const moveForwardClassName =
      slideIndex === slideCount ? "navigator-last" : "navigator-next";

    return (
      <nav className="basic-why-us-3-navigator">
        <ul className="gallery">
          <li>
            <span className={moveBackClassName} onClick={moveBack}></span>
          </li>
          <li>
            <span className={moveForwardClassName} onClick={moveForward}></span>
          </li>
        </ul>
      </nav>
    );
  };

  useEffect(() => {
    const resize = () => {
      let count: number;
      const width = window.innerWidth;
      if (width < 1024) {
        count = 4;
      } else if (width < 1360) {
        count = 3;
      } else if (width < 1680) {
        count = 2;
      } else {
        count = 1;
      }
      setSlideIndex(Math.min(slideIndex, count));
      setSlideCount(count);
    };

    resize();
    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, [slideIndex]);

  return (
    <section className="basic-why-us-3 content">
      <div className="basic-why-us-3-content" {...slide}>
        <div
          className={`basic-why-us-3-reasons basic-why-us-3-reasons-${slideIndex}`}
        >
          <BasicWhyUs3Reason1 />
          <BasicWhyUs3Reason2 />
          <BasicWhyUs3Reason3 />
          <BasicWhyUs3Reason4 />
        </div>
      </div>
      <BasicWhyUs3Navigator />
    </section>
  );
};

export default BasicWhyUs3;
