import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      marginLeft: "2em",
    },
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up(1024)]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      paddingLeft: theme.spacing(20),
      paddingRight: theme.spacing(20),
    },
  },
  title: {
    paddingBottom: theme.spacing(2),
    fontSize: "26pt",
    [theme.breakpoints.up(1024)]: {
      paddingBottom: theme.spacing(4),
      fontSize: "33pt",
    },
    textAlign: "center",
    color: theme.palette.text,
  },
  itemTitle: {
    paddingBottom: theme.spacing(1),
    fontSize: "23pt",
    color: theme.palette.text,
  },
  itemText: {
    paddingBottom: theme.spacing(2),
    fontSize: "13pt",
    color: theme.palette.text,
  },
}));

const MainPrivacy = () => {
  const classes = useStyles();

  const MainPrivacyItem1 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Introduction</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          At GoDay Inc. (“GoDay”) we understand that privacy is an important
          issue. GoDay is committed to protecting privacy and supports a general
          policy of openness about how we collect, use and disclose personal
          information.
        </p>
        <p>
          The purpose of this Privacy Statement is to inform individuals about
          GoDay’s practices relating to the collection, use and disclosure of
          personal information that may be provided through access to or use of
          our websites or services and related products or that may otherwise be
          collected by us. By purchasing our products or using our services or
          website, you consent to the collection, use and disclosure of personal
          information (as defined below) in accordance with the following terms
          and conditions.
        </p>
        <p>
          This Privacy Statement also explains how to contact us if you have a
          question about, want to make a change to or delete any personal
          information that GoDay may be holding about you. We strongly recommend
          that you take the time to read this Privacy Statement and retain it
          for future reference.
        </p>
        <p>
          GoDay will continue to review this Privacy Statement to ensure it
          remains current with changing laws and regulations while meeting the
          evolving needs of our customers. In the event of any amendment to the
          Privacy Statement, a notice will be posted on our website.
        </p>
      </div>
    </section>
  );

  const MainPrivacyItem2 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Personal Information</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          For the purpose of this Privacy Statement, “personal information”
          means information about an identifiable individual, such as name, home
          address, home telephone number, birth date, Social Insurance Number
          and credit reports. Personal information does not include business
          contact information such as the individual’s title, business address,
          or business telephone number, business fax number or business email
          address.
        </p>
      </div>
    </section>
  );

  const MainPrivacyItem3 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Accountability</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          GoDay is responsible for personal information under its control and
          has designated individuals to be accountable for GoDay’s compliance.
        </p>
        <p>
          GoDay has appointed an individual to be its Privacy Officer, who is
          accountable overall for GoDay’s compliance with this Privacy
          Statement. On a day-to-day basis, the collection and processing of
          personal information is delegated to other employees within GoDay.
        </p>
        <p>
          GoDay is responsible for personal information in its possession or
          control and will use reasonable means to provide a proper level of
          protection for personal information, including when personal
          information is being processed by a third party.
        </p>
        <p>
          GoDay will disclose the title of the person(s) designated to oversee
          compliance with this Privacy Statement upon request.
        </p>
        <p>
          GoDay continues to develop and refine policies and procedures to
          protect personal information, receive and respond to complaints and
          inquires, train employees and communicate this information to
          customers.
        </p>
      </div>
    </section>
  );

  const MainPrivacyItem4 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Identifying Purposes</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          GoDay will identify the purposes for which personal information is
          collected at or before the time the information is collected. This
          information may be communicated orally, electronically or in writing.
        </p>
        <p>
          GoDay collects, uses and discloses personal information for the
          following purposes:
        </p>
        <ul>
          <li>To provide financial services to customers;</li>
          <li>
            To develop, manage and market products and services to meet the
            needs of customers;
          </li>
          <li>
            To obtain credit information and credit reports from credit
            reporting agencies to assess credit history and credit worthiness
            and to confirm accuracy of information provided by individuals
            (including by contacting employers and personal references);
          </li>
          <li>To confirm details of customer employment;</li>
          <li>
            To contact customers for products and services that may be of
            interest;
          </li>
          <li>
            To determine the eligibility of customers for different products and
            services that may be of interest;
          </li>
          <li>
            To extract certain information for the purpose of generating
            statistics and analysis for purposes of conducting and improving our
            business;
          </li>
          <li>To ensure a high standard of service to customers;</li>
          <li>To meet legal and regulatory requirements;</li>
          <li>
            To confirm the identity of a customer and to prevent and detect
            fraud;
          </li>
          <li>
            and To collect debt that may become owed to GoDay by a customer.
          </li>
        </ul>
        <p>
          GoDay will identify any new purpose(s) for which personal information
          is being collected, used or disclosed.
        </p>
        <p>
          Individuals will be informed by GoDay by the posting of a notice on
          GoDay’s website, written notification or direct communication.
        </p>
      </div>
    </section>
  );

  const MainPrivacyItem5 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Consent</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          GoDay collects personal information through our website and when it is
          voluntarily provided to us. Typically, GoDay will seek consent for the
          use or disclosure of personal information at the time of collection.
          In certain circumstances, consent may be sought after the information
          has been collected but before use (for example, when we want to use
          information for a purpose not previously identified).
        </p>
        <p>
          The form of consent that we seek, including whether it is express or
          implied, will largely depend on the sensitivity of the personal
          information and the reasonable expectations of the individual in the
          circumstances. An individual’s consent may be explicit or implied and
          consent can be given either orally, electronically or in writing. Use
          of products and services offered by GoDay constitutes implied consent
          for the collection, use and disclosure of personal information for all
          identified purposes.
        </p>
        <p>
          Consent may be withdrawn at any time, subject to legal or contractual
          restrictions and reasonable notice. If you wish to withdraw your
          consent at any time, please contact GoDay’s Privacy Officer at{" "}
          <a href="mailto:customerservice@goday.ca">customerservice@goday.ca</a>
          . We will inform you of the implications of withdrawing consent.
        </p>
        <p>
          GoDay will not, as a condition of the supply of a product or service,
          require consent to the collection, use or disclosure of information
          beyond that required to fulfill the explicitly specified and
          legitimate purposes for which the information is being provided.
          However, refusal or withdrawal of consent by a customer may prevent
          GoDay from providing a product or service to that customer. GoDay will
          not unreasonably withhold products or services from a customer who
          refuses or withdraws consent, but if the personal information is
          required to offer the product or service, GoDay may decline to deal
          with the customer who will not consent to the use of such personal
          information. GoDay maybe contacted for more information regarding the
          method by which consent can be withdrawn.
        </p>
        <p>
          GoDay may collect, use or disclose personal information without the
          individual’s knowledge and consent in certain circumstances. For
          example:
        </p>
        <ul>
          <li>
            When the collection, use, and disclosure is permitted or required by
            law;
          </li>
          <li>
            When the collection, use, and disclosure is clearly in the interests
            of the individual and consent cannot be obtained in a timely manner;
          </li>
          <li>
            When seeking the consent of the individual may defeat the purpose of
            collecting the information;
          </li>
          <li>
            When use of the information is for an emergency that threatens the
            life, health or security of an individual;
          </li>
          <li>
            or When needed to collect a debt, to comply with a subpoena, warrant
            or other court order, or as otherwise required or authorized by law.
          </li>
        </ul>
      </div>
    </section>
  );

  const MainPrivacyItem6 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Limiting Collection</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          The collection of personal information is limited to that which is
          related to the identified purposes.
        </p>
        <p>
          Personal information will be collected by fair and lawful means. Most
          personal information will be collected directly from employees and
          customers.
        </p>
        <p>
          GoDay may also collect personal information from sources such as
          credit bureaus, employers, personal references, or other third
          parties.
        </p>
      </div>
    </section>
  );

  const MainPrivacyItem7 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>
          Limiting Use, Disclosure and Retention
        </h2>
      </header>
      <div className={classes.itemText}>
        <p>
          Personal information will not be used or disclosed for purposes other
          than those for which it was collected, except with the consent of the
          individual or as required by law.
        </p>
        <p>
          GoDay transfers and stores customer personal and transaction
          information with suppliers and service providers located outside of
          Canada, including in the USA, for the purposes listed in the
          Identifying Purposes section above. If you would like further
          information about the transfer and storage of customer personal and
          transaction information outside of your province or outside of Canada,
          please contact our Privacy Officer at{" "}
          <a href="mailto:customerservice@goday.ca">customerservice@goday.ca</a>
          .
        </p>
        <p>
          In addition, GoDay may use and disclose personal information to third
          parties as follows:
        </p>
        <ul>
          <li>
            To conduct analysis of use of our website, prevent and detect fraud
            and assist GoDay in managing its relationship with its customers;
          </li>
          <li>
            A GoDay agent that will evaluate the customer’s creditworthiness or
            to collect a potential debt;
          </li>
          <li>
            A credit reporting agency to obtain credit information and credit
            reports to assess credit history and credit worthiness and to
            confirm accuracy of information;
          </li>
          <li>
            The issuer and/or payer of negotiable items to confirm identity,
            arrange for the issuance of negotiable items or arrange for the
            delivery of negotiable items to customers;
          </li>
          <li>A third party - where individual consents to disclosure;</li>
          <li>A third party - where disclosure is required by law;</li>
          <li>
            A public authority or agent of a public authority where there is
            imminent danger to the life or property of an individual which could
            be avoided or minimized by disclosure;
          </li>
          <li>
            and Conduct surveys in order to enhance our products and services to
            meet customer needs.
          </li>
        </ul>
        <p>
          Where personal information is disclosed to a third party, GoDay will
          ensure that appropriate security measures are utilized to protect the
          use and disclosure of personal information.
        </p>
        <p>
          Only GoDay employees are authorized or granted access to personal
          information about customers when responsibilities reasonably require.
        </p>
        <p>
          GoDay will retain personal information for up to [one] year unless a
          longer period is necessary for the fulfillment of identified purpose,
          or required by law.
        </p>
        <p>
          GoDay reserves the right to disclose and transfer personal information
          to a third party in the event of a proposed or actual purchase, sale
          (including a liquidation, realization, foreclosure or repossession),
          lease, merger, amalgamation or any other type of acquisition,
          disposal, transfer, conveyance or financing of all or any portion of
          GoDay. or its corporate affiliates, or of any of the business or
          assets or shares of GoDay. or its corporate affiliates, or divisions
          thereof in order for you to continue to receive the same products and
          services from the third party.
        </p>
      </div>
    </section>
  );

  const MainPrivacyItem8 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Accuracy</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          Reasonable efforts will be made to ensure that personal information is
          as accurate, complete and up-to-date as is necessary for the purposes
          for which it is used.
        </p>
        <p>
          Due to the nature of GoDay’s business, personal information will be
          updated on an on-going basis as the customer utilizes GoDay’s products
          and services.
        </p>
      </div>
    </section>
  );

  const MainPrivacyItem9 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Safeguards</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          Personal information will be protected by reasonable security
          safeguards appropriate to the sensitivity of the personal information.
        </p>
        <p>
          GoDay is committed to the protection of personal information against
          loss, theft, unauthorized access, disclosure, duplication, use and
          modification regardless of the format in which the personal
          information is held.
        </p>
        <p>
          GoDay will protect personal information disclosed to third parties by
          means of contractual agreements with respect to the confidentiality of
          the personal information and the purposes for which the personal
          information is to be used.
        </p>
        <p>
          GoDay will use reasonable security measures in the disposal of
          personal information.
        </p>
        <p>
          The protection of personal information is an ongoing process, and
          GoDay will update and review the reasonableness of the security
          safeguards as needed.
        </p>
      </div>
    </section>
  );

  const MainPrivacyItem10 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Openness</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          Information is available to individuals regarding GoDay’s policies and
          procedures relating to the management of personal information. Efforts
          have been made to make these policies and procedures understandable
          and easily available, either in written or electronic format.
        </p>
        <p>GoDay will make the following information available:</p>
        <ul>
          <li>Contact information of the Privacy Officer;</li>
          <li>
            Means of gaining access to personal information held by GoDay;
          </li>
          <li>
            and A brief description of the type of personal information retained
            by GoDay and a general account of its use.
          </li>
        </ul>
      </div>
    </section>
  );

  const MainPrivacyItem11 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Individual Access</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          Upon written request, and within a reasonable period of time, a
          customer will be informed of the existence, use, and disclosure of his
          or her personal information and will be given access to that
          information. An individual is entitled to comment on the accuracy and
          completeness of his or her personal information, and have that
          information amended where appropriate.
        </p>
        <p>
          GoDay will respond to an individual request within a reasonable time
          period and at a minimal cost to the customer. GoDay will make
          commercially reasonable efforts to make the information available
          within 30 days of the request. The cost of providing information will
          vary with the type and amount of information requested. Individuals
          can seek access to their personal information by submitting a written
          request to the Privacy Officer at customerservice@goday.ca.
        </p>
        <p>
          An individual will be required to provide sufficient personal
          information to confirm their identity with GoDay.
        </p>
        <p>
          An individual shall be able to challenge the accuracy and completeness
          of their personal information and have it amended as appropriate.
          GoDay will amend personal information when a customer successfully
          demonstrates the inaccuracy or incompleteness of their personal
          information. Any unresolved disagreements relating to the accuracy and
          completeness of a individual’s personal information will be noted for
          so long as such information remains relevant.
        </p>
        <p>
          GoDay may not be able to provide access to any or all of an
          individual’s personal information. If a request for personal
          information is denied, GoDay will notify the individual in writing,
          documenting reasons for refusal and resources available for redress.
        </p>
        <p>Reasons for refusing access may include:</p>
        <ul>
          <li>
            That the personal information is unreasonably costly to provide;
          </li>
          <li>
            That the release of the personal information may threaten the life
            or security of a third party;
          </li>
          <li>
            That the personal information is subject to or generated in a formal
            dispute resolution process;
          </li>
          <li>
            That the personal information cannot be disclosed for legal,
            security, or commercial proprietary reasons;
          </li>
          <li>
            or That the personal information is subject to solicitor-client or
            litigation privilege.
          </li>
        </ul>
      </div>
    </section>
  );

  const MainPrivacyItem12 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Cookies</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          Cookies are small data files placed on a user’s computer by a website.
          They may store and transmit information to websites that users visit.
          Examples of GoDay’s use of cookies on our website include:
        </p>
        <ul>
          <li>Help provide our products and services to customer;</li>
          <li>Provide users of our website with personalized service;</li>
          <li>Market our products and services;</li>
          <li>Analyze the flow of traffic to our website;</li>
          <li>
            and Analyze and track the traffic of website users from page to page
            within our website.
          </li>
        </ul>
        <p>
          Users may accept or decline cookies through the configuration settings
          of their web-browser. If you choose to configure your web-browser to
          decline cookies, some pages of our website may not function properly.
        </p>
      </div>
    </section>
  );

  const MainPrivacyItem13 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Text Message Policy</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          By providing your cell phone number, you have provided us with consent
          to send you text messages in conjunctions with the services you have
          requested. Your cellular provider’s Msg&amp;Data Rates may apply to
          our confirmation message and all subsequent messages.
        </p>
        <p>
          You understand the text messages we send may be seen by anyone with
          access to your phone. Accordingly, you should take steps to safeguard
          your phone and your text messages if you want them to remain private.
        </p>
        <p>
          Please notify us immediately if you change mobile numbers or plan to
          provide your phone to another person.
        </p>
        <p>
          If we modify this Text Message Policy, we will notify you by sending
          you a text message with a link to the new policy. We may terminate our
          text message program at any time.
        </p>
        <p>
          If you have any questions about this policy, would like us to mail you
          a paper copy of this policy or are having problems receiving or
          stopping our text messages, you may contact us by mail at 500 Danforth
          Avenue, Unit 304, Toronto, Ontario, Canada, M4K 1P6; by telephone at
          1-888-984-6329; by facsimile at 1-855-674-6329; or by e-mail at
          customerservice@goday.ca.
        </p>
        <p>
          You agree and consent to be contacted by the Company, Our agents,
          employees, attorneys, affiliates, subsequent creditors, loan servicing
          companies, and third‐party collectors through the use of email, and/or
          telephone calls and/or SMS text messages to your cellular, home or
          work phone numbers, as well as any other phone number you have
          provided in conjunction with this account, including the use of
          automatic telephone dialing systems, autodialers, or an artificial or
          prerecorded voice.
        </p>
      </div>
    </section>
  );

  const MainPrivacyItem14 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Opt‐out or STOP</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          This policy applies to the text messages sent by GoDay to our
          customers while and after they use our service. If you wish to stop
          receiving marketing text messages from GoDay Inc., reply to any text
          message we have sent you and in the reply text simply type STOP.
        </p>
        <p>
          Your stop request will become effective within one day. You may also
          stop text messages by calling us or emailing us using the contact
          information below. You may contact us by telephone at 1-888-984-6329;
          or by e-mail at customerservice@goday.ca.
        </p>
      </div>
    </section>
  );

  const MainPrivacyItem15 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Help or Support</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          If at any time you need our contact information or information on how
          to stop text messages, reply to any text message we have sent you and
          in the reply text simply type HELP. Upon receiving your text message,
          we will send you a text message with this information. In general, the
          messages we send provide you with information about your account. Some
          of the text messages we send may include links to websites. To access
          these websites, you will need a web browser and Internet access.
        </p>
      </div>
    </section>
  );

  const MainPrivacyItem16 = () => (
    <section>
      <header>
        <h2 className={classes.itemTitle}>Challenging Compliance</h2>
      </header>
      <div className={classes.itemText}>
        <p>
          Individuals can address any challenge concerning compliance with this
          Privacy Statement to GoDay’s Privacy Officer at{" "}
          <a href="mailto:customerservice@goday.ca">customerservice@goday.ca</a>
          .
        </p>
        <p>
          GoDay will investigate all complaints. The customer will be informed
          of the outcome of the investigation regarding his or her complaint.
        </p>
      </div>
    </section>
  );

  return (
    <main className={classes.container}>
      <header>
        <h1 className={classes.title}>Privacy Statement</h1>
      </header>
      <MainPrivacyItem1 />
      <MainPrivacyItem2 />
      <MainPrivacyItem3 />
      <MainPrivacyItem4 />
      <MainPrivacyItem5 />
      <MainPrivacyItem6 />
      <MainPrivacyItem7 />
      <MainPrivacyItem8 />
      <MainPrivacyItem9 />
      <MainPrivacyItem10 />
      <MainPrivacyItem11 />
      <MainPrivacyItem12 />
      <MainPrivacyItem13 />
      <MainPrivacyItem14 />
      <MainPrivacyItem15 />
      <MainPrivacyItem16 />
    </main>
  );
};

export default MainPrivacy;
