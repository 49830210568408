import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainNovaScotia = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainNovaScotiaReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainNovaScotiaReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            When it comes to getting an online payday loan in Nova Scotia, we
            like to keep it simple. Fast cash for you with the security and
            professionalism along with it. Your privacy and security is our top
            priority. That’s why we use the most advanced tools to ensure your
            personal information is not shared and only used with your
            authorization.
          </div>
        </div>
      ) : null;

    const MainNovaScotiaReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            From coast to coast Canadians have been using GoDay to help get them
            through their financial hurdles and we have an A+ accreditation
            rating from the Better Business Bureau to prove it. But don’t just
            take our word for it, take a look at{" "}
            <a
              href="https://ca.trustpilot.com/review/goday.ca?utm_medium=trustbox&amp;utm_source=Carousel"
              target="_blank"
              rel="noopener noreferrer"
            >
              our hundreds of reviews
            </a>{" "}
            to see what our customers have to say about us.
          </div>
        </div>
      ) : null;

    const MainNovaScotiaReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            GoDay is 100% online. This means you can apply for a payday loan
            from virtually anywhere in Nova Scotia! Our automated system will
            provide you with an instant decision, so you won’t have to wait to
            get the cash you need. Don’t wait for traditional business hours to
            get the cash you need. Take a look at our{" "}
            <a href="/application">online payday loan application</a> today.
          </div>
        </div>
      ) : null;

    const MainNovaScotiaReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            As we mentioned, GoDay is 100% online, but when you call our
            customer service team you will be speaking with someone from our
            team who will be able to help you with any questions you may have.
            Whether you are looking for more information or have questions about
            the status of your payday loan, our customer service team will be
            able to assist you. <Link to="/contact">Contact us</Link> today.
          </div>
        </div>
      ) : null;

    const MainNovaScotiaReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            Across the entire province of Nova Scotia, no matter where you are
            located on Canada’s east coast, GoDay offers speedy, smart and
            secure online payday loans.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainNovaScotiaReason1 />
        <MainNovaScotiaReason2 />
        <MainNovaScotiaReason3 />
        <MainNovaScotiaReason4 />
        <MainNovaScotiaReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainNovaScotiaOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainNovaScotiaOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainNovaScotiaOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainNovaScotiaOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainNovaScotiaOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainNovaScotiaOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Nova Scotia</small>
      </h3>
    </section>
  );

  const MainNovaScotiaSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainNovaScotiaInitialContent = () => (
    <section>
      <p>
        People choose to get online payday loans with GoDay because we are
        speedy, smart and secure. Get started with a payday loan by heading to
        our website and complete our quick and easy online payday loan
        application. Once you have provided us with the most up to date personal
        information - banking, employment, and payroll details, our automated
        system will let you know instantly if you are approved.
      </p>
      <p>
        Once your loan application has been submitted, our customer service team
        will reach out to help set up your GoDay account, discuss funding
        options and how to get the cash you need. Your application will be
        reviewed by our automated system to determine how much we can approve
        your loan for. For all Nova Scotia residents, online payday loans are
        regulated by the province of Nova Scotia. As a licensed payday lender in
        the province of Nova Scotia GoDay is able to provide our customers with
        loans ranging from $100 to $1,500 in value.
      </p>
    </section>
  );

  const MainNovaScotiaMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          The next step is to review and sign your payday loan documents. From
          there, we use the industry’s fastest tools to get you your loan as
          soon as possible. Based on the business hours of your financial
          institution and when we receive your payday loan agreement, you will
          receive your payday loan within the same day or within the next
          business day. Yes, this means you should expect your loan within 24
          hours! Once your online payday loan has been approved, a GoDay
          representative will be able to provide you with all the necessary
          information and be able to assist with any questions you may have
          about your loan.
        </p>
        <p>
          Whether you are headed along the coast or staying inland, GoDay is
          here for you no matter where you are in Nova Scotia. There is a reason
          why Canadians from coast to coast use GoDay for their online payday
          loans. If we can help you get through a few financial hurdles,
          terrific. If we can help you avoid them altogether, even better.
        </p>
        <p>
          Don’t just take our word for it. Our customers also have great things
          to say about us. See our{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            TrustPilot reviews
          </a>{" "}
          to see why our customers choose GoDay.
        </p>
      </section>
    );
  };

  const MainNovaScotiaApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Nova Scotia
      </h1>
      <div className="location-text">
        <p>
          There is no way of knowing when a financial emergency may pop up. If
          we did, then we could be prepared for the unexpected costs we would be
          facing. There is never a good time for your car to break down or your
          dishwasher will start leaking, which is why GoDay is available 24/7
          for quick and easy online payday loans. Here at GoDay we have been
          providing financial solutions to Canadians since 2012. We’re a name
          trusted from coast to coast when it comes to our payday or short-term
          loans that have helped provide our customers financial relief whenever
          they need.
        </p>
        <p>
          Learn more about our online payday loans in Nova Scotia and how we are
          helping people like you.
        </p>
        <p>
          Why are people across Nova Scotia choosing GoDay for payday loans?
          Because getting an online payday loan with us could not be any
          simpler! We accept loan applications 24/7 and because we are 100%
          online, this means you can apply for a payday loan with us from
          virtually anywhere and never have to walk into a retail store again.
          Our online loan applications take a few minutes to complete as we
          offer one of the fastest payday loan services in Canada. So, if you’re
          in need of a payday loan in Nova Scotia, GoDay gets you the cash you
          need quickly and easily so you can get back on track.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Learn why Nova Scotia trusts GoDay for online payday loans
        </h2>
        <MainNovaScotiaReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in the province of
            Nova Scotia. We make it simple for you to access a payday loan by
            offering:
          </div>
          <MainNovaScotiaOffer1 />
          <MainNovaScotiaOffer2 />
          <MainNovaScotiaOffer3 />
          <MainNovaScotiaOffer4 />
          <MainNovaScotiaOffer5 />
          <MainNovaScotiaOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          For those who need a payday loan in Nova Scotia
        </h2>
        <div className="location-text">
          <p>
            GoDay has been proud to be issuing payday loans in the province of{" "}
            <a
              href="/blog/goday-is-now-lending-in-nova-scotia/"
              target="_blank"
            >
              Nova Scotia
            </a>{" "}
            since 2018. As one of the many provinces we are serving coast to
            coast, we continue to grow to serve customers like you. From the
            South to the North Shore of Nova Scotia, to the eastern shore of the
            Halifax Harbour – you can get the balance of city life and outdoor
            adventure in Nova Scotia. And to help you get most out of Nova
            Scotia, GoDay is here for any financial assistance you may need.
          </p>
          <p>
            To help get you over the financial hurdle, getting a payday loan in
            Nova Scotia has never been easier.
          </p>
          <p>
            Whether you are taking the quick ferry ride from Halifax to
            Dartmouth or are headed to the blue waters at Carter’s Beach, there
            is always something to see and do around Nova Scotia. Let our online
            payday loan services be there for you so you can get everything and
            more from the place you call home.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          How do you get an online payday loan in Nova Scotia?
        </h2>
        <div className="location-details location-text">
          <MainNovaScotiaInitialContent />
          <MainNovaScotiaSeeMore />
          <MainNovaScotiaMoreContent />
          <MainNovaScotiaApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainNovaScotia;
