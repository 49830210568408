import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainOttawa = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainOttawaReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainOttawaReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay uses best in class encryption to ensure your personal
            information is only used with your authorization. We won’t share
            your payday <a href="/application">loan application</a> information
            with any other online payday loan operators, in person payday
            lenders, or any other third parties in Ottawa or otherwise.
          </div>
        </div>
      ) : null;

    const MainOttawaReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is one of the top, and most ttrusted sources of online payday
            loans in Ottawa and across all of Canada. But you don’t have to take
            our word for it, just look at our customer reviews on TrustPilot,
            check our A+ rating from the Better Business Bureau, or review our
            Ontario Payday Loan License before getting an online payday loan
            online from us.
          </div>
        </div>
      ) : null;

    const MainOttawaReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            Our process is completely 100% online. Don’t walk into a retail
            store for a payday loan in Ottawa ever again. The average GoDay
            online payday <a href="/application">loan application</a> takes just
            5 minutes to complete. Better yet, the fully automated GoDay system
            can provide a decision instantly so you can get your online payday
            loan and be on your way.
          </div>
        </div>
      ) : null;

    const MainOttawaReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            We want to help. Any time you phone our service line you’ll be
            speaking with a real person here in our Toronto office who can get
            you back on track. Get an online payday loan with GoDay today!
          </div>
        </div>
      ) : null;

    const MainOttawaReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            No matter where you live; whether it’s in the heart of the nation’s
            capital or just outside of Ottawa, GoDay.ca offers the quickest
            online payday loans. No driving, no faxing, no face to face
            communication, no parking tickets, no traffic, and no waiting in
            line for an online payday loan from GoDay.ca.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainOttawaReason1 />
        <MainOttawaReason2 />
        <MainOttawaReason3 />
        <MainOttawaReason4 />
        <MainOttawaReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainOttawaOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainOttawaOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainOttawaOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainOttawaOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainOttawaOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainOttawaOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Ontario</small>
      </h3>
    </section>
  );

  const MainOttawaSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainOttawaInitialContent = () => (
    <section>
      <p>
        In order to get an online payday loan just go to our website and
        complete a simple online payday loan application. As long as you put in
        the correct personal, banking, employment, and payroll information into
        the online payday loan form and we approve you you’ll have your funds in
        less than 24 hours.
      </p>
      <p>
        Once your online payday loan application is complete our Toronto office
        will reach out to you to set up your account and discuss how funding
        works Our automated and online cash advance system will review your
        application and let you know how much we can approve you for. For all
        Ottawa residents, online payday loans are regulated by the province of
        Ontario. For a licensed ontario payday lender our Ottawa online payday
        loans range from $100 to $1,500 in value.
      </p>
    </section>
  );

  const MainOttawaMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          After you’ve signed your loan documents we use the best tools in the
          industry to get you your online payday loan quickly and easily.
          Depending on the when you sign your agreement and who you bank with
          your online payday loan might be delivered the same day or the very
          next morning. This is just because of the way the banks transfer
          funds. A GoDay representative can provide you all the necessary
          information once your online payday loan is approved.
        </p>
        <p>
          No matter where in Ottawa you are GoDay.ca can get you your Ottawa
          online payday loan immediately. Be one of the thousands of people
          across Canada that trust GoDay.ca for their online payday loan needs.
          Whether you’re exploring the{" "}
          <a
            href="https://en.wikipedia.org/wiki/National_Gallery_of_Canada"
            target="_blank"
            rel="noopener noreferrer"
          >
            National Gallery of Canada
          </a>
          , the{" "}
          <a
            href="https://en.wikipedia.org/wiki/Rideau_Canal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Rideau Canal
          </a>{" "}
          or{" "}
          <a
            href="https://en.wikipedia.org/wiki/Ch%C3%A2teau_Laurier"
            target="_blank"
            rel="noopener noreferrer"
          >
            Château Laurier
          </a>
          , this can be an expensive city. Don’t fret, knowing that GoDay.ca can
          provide you with a payday loan online quickly and easily.
        </p>
        <p>
          Actions speak louder than words. Here is some feedback from some of
          our existing payday loan customers who have submitted feedback for
          GoDay.ca through TrustPilot - an independent third party website. Be
          sure to check out the{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            GoDay.ca TrustPilot Reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainOttawaApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Ottawa
      </h1>
      <div className="location-text">
        <p>
          Borrowing money is something everyone does at one point or another in
          life, whether it’s for a car, appliance, or unexpected expense.
          Whatever the reason, as long as you just need a short term solution,
          our online <Link to="/">payday loans</Link> can help. Whether your car
          needs repaired, you’re tight on rent, or just have a reasonable
          financial opportunity available to you our online payday loans in
          Ottawa can help.
        </p>
        <p>
          Join the thousands of Canadians that already trust GoDay.ca to get
          them their <Link to="/blog/">online payday loan</Link> quickly and
          efficiently! Our process is one of the simplest out there and takes
          just minutes to complete. Once approved you’ll get the funds for your
          online payday loan within less than 24 hours.
        </p>
        <p>
          We use all of the industry’s fastest tools to look over your online
          payday loan application, setup your account, and get the funds to you!
        </p>
        <p>
          GoDay.ca is proud to have been offering payday loans in Ottawa since
          2012.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why trust GoDay.ca for your online payday loan
        </h2>
        <MainOttawaReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans both in Ottawa and
            across Canada. We make it simple for you to access a payday loan by
            offering:
          </div>
          <MainOttawaOffer1 />
          <MainOttawaOffer2 />
          <MainOttawaOffer3 />
          <MainOttawaOffer4 />
          <MainOttawaOffer5 />
          <MainOttawaOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          You may need an online payday loan if you live in the nation’s capital
        </h2>
        <div className="location-text">
          <p>
            Sure, Ottawa may have Canada’s highest standard of living, but the
            fact remains that the cost of everything is increasing. To help you
            cope with the rising prices and unexpected expenses in Ottawa you
            can always turn to online payday loans. We also suggest that you
            check out some of our staff’s favorite Ottawa more budget friendly
            suggestions - after all, our team has been living and providing the
            best way to get a payday loan online in Ottawa since 2012.
          </p>
          <p>
            With Canada turning 150 years old this year, Ottawa is the place to
            be. We want to help you live the celebration to the fullest &amp;
            we’re so happy to be able to serve this incredible location with our
            payday loan services.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          What’s the process and how does it work?
        </h2>
        <div className="location-details location-text">
          <MainOttawaInitialContent />
          <MainOttawaSeeMore />
          <MainOttawaMoreContent />
          <MainOttawaApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainOttawa;
