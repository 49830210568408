import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up(1024)]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      paddingLeft: theme.spacing(20),
      paddingRight: theme.spacing(20),
    },
    textAlign: "center",
  },
  title: {
    paddingBottom: theme.spacing(2),
    fontSize: "26pt",
    [theme.breakpoints.up(1024)]: {
      paddingBottom: theme.spacing(4),
      fontSize: "33pt",
    },
    color: theme.palette.text,
  },
  subtitle: {
    paddingBottom: theme.spacing(2),
    fontSize: "23pt",
    color: theme.palette.text,
  },
  text: {
    paddingBottom: theme.spacing(1),
    fontSize: "13pt",
    color: theme.palette.text,
  },
}));

const MainError = () => {
  const classes = useStyles();
  return (
    <main className={classes.container}>
      <header>
        <h1 className={classes.title}>404 - Page Not Found</h1>
      </header>
      <div className={classes.subtitle}>The requested page was not found</div>
      <div className={classes.text}>
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </div>
    </main>
  );
};

export default MainError;
