import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./location.css";

const MainVictoria = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainVictoriaReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainVictoriaReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            When we say that GoDay is secure, we mean it. GoDay uses the most
            secure resources to ensure your personal information is safe and not
            used without your authorization. And we you can rest easy knowing
            that we don’t share your online payday loan application information
            with anyone else.
          </div>
        </div>
      ) : null;

    const MainVictoriaReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is a trusted source for online payday loans in Victoria and
            across Canada. Let us to prove it – see our reviews on TrustPilot
            from customers like you, our A+ accreditation by the Better Business
            Bureau, or review our British Columbia Payday Loan License before
            choosing us for your payday loan.
          </div>
        </div>
      ) : null;

    const MainVictoriaReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            GoDay is an online company. We do have physical offices across
            Canada, but we only operate 100% online. Why? Because we like to
            make things easy for you by making sure that you can apply for an
            online payday loan with us 24 hours a day 7 days a week so you don’t
            have to walk into a retail store in Victoria for a payday loan
            again.
          </div>
        </div>
      ) : null;

    const MainVictoriaReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            Have a question for us? We’re here to help! Get in touch with us by
            reaching out to our customer service line and you’ll be speaking
            with one of our amazing customer service representatives who will be
            happy to help you with any questions you may have about your loan.
          </div>
        </div>
      ) : null;

    const MainVictoriaReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            No matter where you’re located on the Pacific Coast, GoDay offers
            speedy, smart and secure online payday loans for people like you.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainVictoriaReason1 />
        <MainVictoriaReason2 />
        <MainVictoriaReason3 />
        <MainVictoriaReason4 />
        <MainVictoriaReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainVictoriaOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainVictoriaOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainVictoriaOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainVictoriaOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainVictoriaOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainVictoriaOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in British Columbia</small>
      </h3>
    </section>
  );

  const MainVictoriaSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainVictoriaInitialContent = () => (
    <section>
      <p>
        Looking to get an online payday loan with us? It’s simple, speedy and
        secure. The first step is to head to our website to fill out an online
        payday loan application. Please ensure that you double check your
        application as we need the most accurate information in order to process
        your online payday loan. Your application for may be declined if the
        information you provide is inaccurate and/or unable to be validated by
        our automated online payday loan system.
      </p>
      <p>
        Once you have completed your online payday loan application with us our
        customer service team will reach out to you to set up your GoDay account
        and discuss your funding options. Your application will then be reviewed
        by our automated and online cash advance system to determine how much
        money we can approve you for. Our online payday loans are regulated by
        the province of British Columbia for Victoria residents and, as a
        licensed payday lender, our online payday loans for Victoria range from
        $100 to $1,500.
      </p>
    </section>
  );

  const MainVictoriaMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          After you have reviewed and signed your online payday loan documents,
          your application is complete and your money will be deposited into
          your account. We use the industry’s most reliable tools to ensure your
          cash advance is delivered as quickly as possible. When will you
          receive your cash? It all depends on the time of day that we receive
          your signed loan application and the business hours of your financial
          institution. You may receive your money within the same day or may
          wake up to it the next morning. That’s right, within 24 hours you will
          have the cash you need. A GoDay representative can provide you with
          further details once your online payday loan is approved.
        </p>
        <p>
          No matter where along the Pacific Coast you are in Victoria or the
          surrounding area, GoDay provides people like you with quick and easy
          payday loans. So head to Fisherman’s Wharf or get outdoors to take on
          your favourite hike, because GoDay is here for you whenever you need a
          hand bridging the gap.
        </p>
        <p>
          Don’t just take our word for it. Listen to what other people who have
          had online payday loans have to say about us by checking out the{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            GoDay.ca TrustPilot Reviews
          </a>
          . Become one of the thousands of Canadians who trust GoDay for their
          online payday loans.
        </p>
      </section>
    );
  };

  const MainVictoriaApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Victoria
      </h1>
      <div className="location-text">
        <p>
          Are you looking for fast cash? Look no further than a smart, safe and
          secure loan with GoDay. Whether you’re looking to get yourself out of
          a financial bind or help keep you afloat until your next paycheck,
          GoDay offers online payday loans in Victoria. That’s why we like to
          let our customers know about borrowing money the GoDay way by
          providing flexible and speedy options that can help you solve those
          urgent but temporary cash-flow needs.
        </p>
        <p>Is an online payday loan in Victoria right for you?</p>
        <p>
          When it comes to getting an online payday loan in Victoria, we like to
          keep it simple; fast cash for you with the security and
          professionalism along with it. GoDay partners with the most reputable
          financial institutions and uses the fastest and most secure tools in
          the industry to ensure you get the speediest service possible. We are
          accepting and approving online payday loan applications 24/7, which
          means that you can apply from your computer, smartphone or tablet
          anytime and our highly automated system will do all it can to ensure
          you receive your funds as soon as possible. Here at GoDay, if we can
          help you get through a few financial hurdles, terrific. If we can help
          you avoid them altogether, even better.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Learn why Victoria trusts GoDay for online payday loans
        </h2>
        <MainVictoriaReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So what are you waiting for? Apply for a payday loan online today.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in London. We make
            it simple for you to access a payday loan by offering:
          </div>
          <MainVictoriaOffer1 />
          <MainVictoriaOffer2 />
          <MainVictoriaOffer3 />
          <MainVictoriaOffer4 />
          <MainVictoriaOffer5 />
          <MainVictoriaOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why You Should Choose a Payday Loan in Victoria
        </h2>
        <div className="location-text">
          <p>
            Since 2012, GoDay has been proud to issue payday loans online to
            people in Victoria just like you. Victoria is a beautiful city and
            known for so many things as British Columbia’s capital. So head
            outdoors for a day of whale watching, hiking, zip lining or biking,
            because that’s what Victoria is all about. And we’ll be here for you
            when you need us, because you can always rely on GoDay’s online
            payday loans to get you through the next adventure.
          </p>
          <p>
            But we know that even with all the outdoor adventures Victoria has
            to offer, housing costs are continuing to rise. And GoDay has been
            helping serve the Victoria area with online payday loans since 2012.
            From the craft breweries along the BC Ale Trail to the beautiful
            Butchart Gardens and everything in between, there is always
            something to do and understand why Victoria is one of the most
            beautiful places to live. And GoDay is here to help you enjoy all of
            these things with our online payday loan services.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          So how do you get an online payday loan Victoria?
        </h2>
        <div className="location-details location-text">
          <MainVictoriaInitialContent />
          <MainVictoriaSeeMore />
          <MainVictoriaMoreContent />
          <MainVictoriaApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainVictoria;
