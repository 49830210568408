import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainAlberta = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainAlbertaReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainAlbertaReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay is 100% online, which means we know how important keeping your
            personal information private and secure is. As an online company,
            this has always been our top priority. We use the best in class
            encryption tools to ensure your personal information is only used
            with your authorization and is never shared.
          </div>
        </div>
      ) : null;

    const MainAlbertaReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is a trusted name for online payday loans across Alberta. And
            we have an A+ accreditation rating from the Better Business Bureau
            to prove it. See what our customers have to say about us by checking
            out our reviews on{" "}
            <a
              href="https://ca.trustpilot.com/review/goday.ca"
              target="_blank"
              rel="noopener noreferrer"
            >
              TrustPilot
            </a>
            .
          </div>
        </div>
      ) : null;

    const MainAlbertaReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            GoDay is here to help get you the cash you need. We make it easy for
            our customers by accepting loans 24 hours a day seven days a week.
            Say goodbye to having to visit a retail store for payday loans
            again. Our easy to use online payday{" "}
            <a href="/application">loan application tool</a> takes less than
            five minutes to complete and provides you with a decision instantly.
          </div>
        </div>
      ) : null;

    const MainAlbertaReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            Have questions for us? We’re here to help! If you have questions
            about your loan application or our online payday loans, our customer
            service team is happy to help. Feel free to{" "}
            <Link to="/contact">contact us</Link> today.
          </div>
        </div>
      ) : null;

    const MainAlbertaReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            Speedy, smart, secure. That is the GoDay way. We help you get you
            the cash you need when you need it the most. No matter where you’re
            located in Alberta – GoDay is here for you.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainAlbertaReason1 />
        <MainAlbertaReason2 />
        <MainAlbertaReason3 />
        <MainAlbertaReason4 />
        <MainAlbertaReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainAlbertaOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainAlbertaOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainAlbertaOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainAlbertaOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainAlbertaOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainAlbertaOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Alberta</small>
      </h3>
    </section>
  );

  const MainAlbertaSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainAlbertaInitialContent = () => (
    <section>
      <p>
        GoDay is here to help with any expenses you may have – whether they were
        budgeted for or not. So how do you get an online payday loan with us?
        The first step is to head to our website to complete our quick and easy
        online payday loan application. We will ask you for a few personal
        details such as your banking, employment, and payroll information. It is
        possible we may need more information from you, but our automated system
        will let you know. From there, you will have an instant decision on
        whether or not you have been approved for one of our payday loans.
      </p>
      <p>
        Once your loan application has been submitted, someone from our customer
        service team will reach out to you to help you set up your GoDay account
        and walk you through the step-by-step process to get the cash you need.
        They will also provide you with your payday loan documents, and when you
        are ready you will review and complete them. Once they are signed, all
        you have to do is send the documents back to us, and from there we use
        the industry’s most reliable tools to get you the cash you need as
        quickly as possible. GoDay’s payday loans are regulated by the province
        of Alberta and as a licensed payday lender, we offer loans in the
        amounts of $100 to $1,500.
      </p>
    </section>
  );

  const MainAlbertaMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          One of the main reasons why people choose GoDay is because we are
          speedy, smart and secure and want to make sure you get the cash you
          need as soon as possible. Depending on the business hours of your
          financial institution and time of day we receive your signed loan
          application, your payday loan will either be delivered to you the very
          same day or the next morning. That’s right, with GoDay you will have
          the cash you need within 24 hours. And if you have any questions, our
          GoDay customer service team is available to help along the way.
        </p>
        <p>
          GoDay can provide you with an online payday loan on the spot! Take a
          look at our{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            TrustPilot
          </a>{" "}
          reviews and become one of the thousands of Canadians who trust GoDay
          for their online payday loans.
        </p>
      </section>
    );
  };

  const MainAlbertaApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast, online payday loan in Alberta
      </h1>
      <div className="location-text">
        <p>
          Everyday Canadians are faced with unexpected costs and financial
          difficulties. And it feels like they always come at the worst time.
          Since there is no way predict when a financial emergency will happen,
          GoDay is here to help you face the unexpected. We understand that
          unexpected costs can come at any time and we provide financial
          solutions that Canadians trust. Operating in Alberta – whether you are
          looking for financial assistance until your next paycheque or need
          help with a financial emergency, learn more about how our online
          payday loans in Alberta are helping people like you.
        </p>
        <p>An online payday loan in Alberta may be right for you.</p>
        <p>
          Since 2012, GoDay has been offering financial solutions to Canadians.
          The best part is that we’re 100% online! This means, you can apply for
          a payday loan with us from virtually anywhere from your favourite
          device and don’t have to head to a retail store to apply for a loan
          again. Payday loan or short-term loan, no matter what you like to call
          it, we understand that you need options when it comes to your money.
          Our online loan applications take a few minutes to complete as we
          offer one of the fastest payday loan services in Canada. We accept
          online payday loan applications 24 hours a day, seven days a week and
          our automated system will keep you connected to ensure you receive
          your funds as soon as possible.
        </p>
        <p>
          Learn more about our online payday loans in Alberta and how we are
          helping people like you.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Alberta relies on GoDay for online payday loans
        </h2>
        <MainAlbertaReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So, what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans across Alberta. We
            make it simple for you to access a payday loan by offering:
          </div>
          <MainAlbertaOffer1 />
          <MainAlbertaOffer2 />
          <MainAlbertaOffer3 />
          <MainAlbertaOffer4 />
          <MainAlbertaOffer5 />
          <MainAlbertaOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Getting a payday loan in Alberta has never been easier
        </h2>
        <div className="location-text">
          <p>
            Welcome to Alberta! It is one of Canada’s most populated and visited
            provinces. And no matter which Canadian season is your favourite,
            the mountains make everyone feel at home. Whether you are heading to
            Lake Louise, going to watch the Calgary Flames or Edmonton Oilers,
            or headed to any of the National Parks – no matter where you are in
            Alberta, don’t let your finances get in the way. Try our services
            and see why the people of Alberta have been turning to GoDay when
            they need cash, fast.
          </p>
          <p>
            Everyday costs such as food and housing continue to rise in Alberta.
            And unplanned expenses pop up. And GoDay has been proud to help
            Ontario residents stay afloat financially since 2012. Our online
            payday loans are intended to help get you back on track – not
            further from it. Which is why you can count on GoDay to be there for
            you whenever you need us. Go ahead and apply for an online payday
            loan today.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Get your quick and easy payday loan in Alberta today
        </h2>
        <div className="location-details location-text">
          <MainAlbertaInitialContent />
          <MainAlbertaSeeMore />
          <MainAlbertaMoreContent />
          <MainAlbertaApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainAlberta;
