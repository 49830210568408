import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./location.css";

const MainPrinceEdwardIsland = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainPrinceEdwardIslandReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainPrinceEdwardIslandReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay is an online company which means we take privacy and security
            seriously. We use best in class encryption to make sure that your
            personal information is only used with your authorization and that
            your payday loan application information is never shared with anyone
            else.
          </div>
        </div>
      ) : null;

    const MainPrinceEdwardIslandReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is one of the top, and most trusted sources of online payday
            loans in PEI and across Canada. But you don’t have to take our word
            for it - take a look at our customer reviews on{" "}
            <a
              href="https://ca.trustpilot.com/review/goday.ca?utm_medium=trustbox&amp;utm_source=Carousel"
              target="_blank"
              rel="noopener noreferrer"
            >
              TrustPilot
            </a>
            , check our A+ rating from the Better Business Bureau, or review our
            Payday Loan License before applying for a loan with us.
          </div>
        </div>
      ) : null;

    const MainPrinceEdwardIslandReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            As we mentioned, we are 100% online. You don’t have to walk into a
            retail store for a payday loan in Prince Edward Island ever again.
            The average GoDay online payday{" "}
            <a href="/application">loan application</a> takes five minutes to
            complete and our highly automated system works to ensure you receive
            the cash you need as soon as possible.
          </div>
        </div>
      ) : null;

    const MainPrinceEdwardIslandReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            We’re here to help! Any time you phone our customer service line you
            will be speaking with a one of our amazing customer service
            representatives who will help you with any questions you may have.
            Let us do the work for you.
          </div>
        </div>
      ) : null;

    const MainPrinceEdwardIslandReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            No matter where you’re located in PEI, GoDay offers speedy, smart
            and secure online payday loans.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainPrinceEdwardIslandReason1 />
        <MainPrinceEdwardIslandReason2 />
        <MainPrinceEdwardIslandReason3 />
        <MainPrinceEdwardIslandReason4 />
        <MainPrinceEdwardIslandReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainPrinceEdwardIslandOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainPrinceEdwardIslandOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainPrinceEdwardIslandOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainPrinceEdwardIslandOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainPrinceEdwardIslandOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainPrinceEdwardIslandOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Prince Edward Island</small>
      </h3>
    </section>
  );

  const MainPrinceEdwardIslandSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainPrinceEdwardIslandInitialContent = () => (
    <section>
      <p>
        When it comes to getting a GoDay payday loan, it’s simple, speedy and
        secure. The first step is to head to our website and complete our quick
        and easy online payday loan application. Once you have provided all your
        correct information - personal, banking, employment, and payroll details
        into our online payday loan application form, our automated system will
        let you know instantly if you are approved.
      </p>
      <p>
        Once your online payday loan application process has been completed, our
        customer service team will reach out to help set up your account and
        discuss how funding works. Your application will be reviewed by our
        automated and online cash advance system to determine how much we can
        approve you for. For all PEI residents, online payday loans are
        regulated by the province of Prince Edward Island and as a licensed
        payday lender, we can offer online payday loans ranging from $100 to
        $1,500.
      </p>
    </section>
  );

  const MainPrinceEdwardIslandMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          Once you have reviewed and signed your online payday loan documents,
          we will use the industry’s fastest tools to ensure your payday loan is
          delivered as quickly as possible. Depending on the time of day that we
          received your signed loan agreement and the business hours of your
          financial institution, your payday loan will either be delivered to
          you in the same day or the next morning. Yes, within 24 hours you will
          have the cash you need. From there, a GoDay representative can provide
          you all the necessary information once your online payday loan is
          approved.
        </p>
        <p>
          No matter where you are in Prince Edward Island, GoDay can provide you
          with an online payday loan on the spot! Join the thousands of
          Canadians from coast to coast that trust GoDay for their online payday
          loan needs. Enjoy the history of PEI with a mix of the city and island
          life all while knowing GoDay can provide you with a financial solution
          quickly and easily.
        </p>
        <p>
          Want to learn more about us? Learn more about what our customers have
          to say about us by checking out some of our{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            TrustPilot reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainPrinceEdwardIslandApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a quick and easy online payday loan in Prince Edward Island
      </h1>
      <div className="location-text">
        <p>
          Are you stuck in a financial bind? Unexpected costs and expenses pop
          up all the time. And trying to stay afloat financially can be
          stressful. Here at GoDay we understand that life happens, and
          customers count on us for quick and easy financial solutions. Whether
          you are needing help staying afloat until your next paycheck or there
          has been a financial emergency, learn more about how our online payday
          loans in Prince Edward Island are helping people like you.
        </p>
        <p>
          Getting a payday loan in Prince Edward Island could not be any
          simpler!
        </p>
        <p>
          Since 2012, GoDay has been helping customers in Prince Edward Island
          and across Canada with online payday loans. Residents in PEI choose
          GoDay because our online loan applications take less than five minutes
          to complete as we offer one of the fastest payday loan services in
          Canada. We accept online payday loan applications 24/7, which mean
          that you can apply from your computer, smartphone or tablet from
          comfort and in the privacy of your own home. This means, you don’t
          have to adjust to business hours or physically head to a store
          location to get a payday loan ever again. GoDay makes payday loans in
          PEI easy so you can get back to what matters.
        </p>
        <p>
          We make payday loans in PEI quick and easy so you can get back to what
          matters.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why you should choose GoDay for payday loans in PEI
        </h2>
        <MainPrinceEdwardIslandReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Prince Edward
            Island. We make it simple for you to access a payday loan by
            offering:
          </div>
          <MainPrinceEdwardIslandOffer1 />
          <MainPrinceEdwardIslandOffer2 />
          <MainPrinceEdwardIslandOffer3 />
          <MainPrinceEdwardIslandOffer4 />
          <MainPrinceEdwardIslandOffer5 />
          <MainPrinceEdwardIslandOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Get your quick and easy payday loan in PEI today
        </h2>
        <div className="location-text">
          <p>
            As Canada’s smallest province, Prince Edward Island plays a historic
            role in Canada. From the miles of beautiful beaches to the Cavendish
            Boardwalk, there is always something to do in PEI. But as everyday
            costs continue to rise throughout all of Canada, don’t let this hold
            you back from enjoying the place you call home. GoDay is here for
            you when you need us the most.
          </p>
          <p>
            Whether you are Oyster tonging with experts to cruising along the
            Charlottetown harbour as the sun goes down, there is always
            something to see and do across Prince Edward Island. And GoDay is
            here to help you enjoy all of these things with our online payday
            loan services.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          So how do you get an online payday loan in Prince Edward Island?
        </h2>
        <div className="location-details location-text">
          <MainPrinceEdwardIslandInitialContent />
          <MainPrinceEdwardIslandSeeMore />
          <MainPrinceEdwardIslandMoreContent />
          <MainPrinceEdwardIslandApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainPrinceEdwardIsland;
