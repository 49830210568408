import { Link } from "react-router-dom";
import "./quick-message.css";

const QuickMessage = () => (
  <section className="quick-message">
    <div className="quick-message-text content">
      COVID-19: We’re open and funding loans 7 days a week. Your financial needs
      are our top priority. <Link to="/contact">Contact us</Link> for more
      information.
    </div>
  </section>
);

export default QuickMessage;
