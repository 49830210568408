import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainOntario = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainOntarioReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainOntarioReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay is 100% online. Which means we take your privacy and security
            seriously. As an online company, this has always been our top
            priority. We use the best in class encryption tools to ensure your
            personal information is only used with your authorization and is
            never shared.
          </div>
        </div>
      ) : null;

    const MainOntarioReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is a trusted name for online payday loans across Ontario. And
            we have an A+ accreditation rating from the Better Business Bureau
            to prove it. If you want to hear what our customers have to say
            about us, check out our hundreds of reviews on{" "}
            <a
              href="https://ca.trustpilot.com/review/goday.ca?utm_medium=trustbox&amp;utm_source=Carousel"
              target="_blank"
              rel="noopener noreferrer"
            >
              TrustPilot
            </a>
            .
          </div>
        </div>
      ) : null;

    const MainOntarioReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            We make it easy for our customers to get the cash you need, when you
            need it. Say goodbye to having to visit a retail store for payday
            loans again. Our easy to use{" "}
            <a href="/application">online payday loan application tool</a> takes
            less than five minutes to complete and provides you with a decision
            instantly.
          </div>
        </div>
      ) : null;

    const MainOntarioReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            Have questions for us? We’re here to help! If you have questions
            about your loan application or our online payday loans, our customer
            service team is happy to help. Feel free to{" "}
            <Link to="/contact">contact us</Link> today.
          </div>
        </div>
      ) : null;

    const MainOntarioReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            Speedy, smart, secure. That is the GoDay way. We help you get you
            the cash you need when you need it the most. No matter where you’re
            located in Ontario - GoDay is here for you.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainOntarioReason1 />
        <MainOntarioReason2 />
        <MainOntarioReason3 />
        <MainOntarioReason4 />
        <MainOntarioReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainOntarioOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainOntarioOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainOntarioOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainOntarioOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainOntarioOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainOntarioOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Ontario</small>
      </h3>
    </section>
  );

  const MainOntarioSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainOntarioInitialContent = () => (
    <section>
      <p>
        GoDay is here to help with any expenses you may have – whether they were
        budgeted for or not. So how do you get an online payday loan with us?
        The first step is to head to our website to complete our quick and easy
        online payday loan application. We will ask you for a few personal
        details such as your banking, employment, and payroll information. It is
        possible we may need more information from you, but our automated system
        will let you know. From there, we will be able to give you an instant
        decision on whether or not you have been approved for one of our payday
        loans.
      </p>
      <p>
        Once your loan application has been submitted, someone from our customer
        service team will reach out to you to help you set up your GoDay account
        and walk you through the step-by-step process to get the cash you need.
        They will also provide you with your payday loan documents, and when you
        are ready you will review and complete them. Once they are signed, all
        you have to do is send the documents back to us, and from there we use
        the industry’s most reliable tools to get you the cash you need as
        quickly as possible. GoDay’s payday loans are regulated by the province
        of Ontario and as a licensed payday lender, we offer loans in the
        amounts of $100 to $1,500.
      </p>
    </section>
  );

  const MainOntarioMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          One of the main reasons why people choose GoDay is because we are
          speedy, smart and secure and want to make sure you get the cash you
          need as soon as possible. Depending on the business hours of your
          financial institution and time of day we receive your signed loan
          application, your payday loan will either be delivered to you the very
          same day or the next morning. That’s right, with GoDay you will have
          the cash you need within 24 hours. And if you have any questions along
          the way, our GoDay customer service team is available to help.
        </p>
        <p>
          GoDay can provide you with an online payday loan on the spot! Join the
          thousands of customers across Canada who turn to GoDay. So, no matter
          where in Ontario you are located, you can count on GoDay to help you
          along the way.
        </p>
        <p>
          Want to hear what our customers have to say about us? Take a look at
          our{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            TrustPilot reviews
          </a>{" "}
          and become one of the thousands of Canadians who trust GoDay for their
          online payday loans.
        </p>
      </section>
    );
  };

  const MainOntarioApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a quick and easy online payday loan in Ontario
      </h1>
      <div className="location-text">
        <p>
          Your car broke down. There was a flood in the basement. You have
          unexpectedly lost your job. These things happen and it is always when
          you least expect them to. Sudden expenses pop up all the time and you
          may not have the cash readily available to help cover those costs.
          This is why people turn to GoDay. Since 2012, GoDay has been helping
          Canadians as a trusted and reliable source for online payday loans,
          GoDay gets you the cash you need quickly and easily. Say goodbye to
          financial stress and hello to GoDay.
        </p>
        <p>
          There are a lot of great things about GoDay – how easy our application
          process is, how quickly we get you the cash you need, our amazingly
          responsive customer service team, and more! But one of the best things
          about GoDay is never having to walk into a physical location for a
          payday loan again. With GoDay you can apply for an online payday loan
          with us from virtually anywhere! Our application tool is available 24
          hours a day, seven days a week. Get an online payday loan with us from
          the comfort and privacy of your own home or while you are on the go
          with your favourite device.
        </p>
        <p>
          Whether it’s help keeping you afloat until your next paycheque or a
          financial emergency, no matter the reason, Ontarians count on GoDay to
          get you the cash you need.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Ontario relies on GoDay for online payday loans
        </h2>
        <MainOntarioReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans across Ontario. We
            make it simple for you to access a payday loan by offering:
          </div>
          <MainOntarioOffer1 />
          <MainOntarioOffer2 />
          <MainOntarioOffer3 />
          <MainOntarioOffer4 />
          <MainOntarioOffer5 />
          <MainOntarioOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Getting a payday loan in Ontario has never been easier
        </h2>
        <div className="location-text">
          <p>
            Welcome to Ontario! It is one of Canada’s most populated and visited
            provinces. And no matter which Canadian season is your favourite,
            there is something to do in Ontario all year round. The CN Tower,
            Parliament Hill in Canada’s capital, the blue water of Lake Huron,
            the ski hills in Collingwood or the big Nickle in Sudbury – no
            matter where you are visiting in Ontario, don’t let your finances
            get in the way. Try our services and see why the people on Ontario
            have been turning to GoDay when they need fast cash.
          </p>
          <p>
            No matter where you are headed in Ontario, unplanned expenses pop
            up. And GoDay has been proud to help Ontario residents stay afloat
            financially since 2012. Our online payday loans are intended to help
            get you back on track – not further from it. Which is why you can
            count on GoDay to be there for you whenever you need us. Go ahead
            and apply for an online payday loan today.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Get your quick and easy payday loan in Ontario today
        </h2>
        <div className="location-details location-text">
          <MainOntarioInitialContent />
          <MainOntarioSeeMore />
          <MainOntarioMoreContent />
          <MainOntarioApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainOntario;
