import "./contact-info.css";

const ContactInfo = (props: any) => {
  let title;
  switch (props.id) {
    case "contact":
      title = "Contact GoDay";
      break;
    case "location-services":
      title = "Funding online loans from coast to coast";
      break;
    default:
      title = "";
      break;
  }

  return (
    <section className="contact-info content">
      <h1 className="contact-info-title">{title}</h1>
      <div>
        <div className="contact-info-text">
          Got a question, concern, or general comment? Please get in touch.
        </div>
        <div className="contact-info-left-group">
          <h3 className="contact-info-subtitle">By email</h3>
          <div className="contact-info-text">
            For general enquiries:{" "}
            <a href="mailto:customerservice@goday.ca">
              customerservice@goday.ca
            </a>
            <br />
            For job opportunities:{" "}
            <a href="mailto:careers@goday.ca">careers@goday.ca</a>
            <br />
            For loan applications:{" "}
            <a href="mailto:apply@goday.ca">apply@goday.ca</a>
            <br />
          </div>
        </div>
        <div className="contact-info-right-group">
          <h3 className="contact-info-subtitle">By phone</h3>
          <div className="contact-info-text">
            Phone: 1-888-984-6329
            <br />
            Fax: 1-855-674-6329
          </div>
        </div>
        <div>
          <h3 className="contact-info-subtitle">Business hours</h3>
          <div className="contact-info-text">
            GoDay Customer Service is available to help between the hours of
            8:30 am and 7 pm Monday through Friday, as well as between 9 am and
            5 pm on weekends and holidays. Christmas Day is the only day that
            GoDay Customer Service is closed.{" "}
            <a href="/application">View the GoDay loan application</a>.
          </div>
        </div>
      </div>
      <div className="contact-info-text">
        <em>
          Note: For the fastest service please fill out an online application
          form by clicking <a href="/application">here</a>. If you have any
          questions, please contact our office at 1-888-984-6329.
        </em>
      </div>
    </section>
  );
};

export default ContactInfo;
