import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import { createTheme } from "utils/theme";
import ScrollToTop from "utils/scroll-to-top";
import Page from "components/core/page";
import pages from "configurations/pages.json";
import "./app.css";

const App = () => {
  const useStyles = makeStyles({
    "@global": {
      "*": {
        margin: 0,
        padding: 0,
        outline: "none",
      },
      body: {
        fontFamily: "Varela Round",
        fontSize: 16,
      },
    },
  });
  useStyles();

  var pageIds = [
    "home",
    "installment",
    "how-it-works",
    "why-us",
    "education",
    "faq",
    "contact",
    "location-services",
    "ontario",
    "british-columbia",
    "nova-scotia",
    "alberta",
    "new-brunswick",
    "saskatchewan",
    "prince-edward-island",
    "manitoba",
    "toronto",
    "ottawa",
    "mississauga",
    "scarborough",
    "barrie",
    "oshawa",
    "hamilton",
    "london",
    "kitchener",
    "oakville",
    "kingston",
    "ajax",
    "waterloo",
    "pickering",
    "brampton",
    "guelph",
    "vancouver",
    "victoria",
    "surrey",
    "halifax",
    "dartmouth",
    "calgary",
    "edmonton",
    "saskatoon",
    "regina",
    "charlottetown",
    "winnipeg",
    "lead",
    "terms",
    "privacy",
    "register",
    "login",
    "logout",
    "account",
  ];

  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          {pageIds.map((id) => (
            <Route
              path={(pages as any)[id].url}
              exact
              render={(props) => <Page {...props} id={id} />}
              key={`page-${id}`}
            />
          ))}
          <Route path="*" render={(props) => <Page {...props} id="error" />} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
