import { useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import pages from "configurations/pages.json";
import "./loan-box.css";
import bbb from "assets/images/bbb.png";
import verisign from "assets/images/verisign.png";

const LoanBox = (props: any) => {
  const isInstallment = props.id === "installment";
  const factor = isInstallment ? 10 : 1;
  const [amount, setAmount] = useState(460 * factor);

  const updateAmount = (value: number) => setAmount(value);

  let title;
  switch (props.id) {
    case "ontario":
    case "british-columbia":
    case "nova-scotia":
    case "alberta":
    case "new-brunswick":
    case "saskatchewan":
    case "prince-edward-island":
    case "manitoba":
    case "toronto":
    case "ottawa":
    case "mississauga":
    case "scarborough":
    case "barrie":
    case "oshawa":
    case "hamilton":
    case "london":
    case "kitchener":
    case "oakville":
    case "kingston":
    case "ajax":
    case "waterloo":
    case "pickering":
    case "brampton":
    case "guelph":
    case "vancouver":
    case "victoria":
    case "surrey":
    case "halifax":
    case "dartmouth":
    case "calgary":
    case "edmonton":
    case "saskatoon":
    case "regina":
    case "charlottetown":
    case "winnipeg":
      title = `Get an online payday loan in ${(pages as any)[props.id].name}`;
      break;
    case "installment":
      title = "Fast online installment loans in Canada";
      break;
    default:
      title = "Get an online payday loan in Canada";
      break;
  }
  const subtitle = isInstallment
    ? "Borrow up to $15,000. 100% Online. Pre-Approval in Minutes."
    : "Borrow up to $1,500 today.";
  return (
    <section className="loan-box content">
      <div className="loan-box-container">
        <h2 className="loan-box-title">{title}</h2>
        <div className="loan-box-text">{subtitle}</div>
        <div className="loan-box-slider">
          <Slider
            min={100 * factor}
            max={1500 * factor}
            step={20 * factor}
            defaultValue={460 * factor}
            onChange={updateAmount}
          />
          <nav className="loan-box-slider-labels">
            <ul>
              <li>${100 * factor}</li>
              <li>${300 * factor}</li>
              <li>${500 * factor}</li>
              <li>${700 * factor}</li>
              <li>${900 * factor}</li>
              <li>${1100 * factor}</li>
              <li>${1300 * factor}</li>
              <li>${1500 * factor}</li>
            </ul>
          </nav>
        </div>
        <div className="clearfix">
          <div className="loan-box-borrow">
            <strong>
              <span className="loan-box-borrow-text">I want to borrow:</span>
              <span className="loan-box-borrow-amount">
                <span className="loan-box-dollar">$</span>
                <span className="loan-box-amount">{amount}</span>
              </span>
            </strong>
          </div>
          <div className="loan-box-buttons">
            <div className="loan-box-apply-now button texture">
              <a href="/application">Apply Now</a>
            </div>
            <div className="loan-box-login button green">
              <a href="/login">
                <span>Login</span>
              </a>
            </div>
          </div>
          <div className="loan-box-social gallery">
            <span
              className="trustpilot-widget"
              data-locale="en-CA"
              data-template-id="53aa8807dec7e10d38f59f32"
              data-businessunit-id="584ace7f0000ff000598de27"
              data-style-height="43px"
              data-style-width="104px"
              data-theme="light"
            >
              <a
                href="https://ca.trustpilot.com/review/goday.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                Trustpilot
              </a>
            </span>
            <span>
              <a
                href="http://www.bbb.org/kitchener/business-reviews/payday-loans/goday-in-toronto-on-1319657"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img id="loan-box-social-bbb" src={bbb} alt="BBB" />
              </a>
            </span>
            <span>
              <img
                id="loan-box-social-verisign"
                src={verisign}
                alt="Verisign"
              />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoanBox;
