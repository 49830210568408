import { useState } from "react";
import "./fine-print.css";

const FinePrint = () => {
  const [isFullContent, setIsFullContent] = useState(false);

  const showFullContent = () => setIsFullContent(true);

  const FinePrintSeeMore = () => !isFullContent ? (
      <section className="fine-print-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons fine-print-see-more-arrow">expand_more</i>
      </section>
    ) : null;

  const FinePrintInitialContent = () => (
    <section>
      <p>
        <u>British Columbia:</u> The APR on a $300.00 loan for 14 days in BC is 391.07% 
        at the rate of $15.00 per $100 borrowed. Online BC Payday License # 67513.
      </p>
      <p>
        <u>Ontario Residents:</u> The cost of borrowing at GoDay.ca is $15.00 per $100.00 
        for each $100.00 advanced. On a $500.00 loan for 14 days, the cost of borrowing is 
        $75.00, with a total payback amount of $575.00 and an annual percentage rate of 391.07%. 
        The maximum allowable cost of borrowing under payday loan agreements is $15.00 for each $100.00 advanced.
      </p>
      <p>
        <u>New Brunswick Residents:</u> The maximum charges permitted for a Payday Loan is 
        15% of the principal. We charge $15 per $100 borrowed. On a $300 loan for 14 days, 
        the total cost of borrowing is $45, with a total payback amount of $345 and an APR of 391.07%.
      </p>
    </section>
  );

  const FinePrintMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`fine-print-group ${className}`}>
        <p>
          <u>Nova Soctia Residents:</u> The total cost of borrowing $100.00 for 14 days is $17.00. 
          Payday Loans are High Cost Loans.
        </p>
        <p>
          <u>Saskatchewan Residents:</u> The maximum allowable cost of borrowing under payday loan 
          agreements in Saskatchewan is $17 per $100 borrowed. We charge $17 per each $100 borrowed. 
          On a $300 loan for 14 days, the total cost of borrowing is $51, with a total payback amount 
          of $351 and an APR of 443.21 %.
        </p>
        <p>
          <u>Manitoba Residents:</u> The APR on a $300.00 loan for 12 days is 517.08% on a rate of 
          $17.00 per $100.00 borrowed. To learn more about your rights as a payday loan borrower, 
          contact the Consumer Protection Office at 1-204-945-3800, 1-800-782-0067 or at &nbsp; 
          <a href="https://www.manitoba.ca/cca/cpo" target="_blank">www.manitoba.ca/cca/cpo</a>.
        </p>
        <p>
          <u>Alberta Residents:</u> The APR on a $300 loan for 42 days is 201.84% for a borrower 
          who is paid weekly on a rate of $15.00 per $100 borrowed.
        </p>
        <p>
          <u>PEI Residents:</u> The cost of borrowing at GoDay.ca is $15.00 per $100.00 per each 
          $100.00 advanced. On a $300.00 loan for 14 days, the cost of borrowing is $45.00, with 
          a total payback amount of $345.00. The maximum allowable cost of borrowing under payday 
          loan agreements is $15.00 per each $100.00 advanced.
        </p>
      </section>
    );
  };

  const FinePrintNonPayment = () => (
    <section>
      <p>
        Non Payment Policy: To collect on a loan in default GoDay.ca will utilize all 
        collection practices available to the issuers of consumer credit in Canada.
      </p>
    </section>
  );

  return (
    <section className="fine-print content">
      <FinePrintInitialContent />
      <FinePrintSeeMore />
      <FinePrintMoreContent />
      <FinePrintNonPayment />
    </section>
  );
};

export default FinePrint;
