import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainEdmonton = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainEdmontonReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainEdmontonReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay is 100% online. As an online company, we know the importance
            of privacy and security. We make sure that your private information
            stays private. And that the personal information you provide us with
            is only used with your authorization and never shared with anyone
            else.
          </div>
        </div>
      ) : null;

    const MainEdmontonReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is one of the most trusted sources of online payday loans in
            Edmonton and across Canada. But you don’t have to take our word for
            it - check out A+ rating from the Better Business Bureau or take a
            look at what our customers have to say about us on{" "}
            <a
              href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
              target="_blank"
              rel="noopener noreferrer"
            >
              TrustPilot
            </a>
            .
          </div>
        </div>
      ) : null;

    const MainEdmontonReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            As we mentioned, GoDay 100% online. Our application tool is
            available 24 hours a day, seven days a week which means you can get
            an online payday loan with us from the comfort and privacy of your
            own home or while you are on the go with your favourite device. The
            average GoDay{" "}
            <a href="/application">online payday loan application</a> takes only
            minutes to complete and our highly automated system works to ensure
            you receive the cash you need as soon as possible.
          </div>
        </div>
      ) : null;

    const MainEdmontonReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            We’re here to help! We may be an online company but our customer
            service representatives are available to help you with any questions
            you may have about your application or the process along the way.
            Let us do the work for you. <Link to="/contact">Contact us</Link>{" "}
            today.
          </div>
        </div>
      ) : null;

    const MainEdmontonReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            No matter where you’re located in Alberta, downtown Edmonton or if
            you’re on your way to the airport or the West Edmonton Mall, GoDay
            offers speedy, smart and secure online payday loans.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainEdmontonReason1 />
        <MainEdmontonReason2 />
        <MainEdmontonReason3 />
        <MainEdmontonReason4 />
        <MainEdmontonReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainEdmontonOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainEdmontonOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainEdmontonOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainEdmontonOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainEdmontonOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainEdmontonOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Alberta</small>
      </h3>
    </section>
  );

  const MainEdmontonSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainEdmontonInitialContent = () => (
    <section>
      <p>A payday loan with GoDay is speedy, smart and secure.</p>
      <p>
        So how do Edmonton residents get started with an online payday loan? The
        first step is to visit our website and apply using our quick and easily{" "}
        <a href="/application">application tool</a>. You will be asked to
        provide some personal details for the application such as your banking
        information, employment and payroll details. Once you have submitted the
        most up to date information, our automated system will give you an
        immediate decision to let you know if you have been approved for one of
        our online payday loans.
      </p>
      <p>
        We keep our application process simple! Once the application has been
        completed, you will hear from our customer service team as they will be
        in touch to help set up your GoDay account. Our automated and online
        cash advance system will review your application and let you know how
        much we can approve you for. GoDay’s online payday loans are regulated
        by the province of Alberta and as a licensed payday lender, we can offer
        online payday loans in Edmonton ranging from $100 to $1,500.
      </p>
    </section>
  );

  const MainEdmontonMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          When the GoDay team reaches out, they will also send along your payday
          loan documents to review. Once you have received the payday loan
          documents, you will sign them and once they are completed, you send
          back to us and we will then begin to process your loan. GoDay uses the
          industry’s most reliable tools to get you the cash you need as quickly
          as possible.
        </p>
        <p>
          Depending on the time of day we receive your signed loan application
          and the business hours of your financial institution, your payday loan
          will either be delivered to you in the same day or the next morning.
          That’s right, with GoDay you will have the cash you need within 24
          hours.
        </p>
        <p>
          No matter where you are located in Alberta, GoDay can provide you with
          an online payday loan on the spot! Have questions along the way? One
          of our GoDay representatives would be happy to assist.{" "}
          <Link to="/contact">Contact us</Link> today.
        </p>
        <p>
          Want to see what others have to say about us? Take a look at our{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            reviews on TrustPilot
          </a>{" "}
          from customers just like you.
        </p>
      </section>
    );
  };

  const MainEdmontonApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a quick and easy online payday loan in Edmonton
      </h1>
      <div className="location-text">
        <p>
          Financial hardships happen. Unexpected costs and expenses pop up all
          the time and trying to stay afloat financially can be stressful. Here
          at GoDay we understand that life happens and is here for you when you
          need us. Whether you are needing help to get to your next paycheck or
          there has been a financial emergency, learn more about how our online
          payday loans in Edmonton are helping people like you.
        </p>
        <p>Getting a payday loan in Edmonton could not be any easier!</p>
        <p>
          Since 2012, GoDay has been helping customers in Edmonton and across
          Canada with online payday loans. The best part about GoDay? Our online
          loan applications take less than five minutes to complete as we offer
          one of the fastest payday loan services in Canada. We accept online
          payday loan applications 24/7, which mean that you can apply from any
          device from comfort and in the privacy of your own home. Why should
          you have to adjust to retail business hours or physically head to a
          store location to get a payday loan again when you can get an online
          payday loan with GoDay? We make getting a payday loan in Edmonton easy
          so you can get back to what matters.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Edmonton trusts GoDay for online payday loans
        </h2>
        <MainEdmontonReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So, what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Edmonton. We
            make it simple for you to access a payday loan by offering:
          </div>
          <MainEdmontonOffer1 />
          <MainEdmontonOffer2 />
          <MainEdmontonOffer3 />
          <MainEdmontonOffer4 />
          <MainEdmontonOffer5 />
          <MainEdmontonOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Get your quick and easy payday loan in Edmonton today
        </h2>
        <div className="location-text">
          <p>
            Living in the beautiful province of Alberta can come with a cost.
            The price of everyday things such as food and housing are continuing
            to rise in Edmonton and to help you with Alberta’s rising costs; you
            can turn to a payday loan with GoDay.
          </p>
          <p>
            As the capital of Alberta, in Edmonton you can experience what 18
            hours of sunlight a day feels like in the summer, or how the first
            snowfall transforms the river valley in winter. No matter the
            season, Edmonton is made for exploring. GoDay is here to help you
            enjoy everything Edmonton has to offer and more with our online
            payday loan services.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          How does an online payday loan with GoDay work?
        </h2>
        <div className="location-details location-text">
          <MainEdmontonInitialContent />
          <MainEdmontonSeeMore />
          <MainEdmontonMoreContent />
          <MainEdmontonApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainEdmonton;
