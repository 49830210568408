import { useEffect } from "react";
import "./trustpilot.css";

const Trustpilot = () => {
  useEffect(() => {
    if (document.getElementById("trustpilot-script")) {
      const trustboxes = document.getElementsByClassName("trustpilot-widget");
      for (let i = 0; i < trustboxes.length; i++) {
        (window as any).Trustpilot.loadFromElement(trustboxes[i]);
      }
    } else {
      const script = document.createElement("script");
      script.id = "trustpilot-script";
      script.src =
        "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <section className="trustpilot content">
      <h2 className="trustpilot-title">What our customers have to say</h2>
      <div
        className="trustpilot-widget"
        data-locale="en-CA"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="584ace7f0000ff000598de27"
        data-style-height="auto"
        data-style-width="100%"
        data-theme="light"
        data-schema-type="Organization"
        data-stars="5"
      >
        <a
          href="https://ca.trustpilot.com/review/goday.ca"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </section>
  );
};

export default Trustpilot;
