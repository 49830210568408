import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainAjax = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainAjaxReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainAjaxReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay takes privacy and security very seriously and uses the most
            advanced industry practices to ensure your data is safe and never
            used without your express consent. Unlike some online payday lenders
            who provide your data to others under your nose we never share any
            online payday loan information with any other third parties unless
            you tell us it’s okay to do so.
          </div>
        </div>
      ) : null;

    const MainAjaxReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay possibly the most trusted online payday loan provider in Ajax,
            Toronto, and across Canada. And you don’t have to take our word for
            it, just check out our online payday loan reviews on TrustPilot from
            some of our customers, view our stellar A+ rating with the Better
            Business Bureau, or inspect our Ontario Payday Loan License before
            getting an online payday loan with us.
          </div>
        </div>
      ) : null;

    const MainAjaxReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            The GoDay online payday loan process is entirely online, which means
            no going into a storefront, faxing documents, or anything else.
            Better yet, our online payday loan application takes just 5 minutes
            to complete and our system can get you a decision in just minutes.
            Get your online payday loan quickly so you can get back to what
            matters.
          </div>
        </div>
      ) : null;

    const MainAjaxReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            GoDay.ca was founded in Ontario and operates here! When you call
            GoDay you’re speaking to a real person in a real office in Toronto
            that cares about your situation and wants to help.
          </div>
        </div>
      ) : null;

    const MainAjaxReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            We guarantee satisfaction with our online payday loans. Whether
            you’re in Ajax, Halton, Mississauga, Peel Durham or otherwise we
            provide the fastest payday loans. If you’re not satisfied with our
            service you can return the funds within 48 hours at no charge.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainAjaxReason1 />
        <MainAjaxReason2 />
        <MainAjaxReason3 />
        <MainAjaxReason4 />
        <MainAjaxReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainAjaxOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>Automatic decisions</strong>
        <br />
        <small>
          so that you can get your payday loan and get back to your day
        </small>
      </h3>
    </section>
  );

  const MainAjaxOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Industry leading security</strong>
        <br />
        <small>
          to ensure that your data is safe and won’t be used without your
          permission
        </small>
      </h3>
    </section>
  );

  const MainAjaxOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Clear and simple fees</strong>
        <br />
        <small>
          so you know what you owe and don’t get gouged by hidden fees
        </small>
      </h3>
    </section>
  );

  const MainAjaxOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>No paperwork or faxing required</strong>
        <br />
        <small>
          you can apply from your home, office, or anywhere you have an internet
          connection
        </small>
      </h3>
    </section>
  );

  const MainAjaxOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>We are fully regulated and licensed</strong>
        <br />
        <small>
          our online payday loans have been vetted and approved by the
          Government of Ontario
        </small>
      </h3>
    </section>
  );

  const MainAjaxSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainAjaxInitialContent = () => (
    <section>
      <p>
        Have we mentioned how expensive Ajax is? Whether it’s going out for
        dinner and a show or ordering in it can cost a lot to live in Ajax and
        do all the things you want to do. In order to manage the rising prices
        (and one time expenses) you can always take a look at our online payday
        loan service to see if it’s right for you.
      </p>
      <p>
        Ajax is full of free activities that don’t require an online payday
        loan, if you have a pair of good shoes on your feet, a backpack with
        snacks and some water, and area ready for an adventure, here are some of
        our team’s favorite activities.
      </p>
      <div className="location-quote">
        <p>
          In the summer, we love the St. Lawrence Market, the Ajax Islands, the
          Harbourfront Centre, and the Beaches. All places are free (or almost
          free) to visit and have so much to offer depending on the weather.
        </p>
        <p>
          In the winter, we love Yonge-Dundas square, Skating in Nathan Phillips
          Square, and shopping on Queen Street West. Be sure to take a walk up
          and down Yonge Street, filled everything from clothing and specialty
          shops to storefront payday loan companies. Be sure to skip the lineup
          at the store and visit GoDay.ca for an online payday loan instead!
        </p>
      </div>
      <p>
        So now that you have a few free (or nearly free) activity ideas in Ajax,
        let’s discuss what to do if you need to consider a payday loan online in
        Ajax. What’s the process and how does it work?
      </p>
    </section>
  );

  const MainAjaxMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          In order to get a payday loan online you just have to visit our
          website and go over a simple and easy online payday loan application
          form. Make sure that you put in all the correct information or your
          application could take longer (or worse you may not be eligible for an
          online payday loan with us). We strongly advise every applicant to
          double check the information on their application at each step before
          moving forward. It only takes a few seconds to do and can save you a
          lot of hassle! Once you submit your online payday loan application you
          can get your online payday loan approval within minutes.
        </p>
        <p>
          Once our Toronto office has reviewed your online payday loan
          application we’ll reach out to set up your account and discuss
          possible funding options. Our online payday loan system will then send
          the funds off to your bank for processing, which depending on your
          bank will usually be done the same day. And remember, our loans are
          meant to be small and short term, between $100 and $1,500 and always
          due (by law) on your next regularly scheduled pay day (it is called a
          pay day loan after all!).
        </p>
        <p>
          Now, no matter what the overall cost of the, GoDay.ca can provide you
          with a Ajax Payday Loan on the spot! Join the thousands of Canadians
          from coast to coast that trust GoDay.ca for their online payday loan
          needs. Whether you’re exploring the Annex, the Beaches or the Ajax
          Islands, this can be an expensive city. Don’t fret, knowing that
          GoDay.ca can provide you with a payday loan online quickly and easily.
        </p>
        <p>
          Actions speak louder than words. Here is some feedback from some of
          our Ajax payday loan customers who have submitted feedback for
          GoDay.ca through TrustPilot - an independent third party website. Be
          sure to check out the{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            GoDay.ca TrustPilot Reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainAjaxApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">Get a fast online payday loan in Ajax</h1>
      <div className="location-text">
        <p>
          It’s hard enough to deal with your normal monthly expenses, so when
          your car breaks down, a pipe bursts, or another unexpected emergency
          comes up it can seem impossible to deal with. Fortunately, if you need
          fast access to cash there are plenty of ways to get an online{" "}
          <Link to="/">payday loan</Link> to bridge the gap. For an easy and
          quick provider of payday loan online in Ajax, try GoDay.ca. We work
          harder than any other company to make sure that you get your cash
          quickly. In fact, our application form takes just minutes and you can
          get your cash as early as the same day. With our online payday loans
          you can have up to $1,500 in your bank account just like that.
        </p>
        <p>
          A GoDay.ca online <Link to="/education">payday loan</Link> is a great
          way to get yourself through those tough unexpected expenses. Unlike
          other loans that take years to pay off, a payday loan online or in
          person is focused on giving you immediate short term cash that’s due
          on your next pay day. Instead of paying thousands of dollars in
          interest over a three year period we believe you should only be paying
          for the amount you need and the time you need it for. Our process is
          one of the quickest in the payday loan industry. Once we take a look
          at your online payday loan application, review your account, and get
          your information verified we can provide you with a payday loan offer
          immediately. We’ve been providing Ajax payday loans online since 2012,
          so you can trust us to get you your loan quickly and without any
          hassle.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why choose GoDay.ca for an online payday loan?
        </h2>
        <MainAjaxReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              Come on Ajax! Apply for a payday loan online. We make it speedy,
              smart, and secure.
            </strong>
          </div>
          <div className="location-offers-text">
            Here are just a few reasons to choose GoDay.ca for your Ajax online
            payday loan:
          </div>
          <MainAjaxOffer1 />
          <MainAjaxOffer2 />
          <MainAjaxOffer3 />
          <MainAjaxOffer4 />
          <MainAjaxOffer5 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">Ajax can be expensive</h2>
        <div className="location-details location-text">
          <MainAjaxInitialContent />
          <MainAjaxSeeMore />
          <MainAjaxMoreContent />
          <MainAjaxApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainAjax;
