import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainOakville = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainOakvilleReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainOakvilleReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            For the protection of your personal information, GoDay.ca uses top
            level encryption and only uses your information with your
            authorization. We do not share your online payday loan application
            with our online payday loan competitors, in person payday lenders or
            any other third party companies or organizations in Oakville or
            anywhere else.
          </div>
        </div>
      ) : null;

    const MainOakvilleReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            Being a trusted name in online payday loans throughout Oakville and
            the surrounding region, GoDay.ca makes sure to keep posted reviews
            with TrustPilot, while making our A+ accreditation by the Better
            Business Bureau and our Ontario Payday Loan License readily
            accessible for you to review before applying.
          </div>
        </div>
      ) : null;

    const MainOakvilleReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            We do everything 100% online. You won’t have to leave the comfort of
            your home in Oakville or the surrounding region to go into a retail
            office for an online payday loan with us. Our payday{" "}
            <a href="/application">loan application</a> takes just under 5
            minutes for anyone to complete and, what’s even better, our
            automated system can let you know if you’re pre-approved in seconds
            so that you can take care of your payday loan online while focusing
            on other important things that may require more of your attention.
          </div>
        </div>
      ) : null;

    const MainOakvilleReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            We love Oakville. It’s not too far from Toronto, where we operate.
            This means that if you should ever need to call in and speak to a
            customer service representative, you will be speaking to a friendly
            fellow Canadian in your time zone! Whether having a picnic by
            Lakeshore or a stroll in Downtown Oakville, our team of experts in
            issuing online payday loans in Oakville and the surrounding area
            cares about helping you sort out your interests. Apply for a payday
            loan online with GoDay today!
          </div>
        </div>
      ) : null;

    const MainOakvilleReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            You may live in Halton, Peel, York, Durham or the city of Toronto.
            Regardless, GoDay.ca is the fastest option for payday loans! No need
            to travel or even speak to anyone face to face; no troubles with
            meter maids or rush hour; and most importantly, no waiting in line
            for an online payday loan with GoDay.ca!
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainOakvilleReason1 />
        <MainOakvilleReason2 />
        <MainOakvilleReason3 />
        <MainOakvilleReason4 />
        <MainOakvilleReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainOakvilleOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 submissions</strong>
        <br />
        <small>so that you can apply at any time of day</small>
      </h3>
    </section>
  );

  const MainOakvilleOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Secure online processing</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainOakvilleOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Upfront fee disclosure</strong>
        <br />
        <small>so you know what will be exchanged</small>
      </h3>
    </section>
  );

  const MainOakvilleOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Fast cash</strong>
        <br />
        <small>
          to keep up with what’s important while getting your payday loan funds
        </small>
      </h3>
    </section>
  );

  const MainOakvilleOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>No paperwork or faxing required</strong>
        <br />
        <small>for a payday loan online</small>
      </h3>
    </section>
  );

  const MainOakvilleOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>We are licensed</strong>
        <br />
        <small>in Ontario to issue online payday loans</small>
      </h3>
    </section>
  );

  const MainOakvilleSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainOakvilleInitialContent = () => (
    <section>
      <p>
        Congratulations, you live in Oakville, one of the most beautiful
        townships in Canada! It can get pretty expensive in Oakville, and a lot
        of the more fun things to do an see in particular can be on the pricy
        side. All the explorative things you may want to do, like shopping
        around Downtown Oakville, taking a tour via horseback ride at The Ranch
        by Burnhamthorpe Road or even a boat tour by the lake - these things all
        cost a bit of money!
      </p>
      <p>
        Payday loans will help you tackle rising prices and unexpected expenses
        in Oakville. You could also check with our staff to see some of their
        favorite budget-friendly activities to do in and around Oakville. We are
        based out of Toronto, but, our staff is very familiar with most of
        southern Ontario.
      </p>
    </section>
  );

  const MainOakvilleMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          Oakville is filled with different things to do that don’t require an
          online payday loan if you enjoy the walk and don’t mind packing some
          food or drink. There is beautiful scenery to take in and engage!
        </p>
        <p>
          Lion’s Valley Park is a beautiful place to visit and Bronte Creek
          Provincial Park is filled with heritage and splendor. Gairloch Gardens
          and the Oakville Lighthouse are other fine sites along with Bronte
          Heritage Park and vibrant Downtown Oakville (which is particularly
          wonderful at night). Plenty of retail Downtown as well. Don’t wait for
          a payday loan that may take long to get. Apply for an online payday
          loan at GoDay.ca instead!
        </p>
        <p>
          So let’s say you’re considering an online payday loan in Oakville;
          what are the next step and how does it work?
        </p>
        <p>
          All you need to do to get a payday loan online is go to our website
          and fill out the quick and easy online payday loan application. You’re
          going to want to correctly enter your personal, banking, employment
          and payroll information on the online payday loan application form. If
          the information you provide on this payday loan online application
          form is inaccurate and/or unable to be verified by our online payday
          loan system, you may be declined. After completing the form, we would
          advise you to take 30 seconds to look everything over and make sure
          your online payday loan application information is correct before you
          click the “Submit” button. If anything is out of place, even
          mistakenly, we will not be able to offer you a payday loan online.
        </p>
        <p>
          One we have received your application from Oakville to Toronto for an
          online payday loan, we’ll contact you by phone or email to set up your
          account and discuss your funding options. Your application will be
          reviewed by our automated and online cash advance system to determine
          how much we can approve you for. Payday loans are of course regulated
          by the province of Ontario for Oakville residents and, as a licensed
          payday lender, our online payday loans for Oakville range from $100 to
          $1,500 in value and would be due on your next regularly scheduled pay
          date.
        </p>
        <p>
          We will use the industry’s most reliable tools to ensure your cash
          advance is delivered as quickly as possible after you have reviewed
          and signed your online payday loan documents. Your online payday loan
          will then be delivered on either the same day or overnight. This
          depends on the time of day at which you are approved for the online
          payday loan and sign the payday loan online agreement, and your
          financial institution. Confirmation of this can be provided by a
          GoDay.ca representative after your application for an online payday
          loan has been approved.
        </p>
        <p>
          No matter your address in Oakville, GoDay.ca can provide you with a
          payday loan immediately. Thousands of Canadians over the years have
          already received loans, and you can be one of them today! You could be
          shopping Downtown or at Oakville Place; regardless, this town can
          still be expensive. Not to worry! GoDay.ca is here to provide you with
          our quick and easy payday loan online service.
        </p>
      </section>
    );
  };

  const MainOakvilleApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Oakville
      </h1>
      <div className="location-text">
        <p>
          Unforeseen expenses can occur without warning. An urgent need for
          transportation, refrigerator repair or cell phone replacement usually
          doesn’t leave much space to breath until it’s fixed. In Oakville,
          GoDay.ca can help with a timely <Link to="/">online payday loan</Link>
          . Submitting an online payday loan application takes minutes and as
          much as $500 could be deposited into your account on the same day you
          submit an application.
        </p>
        <p>
          GoDay.ca’s payday loan can help you quickly get things back in order.
          An <Link to="/education">online payday loan</Link> or in person loan
          is unsecured and suited to help you take care of unexpected expenses
          that may occur between today and your next regularly scheduled pay
          date. We can review your online payday loan application, open an
          account for you and send you a payday loan quickly by use of unique
          industry tools.
        </p>
        <p>
          Since 2012, GoDay.ca has been proud to issue payday loans online,
          offering residents of not only Oakville, but many other towns and
          cities, a quick and nearly effortless payday loan process.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">Oakville can trust GoDay</h2>
        <MainOakvilleReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              Let’s go, Oakville! Apply for a payday loan online. It’s a piece
              of cake!
            </strong>
          </div>
          <div className="location-offers-text">
            We’re licensed by the province of Ontario to issue payday loans in
            Oakville and we make it easy for you to get a payday loan with:
          </div>
          <MainOakvilleOffer1 />
          <MainOakvilleOffer2 />
          <MainOakvilleOffer3 />
          <MainOakvilleOffer4 />
          <MainOakvilleOffer5 />
          <MainOakvilleOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          For those who live in Oakville and the surrounding region
        </h2>
        <div className="location-details location-text">
          <MainOakvilleInitialContent />
          <MainOakvilleSeeMore />
          <MainOakvilleMoreContent />
          <MainOakvilleApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainOakville;
