import "./partners.css";
import verisign from "assets/images/verisign.png";
import bbb from "assets/images/bbb.png";
import ccfa from "assets/images/ccfa.png";

const Partners = () => (
  <section className="partners content gallery">
    <span>
      <img id="partners-verisign" src={verisign} alt="Verisign" />
    </span>
    <span>
      <a
        href="http://www.bbb.org/kitchener/business-reviews/payday-loans/goday-in-toronto-on-1319657"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img id="partners-bbb" src={bbb} alt="BBB" />
      </a>
    </span>
    <span>
      <a
        href="http://canadiancfa.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          id="partners-ccfa"
          src={ccfa}
          alt="Canadian Consumer Finance Association"
        />
      </a>
    </span>
  </section>
);

export default Partners;
