import { useState } from "react";
import { Link } from "react-router-dom";
import "./main-lead.css";

const MainLead = (props: any) => {
  const [isConsented, setIsConsented] = useState(false);

  const consent = () => setIsConsented(!isConsented);

  const className = isConsented ? "texture" : "disabled";
  const applicationUrl = isConsented
    ? `/inbound/${props.match.params.applicationId}`
    : "";

  return (
    <main className="main-lead content">
      <h1 className="main-lead-title">
        GoDay has been selected as your online lender!
      </h1>
      <div className="main-lead-text">
        <p>
          We need some additional information from you to complete your account
          setup and process your application.
        </p>
        <p>
          <input
            type="checkbox"
            name="main-lead-consent"
            id="main-lead-consent"
            checked={isConsented}
            onChange={consent}
          />
          <label htmlFor="main-lead-consent">
            By checking the box and clicking “Let’s Go!”, you consent to GoDay’s
            collection, use and disclosure of your personal information as
            described in our{" "}
            <Link to="/privacy" target="_blank">
              Privacy Policy
            </Link>
            .
          </label>
        </p>
      </div>
      <section className={`main-lead-go button ${className}`}>
        <a href={applicationUrl}>Let’s Go!</a>
      </section>
    </main>
  );
};

export default MainLead;
