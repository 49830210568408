import "./basic-location.css";
import flag from "assets/images/flag.png";

const InstallmentLocation = () => {
  const BasicLocationServices = () => (
    <section className="basic-location-services">
      <h2 className="basic-location-title">
        Online installment loans across Canada
      </h2>
      <div>
        GoDay has been issuing online loans since 2012. We’re headquartered in
        Toronto, but servicing Canadians from coast to coast. Try or online
        personal loan service and let us demonstrate why thousands of Canadians
        turn to GoDay when they need an online short term loan.
      </div>
    </section>
  );

  const BasicLocationFlag = () => (
    <section className="basic-location-flag gallery">
      <img src={flag} alt="Canadian Flag" />
    </section>
  );

  return (
    <section className="basic-location content">
      <BasicLocationServices />
      <BasicLocationFlag />
    </section>
  );
};

export default InstallmentLocation;
