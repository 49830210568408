import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainSaskatoon = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainSaskatoonReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainSaskatoonReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            We want to help you eliminate debt, so our online payday loans are
            directly attributed to your income. Meaning, we only let you borrow
            in proportion to your pay cheque. Simply put, you can’t borrow more
            than you make. This might seem restrictive, but borrowing more than
            you earn is a sure-fire way to increase debt, not avoid it. GoDay
            believes in responsible lending, and we want to get you the cash you
            need while providing you with enough financially flexibility to pay
            it back on your next payday.
          </div>
        </div>
      ) : null;

    const MainSaskatoonReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            Online payday loans in Saskatoon are straightforward and simple with
            us. Instead of printing off documents and bringing them to a bank or
            storefront lender, our hassle free online option can save you time
            and frustrations. Just fill out an online application and you’re
            ready to go! You can <a href="/application">apply online</a>, from
            the comfort and privacy of your home. Plus, we’re always here to
            help you with the payday loan process should you need it.
          </div>
        </div>
      ) : null;

    const MainSaskatoonReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            Our online payday loans allow you to borrow the amount you need. If
            you only need $100 don’t take out $1000, as that may cause more
            debt! Unlike other lenders that force you to borrow a “minimum
            amount” we make it easy to borrow just what you need and potentially
            avoid hundreds of dollars in additional expenses.
          </div>
        </div>
      ) : null;

    const MainSaskatoonReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            We don’t ask you what your loan is for and it doesn’t affect our
            decision to lend you money. Unlike some installment loan providers
            that want to know information as specific as whether your loan is
            for a new refrigerator or car repair bill, we respect our customer’s
            privacy and ability to use their money as they wish.
          </div>
        </div>
      ) : null;

    const MainSaskatoonReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            GoDay is professional, licensed payday lender. It’s important to
            know where your money comes from, and we proudly display our
            licenses on nearly every page of our website. We’ve had hundreds of
            happy customers, and many have shared their positive reviews, which
            we encourage you to check out! Say goodbye to fly by night payday
            lenders and say hello to the pros.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainSaskatoonReason1 />
        <MainSaskatoonReason2 />
        <MainSaskatoonReason3 />
        <MainSaskatoonReason4 />
        <MainSaskatoonReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainSaskatoonSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainSaskatoonInitialContent = () => (
    <section>
      <p>
        At GoDay.ca we’re proudly owned and operated in Canada. Since our launch
        in 2012, we have grown to service the Saskatoon with online payday
        loans. On a budget? We have a few free (or almost free activities) that
        we would love to share with you.
      </p>
      <div className="location-quote">
        <p>
          If you’re into cars and tractor then you have to check out the Western
          Development Museum. The main street was setup like an old town and
          there are some wonderfully restored cards from various time periods. A
          great place to go for young and old - especially as it does not rely
          on the weather!
        </p>
        <p>
          Have young ones at home? The Saskatoon Forestry Farm Park &amp; Zoo is
          an awesome smaller zoo to visit while in Saskatoon.
        </p>
        <p>
          Free activities are always awesome. If the weather is nice, we
          recommend the Meewasin Valley. There are some excellent walking trails
          and gorgeous river views.
        </p>
      </div>
    </section>
  );

  const MainSaskatoonMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          If you want to get started with these activities and not worry about
          cash, complete a Saskatoon{" "}
          <a href="/application">online payday loan application</a> now. We are
          a licensed online payday lender in Saskatchewan and eager to do
          whatever we can to get you a fast online payday loan in Saskatoon.
        </p>
        <p>
          Regardless of if you live in Nutana, Varsity View, River Heights, City
          Park, Mayfair, Sutherland, Silverspring, Riversdale, or anywhere else
          is Saskatoon, we can process your online payday loan application from
          your computer or mobile device. Our Saskatoon online payday loans
          require no driving to a payday loan storefront, no paperwork, and no
          in person interview. All we need is for you to submit an online payday
          loan application on our website.
        </p>
        <p>
          Actions speak louder than words. Below you will find reviews from some
          of our Saskatoon payday loan customers who have submitted feedback for
          GoDay.ca through TrustPilot. Trust Pilot is an independent third party
          website. Be sure to check out the{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            GoDay.ca TrustPilot Reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainSaskatoonApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Saskatoon
      </h1>
      <div className="location-text">
        <p>
          It can happen to anyone, from any walk of life. Just when you finally
          get caught up with payments, unexpected expenses catch you by surprise
          and turn your financial plan into turmoil. Accidents, unexpected
          vehicle repairs and family needs are just some of the many
          difficulties that can set back months of hard work and savings.
          Delaying a specific payment is sometimes an option, though it comes
          with a hefty price tag, especially when it’s a credit card company.
          There are other options, and a GoDay online{" "}
          <Link to="/">payday loan</Link> can be the solution you need to help
          cover unexpected costs while managing debt.
        </p>
        <p>
          We are a Canadian company that specializes in{" "}
          <Link to="/blog/">online payday loans</Link> in Saskatoon and across
          the country. For years, we’ve helped Canadians from coast to coast get
          through tough times, and we’re here for you too. When it comes to
          payday loans Saskatoon, we make it quick and easy to take control of
          your financial landscape. Our payday loans can be processed in as
          little as 24 hrs, and in some cases as quick as 30 minutes! This is
          all without you having to go a dimly lit retail store across town to
          drop off paperwork. GoDay makes the Saskatoon payday loan process easy
          to understand, quick, and clear cut. We understand financial surprises
          can be extremely stressful, and we’re here to help you use a payday
          loan in Saskatoon to get back on top of your financial goals.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Is a Saskatoon payday loan right for you?
        </h2>
        <div className="location-text">
          All financial decisions are important and every dollar adds up.
          Therefore, it’s important to take the time and decide whether a payday
          loan is right for you, and who you want your lender to be. If you are
          dealing with unexpected expenses and need financial help, it’s also
          important to{" "}
          <Link to="/education">understand your finance options</Link> and make
          a game plan. It’s always a good idea to consider whether a loan is a
          good product for you, and online payday loans are no different. Here
          are a few reasons why getting a payday loan can be the smart thing to
          do:
        </div>
        <MainSaskatoonReasons />
      </div>
      <div>
        <h2 className="location-subtitle">
          Why choose a GoDay payday loan in Saskatoon
        </h2>
        <div className="location-text">
          <p>
            Over 250,000 people call Saskatoon home, and for years we’ve been
            helping our customers in Saskatchewan’s most populated city utilize
            online payday loans to help them cover costs and make wise financial
            decisions. We are a reputable, licensed online payday loan provider
            that cares about what our clients think, so we always put our
            customers first. Unlike unlicensed providers of online payday loans,
            we make sure you get the best rates possible on a payday loan in
            Saskatoon and there won’t be any surprises along the way. These are
            just some of the reasons{" "}
            <Link to="/why-us">why you should choose GoDay</Link> as your payday
            lender.
          </p>
          <p>
            With a <Link to="/">GoDay payday loan</Link>, what you see is what
            you get. Our easy to use fee adjuster can show you exactly how much
            online payday loans will cost you. We are transparent with customers
            from the beginning, taking the time to ensure you understand what
            the responsibilities are with online payday loans, and how they can
            be beneficial to you and your family. We are here to help you get
            your payday loan money quickly, privately and without worry. Taking
            control of your financial problems is just a click away!
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why GoDay offers the fastest online payday loans in Saskatoon
        </h2>
        <div className="location-details location-text">
          <MainSaskatoonInitialContent />
          <MainSaskatoonSeeMore />
          <MainSaskatoonMoreContent />
          <MainSaskatoonApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainSaskatoon;
