import { Helmet } from "react-helmet";
import pages from "configurations/pages.json";

const DocumentHead = (props: any) => {
  const page = (pages as any)[props.id];
  return (
    <Helmet>
      <title>{page.title}</title>
      <meta name="keywords" content={page.keywords} />
      <meta name="description" content={page.description} />
      <link rel="canonical" href={`${window.location.origin}${page.url}`} />
    </Helmet>
  );
};

export default DocumentHead;
