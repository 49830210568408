import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainCalgary = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainCalgaryReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainCalgaryReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            Looking to get an online payday loan in Calgary? Choose GoDay. We
            keep it simple. GoDay provides fast cash when you need it most with
            the security and professionalism along with it. Your privacy and
            security are our top priority which is we use the most advanced
            tools and only use your personal information with your
            authorization. You can also count on us to make sure your
            information is never shared.
          </div>
        </div>
      ) : null;

    const MainCalgaryReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            In Calgary and across Canada, people have been turning to GoDay when
            they need us the most. We have an A+ accreditation rating from the
            Better Business Bureau and also have{" "}
            <a
              href="https://ca.trustpilot.com/review/goday.ca?utm_medium=trustbox&amp;utm_source=Carousel"
              target="_blank"
              rel="noopener noreferrer"
            >
              hundreds of reviews
            </a>{" "}
            on TrustPilot from our customers just like you. See what they have
            to say about us today.
          </div>
        </div>
      ) : null;

    const MainCalgaryReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            At GoDay, we want to make sure you never have to have the hassle of
            walking into a store in Calgary for a payday loan again. That’s why
            GoDay is 100% online and you can apply for a payday loan in Calgary
            or from virtually anywhere. The best part is with our automated
            system, you will have an instant decision about your payday loan.
            Why wait to get the cash you need? Visit our website and start an{" "}
            <a href="/application">online payday loan application</a> today.
          </div>
        </div>
      ) : null;

    const MainCalgaryReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            Have questions for us? GoDay may be virtual, but if you call us you
            will be speaking with one of our amazing our customer service team
            members. They will be able to help answer any of the questions you
            may have when it comes to getting an online payday loan with us.{" "}
            <Link to="/contact">Contact us</Link> today to see how to can help.
          </div>
        </div>
      ) : null;

    const MainCalgaryReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            Since 2012, GoDay has been offering speedy, smart and secure online
            payday loans to people like you. So, whether you are located in
            Calgary or anywhere in the province of Alberta, choose GoDay for
            your next online payday loan.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainCalgaryReason1 />
        <MainCalgaryReason2 />
        <MainCalgaryReason3 />
        <MainCalgaryReason4 />
        <MainCalgaryReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainCalgaryOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainCalgaryOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainCalgaryOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainCalgaryOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainCalgaryOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainCalgaryOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Alberta</small>
      </h3>
    </section>
  );

  const MainCalgarySeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainCalgaryInitialContent = () => (
    <section>
      <p>
        A payday loan with GoDay is speedy, smart and secure. And it has never
        been easier to get started with our online application process. Our
        online application tool is simple to use and is the first step to
        getting your loan. We will need to get to know you first. So be ready to
        provide us with some of your personal information. Details like your
        banking, employment and payroll information are a few of the things we
        need to get you started. Once you have submitted the most up to date and
        correct information, our automated system will give you an immediate
        decision to let you know if you have been approved.
      </p>
      <p>
        What happens next? Your application will be reviewed by our automated
        system to determine how much we can approve your loan for. When your
        loan application has been submitted, our customer service team will get
        in touch with you to get your GoDay account set up and go over your
        funding options. At that time, we will also let you know how we will be
        getting you the cash you need. Our online payday loans are regulated by
        the province of Alberta and we’re able to provide our Calgary customers
        with loans ranging from $100 to $1,500 in value.
      </p>
    </section>
  );

  const MainCalgaryMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          After reviewing your loan, you will sign your payday loan documents
          and then you leave the rest to us. Once your online payday loan has
          been approved, we use the industry’s fastest tools to get your loan as
          soon as possible. Based on the business hours of your financial
          institution and when we receive your signed payday loan agreement,
          this will determine when you receive your payday loan. And at GoDay we
          work hard to make sure you get the cash you need within the same day
          or within the next business day. That’s right, within 24 hours you
          will see your payday loan in your account. A GoDay representative will
          be in touch along the way and be able to provide you with all the
          necessary information and assist with any questions you may have about
          your loan.
        </p>
        <p>
          From coast to coast, Canadians like you choose GoDay for their online
          payday loans. Want to know what our customers have to say about us?
          Take a look at our{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            TrustPilot reviews
          </a>{" "}
          to see why our customers choose GoDay.
        </p>
      </section>
    );
  };

  const MainCalgaryApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Calgary
      </h1>
      <div className="location-text">
        <p>
          Here at GoDay, we know that payday can’t come soon enough. Especially
          when it feels like all your bills are due at once and it feels like
          you have more money going out then coming in. That’s why GoDay is here
          to help. GoDay has been helping Canadians get to their next paycheque
          since 2012 by offering online payday loans in Calgary and from coast
          to coast. People trust GoDay to get them the cash they need quickly
          and easily. Learn more about how our instant payday loans are
          providing Calgary residents financial relief today.
        </p>
        <p>
          Payday loan or short-term loan, no matter what you like to call it, we
          understand that you have options when it comes to your finances. And
          when it comes to looking for a payday or short-term loan in Calgary,
          look no further GoDay. There are a lot of great things about GoDay –
          how easy our application process is, how quickly we get you the cash
          you need, our amazingly responsive customer service team, and more!
          But one of the best things about GoDay is never having to walk into a
          physical location for a payday loan again. With GoDay you can apply
          for an online payday loan with us from virtually anywhere! Our
          application tool is available 24 hours a day, seven days a week. Get
          an online payday loan with us from the comfort and privacy of your own
          home or while you are on the go with your favourite device.
        </p>
        <p>
          Whether you are looking for fast cash, to get yourself out of a
          financial bind, or to help keep yourself afloat until your next
          paycheck, GoDay offers online payday loans in Calgary to help you get
          back on track.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Calgary trusts GoDay for online payday loans
        </h2>
        <MainCalgaryReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So, what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Calgary and the
            province of Alberta. We make it simple for you to access a payday
            loan by offering:
          </div>
          <MainCalgaryOffer1 />
          <MainCalgaryOffer2 />
          <MainCalgaryOffer3 />
          <MainCalgaryOffer4 />
          <MainCalgaryOffer5 />
          <MainCalgaryOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why you should choose GoDay for a payday loan in Calgary
        </h2>
        <div className="location-text">
          <p>
            Living in Albert’s biggest city, Calgary has grown into one of the
            places to be. As one of the top contenders as one of the most
            livable cities in North America, GoDay has been there to help
            Calgary residents with their financial needs. Don’t worry about
            making time to have to go get an instant payday loan ever again. In
            Calgary and throughout Alberta, GoDay is here to help you get back
            on track.
          </p>
          <p>
            Known for its cold winters and events like the Calgary Stampede,
            Calgary continues to grow as one of Canada’s largest cities. Whether
            you are headed out for a ski day at Sunshine Village or headed to a
            Calgary Flames game, GoDay’s online payday loans are here for you so
            you can enjoy all the things Calgary has to offer.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Getting an online payday loan in Calgary has never been easier
        </h2>
        <div className="location-details location-text">
          <MainCalgaryInitialContent />
          <MainCalgarySeeMore />
          <MainCalgaryMoreContent />
          <MainCalgaryApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainCalgary;
