import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up(1024)]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      paddingLeft: theme.spacing(20),
      paddingRight: theme.spacing(20),
    },
  },
  title: {
    paddingBottom: theme.spacing(2),
    fontSize: "26pt",
    [theme.breakpoints.up(1024)]: {
      paddingBottom: theme.spacing(4),
      fontSize: "33pt",
    },
    textAlign: "center",
    color: theme.palette.text,
  },
  sectionTitle: {
    paddingBottom: theme.spacing(2),
    fontSize: "23pt",
    textTransform: "uppercase",
    color: theme.palette.text,
  },
  itemTitle: {
    fontSize: "16pt",
    fontWeight: "bold",
    color: theme.palette.text,
  },
  itemText: {
    paddingBottom: theme.spacing(2),
    fontSize: "13pt",
    color: theme.palette.text,
  },
}));

const MainTerms = () => {
  const classes = useStyles();

  const MainTermsItem1 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>1. Overview</div>
      </header>
      <div className={classes.itemText}>
        <p>
          YOUR USE OF THIS WEBSITE IS EXPRESSLY CONDITIONED UPON YOUR ACCEPTING
          AND AGREEING TO THESE TERMS OF USE.
        </p>
        <p>
          For USERS who are not registered with this Website, your use of the
          Website will be deemed to be acceptance of the Terms of Use, Section
          A.
        </p>
        <p>
          For USERS who are registered with the Website, in which registration
          is defined in Section B, your use of the Website shall be subject to
          (i) certain designated terms (see Section B below) in addition to
          those terms applicable to all USERS (Section A) and (ii) shall be
          further conditioned on your [clicking the “I AGREE TO THE TERMS OF
          USE” button at the end of these Terms of Use].
        </p>
        <p>
          IF THESE TERMS OF USE ARE NOT COMPLETELY ACCEPTABLE TO YOU, YOU MUST
          IMMEDIATELY TERMINATE YOUR USE OF THIS WEBSITE.
        </p>
      </div>
    </section>
  );

  const MainTermsItem2 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>2. Changes to Terms</div>
      </header>
      <div className={classes.itemText}>
        <p>
          <strong>GoDay</strong> may, at any time, for any reason and without
          notice, make changes to (i) this Website, including its look, feel,
          format, and content, as well as (ii) the products and/or services as
          described in this Website. Any modifications will take effect when
          posted to the Website. Therefore, each time you access the Website,
          you need to review the Terms of Use upon which access and use of this
          Website is conditioned. By your continuing use of the Website after
          changes are posted, you will be deemed to have accepted such changes.
          By accepting the Terms of Use, you are also accepting and potential
          changes.
        </p>
      </div>
    </section>
  );

  const MainTermsItem3 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>3. Jurisdiction</div>
      </header>
      <div className={classes.itemText}>
        <p>
          The Website is directed to those individuals and entities located in
          Canada. It is not directed to any person or entity in any jurisdiction
          where (by reason of nationality, residence, citizenship or otherwise)
          the publication or availability of the Website and its content,
          including its products and services, are unavailable or otherwise
          contrary to local laws or regulations. If this applies to you, you are
          not authorised to access or use any of the information on this
          Website. <strong>GoDay</strong> makes no representation that the
          information, opinions, advice or other content on the Website
          (collectively, “Content”) is appropriate or that its products and
          services are available outside Canada. Those who choose to access this
          Website from other locations do so at their own risk and are
          responsible for compliance with applicable local laws.
        </p>
      </div>
    </section>
  );

  const MainTermsItem4 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>4. Scope of Use and User E-Mail</div>
      </header>
      <div className={classes.itemText}>
        <p>
          You are only authorised to view, use, copy for your records and
          download small portions of the Content (including without limitation
          text, graphics, software, audio and video files and photos) of this
          Website for your personal informational and non-commercial use,
          provided that you leave all the copyright notices, including copyright
          management information, or other proprietary notices intact.
        </p>
        <p>
          You may not store, modify, reproduce, transmit, reverse engineer or
          distribute a significant portion of the Content on this Website, or
          the design or layout of the Website or individual sections of it, in
          any form or media. The systematic retrieval of data from the Website
          is also prohibited.
        </p>
        <p>
          The commercial use, reproduction, transmission or distribution of any
          information, software or other material available through the Website
          without the prior written consent of <strong>GoDay</strong> is
          strictly prohibited.
        </p>
        <p>
          E-mail submissions over the Internet may not be secure and are subject
          to the risk of interception by third parties. Please consider this
          fact before e-mailing any information. Also, please consult our{" "}
          <Link to="/privacy">Privacy Policy</Link>. You agree not to submit or
          transmit any e-mails or materials through the Website that: (i) are
          defamatory, threatening, obscene or harassing, (ii) contain a virus,
          worm, Trojan horse or any other harmful component, (iii) incorporate
          copyrighted or other proprietary material of any third party without
          that party’s permission or (iv) otherwise violate any applicable laws.{" "}
          <strong>GoDay</strong> shall not be subject to any obligations of
          confidentiality regarding any information or materials that you submit
          online except as specified in these Terms of Use, or as set forth in
          any additional terms and conditions relating to specific products or
          services, or as otherwise specifically agreed or required by law.
        </p>
      </div>
    </section>
  );

  const MainTermsItem5 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>5. Copyrights and Trademarks</div>
      </header>
      <div className={classes.itemText}>
        <p>
          The materials at this Site, including the organization and layout of
          this site, are copyrighted and are protected by Canadian and
          International Copyright Laws and Treaty Provisions. You may access,
          download and print materials on this Website solely for your personal
          and non-commercial use; however, any print out of this Site, or
          portions of the Site, must include <strong>GoDay’s</strong> copyright
          notice. No right, title or interest in any of the materials contained
          on this Site is transferred to you as a result of accessing,
          downloading or printing such materials. You may not copy, modify,
          distribute, transmit, display, reproduce, publish, license any part of
          this Site; create derivative works from, link to or frame in another
          website, use on any other website, transfer or sell any information
          obtained from this Site without the prior written permission of{" "}
          <strong>GoDay</strong>. Except as expressly provided under the “Scope
          of Use” Section above, you may not use, reproduce, modify, transmit,
          distribute, or publicly display or operate this Website without the
          prior written permission of <strong>GoDay</strong>. You may not use a
          part of this Website on any other Website, without{" "}
          <strong>GoDay’s</strong> prior written consent. <strong>GoDay</strong>{" "}
          respects the intellectual property rights of others and expects our
          USERS to do the same. <strong>GoDay</strong> will terminate the
          accounts of repeat copyright offenders and other USERS who infringe
          upon the intellectual property rights of others. If you believe that
          your work has been copied in a way that constitutes copyright
          infringement, please contact us at{" "}
          <a href="mailto:customerservice@goday.ca">customerservice@goday.ca</a>
          .
        </p>
      </div>
    </section>
  );

  const MainTermsItem6 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>6. Links</div>
      </header>
      <div className={classes.itemText}>
        <p>
          For your convenience, we may provide links to various other Websites
          that may be of interest to you and for your convenience only. However,{" "}
          <strong>GoDay</strong> does not control or endorse such Websites and
          is not responsible for their content nor is it responsible for the
          accuracy or reliability of any information, data, opinions, advice, or
          statements contained within such Websites. Please read the terms and
          conditions or terms of use policies of any other company or website
          you may link to from our website. This Terms of Use policy applies
          only to <strong>GoDay’s</strong> website and the products and services{" "}
          <strong>GoDay</strong> that offers. If you decide to access any of the
          third party sites linked to this Website, you do so at your own risk.{" "}
          <strong>GoDay</strong> reserves the right to terminate any link or
          linking program at any time. <strong>GoDay</strong> disclaims all
          warranties, express and implied, as to the accuracy, validity, and
          legality or otherwise of any materials or information contained on
          such sites.
        </p>
        <p>
          You may not link to this Website without <strong>GoDay’s</strong>{" "}
          written permission. If you are interested in linking to this Website,
          please contact{" "}
          <a href="mailto:customerservice@goday.ca">customerservice@goday.ca</a>
          .
        </p>
      </div>
    </section>
  );

  const MainTermsItem7 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>
          7. No Unlawful Purpose or Prohibited Use
        </div>
      </header>
      <div className={classes.itemText}>
        <p>
          As a condition of your use of the Website, you warrant to GoDay that
          you will not use the Website for any purpose that is unlawful or
          prohibited by these terms, conditions, and notices. You may not use
          the Website in any manner that could damage, disable, overburden, or
          impair the Site or interfere with any other party’s use and enjoyment
          of the Website. You may not obtain or attempt to obtain any materials
          or information through any means not intentionally made available or
          provided for through the Site.
        </p>
      </div>
    </section>
  );

  const MainTermsItem8 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>8. Spamming</div>
      </header>
      <div className={classes.itemText}>
        <p>
          Gathering email addresses from <strong>GoDay</strong> through
          harvesting or automated means is prohibited. Posting or transmitting
          unauthorised or unsolicited advertising, promotional materials, or any
          other forms of solicitation to other USERS is prohibited. Inquiries
          regarding a commercial relationship with <strong>GoDay</strong> should
          be directed to{" "}
          <a href="mailto:customerservice@goday.ca">customerservice@goday.ca</a>
          .
        </p>
      </div>
    </section>
  );

  const MainTermsItem9 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>9. No Warranties</div>
      </header>
      <div className={classes.itemText}>
        <p>
          THE WEBSITE, AND ANY CONTENT, ARE PROVIDED TO YOU ON AN “AS IS”, “AS
          AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND WHETHER EXPRESS,
          STATUTORY OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET
          ENJOYMENT, SYSTEMS INTEGRATION, ACCURACY, AND NON-INFRINGEMENT, ALL OF
          WHICH <strong>GODAY</strong> EXPRESSLY DISCLAIMS.{" "}
          <strong>GODAY</strong> DOES NOT ENDORSE AND MAKES NO WARRANTY AS TO
          THE ACCURACY, COMPLETENESS, CURRENCY, OR RELIABILITY OF THE CONTENT,
          AND <strong>GODAY</strong> WILL NOT BE LIABLE OR OTHERWISE RESPONSIBLE
          FOR ANY FAILURE OR DELAY IN UPDATING THE WEBSITE OR ANY CONTENT. WE
          HAVE NO DUTY TO UPDATE THE CONTENT OF THE WEBSITE.{" "}
          <strong>GODAY</strong> MAKES NO REPRESENTATIONS OR WARRANTIES THAT USE
          OF THE CONTENT WILL BE UNINTERRUPTED OR ERROR-FREE. YOU ARE
          RESPONSIBLE FOR ANY RESULTS OR OTHER CONSEQUENCES OF ACCESSING THE
          WEBSITE AND USING THE CONTENT, AND FOR TAKING ALL NECESSARY
          PRECAUTIONS TO ENSURE THAT ANY CONTENT YOU MAY ACCESS, DOWNLOAD OR
          OTHERWISE OBTAIN IS FREE OF VIRUSES OR ANY OTHER HARMFUL COMPONENTS.
          THIS WARRANTY DISCLAIMER MAY BE DIFFERENT IN CONNECTION WITH SPECIFIC
          PRODUCTS AND SERVICES OFFERED BY <strong>GODAY</strong>.
        </p>
      </div>
    </section>
  );

  const MainTermsItem10 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>
          10. Governing Law, Location and Miscellaneous
        </div>
      </header>
      <div className={classes.itemText}>
        <p>
          These Terms of Use shall be governed in all respects by the laws of
          the Province of Ontario Canada, without reference to its choice of law
          rules. If an applicable law is in conflict with any part of the Terms
          of Use, the Terms of Use will be deemed modified to conform to the
          law. The other provisions will not be affected by any such
          modification.
        </p>
      </div>
    </section>
  );

  const MainTermsItem11 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>11. Separate Agreements</div>
      </header>
      <div className={classes.itemText}>
        <p>
          As a registered USER, you may have other agreements with{" "}
          <strong>GoDay</strong>. Those agreements are separate and distinct and
          are in addition to these Terms of Use. These Terms of Use do not
          modify, revise or amend the terms of any other agreements you may have
          with <strong>GoDay</strong>.
        </p>
      </div>
    </section>
  );

  const MainTermsItem12 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>12. Canadian Resident</div>
      </header>
      <div className={classes.itemText}>
        <p>You represent that you are a resident of Canada.</p>
      </div>
    </section>
  );

  const MainTermsItem13 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>13. No Professional Advice</div>
      </header>
      <div className={classes.itemText}>
        <p>
          The information available on the Website is intended to be a general
          information resource regarding the matters covered, and is not
          tailored to your specific circumstance. You should not construe this
          as legal, accounting or other professional advice. This Website is not
          intended for use by minors. YOU SHOULD EVALUATE ALL INFORMATION,
          OPINIONS AND ADVICE AVAILABLE ON THIS WEBSITE IN CONSULTATION WITH
          YOUR INSURANCE SPECIALIST, OR WITH YOUR LEGAL, TAX, FINANCIAL OR OTHER
          ADVISOR, AS APPROPRIATE.
        </p>
      </div>
    </section>
  );

  const MainTermsItem14 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>14. USERS Disputes</div>
      </header>
      <div className={classes.itemText}>
        <p>
          You are solely responsible for your interactions with other USERS.{" "}
          <strong>GoDay</strong> reserves the right, but has no obligation, to
          monitor disputes between you and other USERS.
        </p>
      </div>
    </section>
  );

  const MainTermsItem15 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>
          15. User Submissions And Communications; Public Areas:
        </div>
      </header>
      <div className={classes.itemText}>
        <p>
          You acknowledge that you are solely responsible or otherwise control
          all of the rights to the content that you post; that the content is
          accurate; that use of the content you supply does not violate these
          Terms of Use and will not cause injury to any person or entity; and
          that you will indemnify <strong>GoDay</strong> or its affiliates for
          all claims resulting from content you supply.
        </p>
        <p>
          If you make any submission to an area of the Website accessed or
          accessible by the public (“Public Area”) or if you submit any business
          information, idea, concept or invention to <strong>GoDay</strong> by
          email, you automatically represent and warrant that the owner of such
          content or intellectual property has expressly granted{" "}
          <strong>GoDay</strong> a royalty-free, perpetual, irrevocable,
          world-wide nonexclusive license to use, reproduce, create derivative
          works from, modify, publish, edit, translate, distribute, perform, and
          display the communication or content in any media or medium, or any
          form, format, or forum now known or hereafter developed.{" "}
          <strong>GoDay</strong> may sublicense its rights through multiple
          tiers of sublicenses. If you wish to keep any business information,
          ideas, concepts or inventions private or proprietary, you must not
          submit them to the Public Areas or to <strong>GoDay</strong> by email.
          We try to answer every email in a timely manner, but are not always
          able to do so.
        </p>
        <p>
          Some of the forums (individual bulletin boards and posts on the social
          network, for instance) on the Website are not moderated or reviewed.
          Accordingly, USERS will be held directly and solely responsible for
          the content of messages that are posted. While not moderating the
          forums, the Site reviewer will periodically perform an administrative
          review for the purpose of deleting messages that are old, have
          received few responses, are off topic or irrelevant, serve as
          advertisements or seem otherwise inappropriate. <strong>GoDay</strong>{" "}
          has full discretion to delete messages. USERS are encouraged to read
          the specific forum rules displayed in each discussion forum first
          before participating in that forum.
        </p>
        <p>
          <strong>GoDay</strong> reserves the right (but is not obligated) to do
          any or all of the following:
        </p>
        <p>
          (a) Record the dialogue in public chat rooms.
          <br />
          (b) Examine an allegation that a communication(s) do(es) not conform
          to the terms of this section and determine in its sole discretion to
          remove or request the removal of the communication(s).
          <br />
          (c) Remove communications that are abusive, illegal, or disruptive, or
          that otherwise fail to conform with these Terms of Use.
          <br />
          (d) Terminate a Member’s access to any or all Public Areas and/or the
          GoDay Site upon any breach of these Terms of Use.
          <br />
          (e) Monitor, edit, or disclose any communication in the Public Areas.
          <br />
          (f) Edit or delete any communication(s) posted on the GoDay Site,
          regardless of whether such communication(s) violate these standards.
        </p>
        <p>
          <strong>GoDay</strong> reserves the right to take any action it deems
          necessary to protect the personal safety of our guests or the public.{" "}
          <strong>GoDay</strong> has no liability or responsibility to USERS of
          the <strong>GoDay</strong> Website or any other person or entity for
          performance or non-performance of the aforementioned activities.
        </p>
      </div>
    </section>
  );

  const MainTermsItem16 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>16. Arbitration</div>
      </header>
      <div className={classes.itemText}>
        <p>
          Except as regarding any action seeking equitable relief, including
          without limitation for the purpose of protecting any GoDay
          confidential information and/or intellectual property rights, any
          controversy or claim arising out of or relating to these Terms of Use
          or this Website shall be settled by binding arbitration in accordance
          with the provisions, in effect at the time the proceedings begin, of
          the Commercial Arbitration Act. Any such controversy or claim shall be
          arbitrated on an individual basis, and shall not be consolidated in
          any arbitration with any claim or controversy of any other party. The
          arbitration shall be held in the province of Ontario.
        </p>
        <p>
          All information relating to or disclosed by any party in connection
          with the arbitration of any disputes hereunder shall be treated by the
          parties, their representatives, and the arbitrator as proprietary
          business information. Such information shall not be disclosed by any
          party or their respective representatives without the prior written
          authorisation of the party furnishing such information. Such
          information shall not be disclosed by the arbitrator without the prior
          written authorisation of all parties. Each party shall bear the burden
          of its own counsel fees incurred in connection with any arbitration
          proceedings.
        </p>
        <p>
          Judgment upon the award returned by the arbitrator may be entered in
          any court having jurisdiction over the parties or their assets or
          application of enforcement, as the case may be. Any award by the
          arbitrator shall be the sole and exclusive remedy of the parties. The
          parties hereby waive all rights to judicial review of the arbitrator’s
          decision and any award contained therein.
        </p>
      </div>
    </section>
  );

  const MainTermsItem17 = () => (
    <section>
      <header>
        <div className={classes.itemTitle}>17. Limitation of Liability</div>
      </header>
      <div className={classes.itemText}>
        <p>
          YOUR USE OF THE CONTENT IS AT YOUR OWN RISK. <strong>GODAY</strong>{" "}
          SPECIFICALLY DISCLAIMS ANY LIABILITY, WHETHER BASED IN CONTRACT, TORT,
          NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, FOR ANY DIRECT, INDIRECT,
          INCIDENTAL, PUNITIVE, CONSEQUENTIAL, OR SPECIAL DAMAGES ARISING OUT OF
          OR IN ANY WAY CONNECTED WITH ACCESS TO, USE OF OR RELIANCE ON THE
          CONTENT (EVEN IF <strong>GODAY</strong> HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES) OR THAT ARISE IN CONNECTION WITH MISTAKES
          OR OMISSIONS IN, OR DELAYS IN TRANSMISSION OF, INFORMATION TO OR FROM
          THE USER, ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION,
          DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION OR DELIVERY,
          COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR
          UNAUTHORISED ACCESS TO, ALTERATION OF, OR USE OF RECORDS, PROGRAMS OR
          FILES, INTERRUPTIONS IN TELECOMMUNICATIONS CONNECTIONS TO THE WEBSITE
          OR VIRUSES, WHETHER CAUSED IN WHOLE OR IN PART BY NEGLIGENCE, ACTS OF
          GOD, TELECOMMUNICATIONS FAILURE, THEFT OR DESTRUCTION OF, OR
          UNAUTHORISED ACCESS TO THE WEBSITE OR THE CONTENT. THIS LIMITATION OF
          LIABILITY MAY BE DIFFERENT IN CONNECTION WITH SPECIFIC PRODUCTS AND
          SERVICES OFFERED BY <strong>GODAY</strong>. SOME JURISDICTIONS DO NOT
          ALLOW THE LIMITATION OF LIABILITY, SO THIS LIMITATION MAY NOT APPLY TO
          YOU.
        </p>
      </div>
    </section>
  );

  const MainTermsItem18 = () => (
    <section>
      <header>
        <h3 className={classes.itemTitle}>18. Indemnity</h3>
      </header>
      <div className={classes.itemText}>
        <p>
          You agree to defend, indemnify, and hold <strong>GoDay</strong>, its
          officers, directors, employees, agents, licensors, and suppliers,
          harmless from and against any claims, actions or demands, liabilities
          and settlements including without limitation, reasonable legal and
          accounting fees, resulting from, or alleged to result from, your
          violation of these Terms of Use.
        </p>
      </div>
    </section>
  );

  const MainTermsItem19 = () => (
    <section>
      <header>
        <h3 className={classes.itemTitle}>19. Accounts And Security</h3>
      </header>
      <div className={classes.itemText}>
        <p>
          <strong>GoDay</strong> does not warrant that the functions contained
          in the service provided by the Website will be uninterrupted or
          error-free, that defects will be corrected or that this service or the
          server that makes it available will be free of viruses or other
          harmful components.
        </p>
        <p>
          As part of the registration process, each user will select an email to
          use as a Login Name (“Login Name”) and use their SIN number as their
          password (“Password”). You shall provide GoDay with accurate,
          complete, and updated account information. Failure to do so shall
          constitute a breach of this Terms of Use, which may result in
          immediate termination of your account.
        </p>
        <p>You may not:</p>
        <p>
          (a) select or use a Login Name of another person with the intent to
          impersonate that person;
          <br />
          (b) use a name subject to the rights of any other person without
          authorization;
          <br />
          (c) use a Login Name that www.goday.ca, in its sole discretion, deems
          inappropriate or offensive.
        </p>
        <p>
          You shall notify <strong>GoDay</strong> of any known or suspected
          unauthorized use(s) of your Account, or any known or suspected breach
          of security, including loss, theft, or unauthorised disclosure of your
          password. You shall be responsible for maintaining the confidentiality
          of your password.
        </p>
        <p>
          Any fraudulent, abusive, or otherwise illegal activity may be grounds
          for termination of your Account, at <strong>GoDay’s</strong> sole
          discretion, and you may be reported to appropriate law-enforcement
          agencies.
        </p>
        <p>
          If you would like to request additional information regarding these
          Terms of Use, please contact us at{" "}
          <a href="mailto:customerservice@goday.ca">customerservice@goday.ca</a>
          .
        </p>
      </div>
    </section>
  );

  const MainTermsSection1 = () => (
    <>
      <header>
        <h2 className={classes.sectionTitle}>
          Section A. Terms Applicable to All Users
        </h2>
      </header>
      <MainTermsItem1 />
      <MainTermsItem2 />
      <MainTermsItem3 />
      <MainTermsItem4 />
      <MainTermsItem5 />
      <MainTermsItem6 />
      <MainTermsItem7 />
      <MainTermsItem8 />
      <MainTermsItem9 />
      <MainTermsItem10 />
      <MainTermsItem11 />
      <MainTermsItem12 />
      <MainTermsItem13 />
      <MainTermsItem14 />
      <MainTermsItem15 />
      <MainTermsItem16 />
      <MainTermsItem17 />
      <MainTermsItem18 />
    </>
  );

  const MainTermsSection2 = () => (
    <>
      <header>
        <h2 className={classes.sectionTitle}>
          Section B. Additional Terms Applicable Only to Registered Users
        </h2>
      </header>
      <div className={classes.itemText}>
        <p>
          Registered USERS are defined as individuals with a{" "}
          <strong>GoDay</strong> account for the purpose of receiving a loan.
        </p>
      </div>
      <MainTermsItem19 />
    </>
  );

  return (
    <main className={classes.container}>
      <header>
        <h1 className={classes.title}>GoDay Terms of Use</h1>
      </header>
      <div className={classes.itemText}>
        <p>Please Read Carefully Before Using This Website:</p>
        <p>
          <strong>GoDay Inc (“GoDay”)</strong> maintains this site for
          information and communication purposes. This webpage contains the
          Terms of Use governing your access to and use of www.goday.ca (the
          “Website” or “Site”). If you do not accept these Terms of Use or you
          (the “USER”) do not meet or comply with their provisions, you may not
          use the Website.
        </p>
      </div>
      <MainTermsSection1 />
      <MainTermsSection2 />
    </main>
  );
};

export default MainTerms;
