import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainRegina = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 4));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainReginaReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 4 ? "enabled" : "disabled";

    const MainReginaReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay implements bank level encryption to make sure that all of your
            information is secure and guarded against unauthorized use. We will
            never share any of your online payday loan application information
            (whether it’s with banks, employers, or other online payday loan
            operators) without your express consent.
          </div>
        </div>
      ) : null;

    const MainReginaReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay the most reputable source of online payday loans in Regina,
            Saskatchewan, and throughout Canada. If you don’t believe it just
            take a look at our TrustPilot customer ratings, our A+ rating by the
            Better Business Bureau, or take a look at our Saskatchewan Payday
            Loan License before applying for a payday loan online with us.
          </div>
        </div>
      ) : null;

    const MainReginaReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            Our payday loan process is entirely online. You will never have to
            worry again about going to a storefront in Regina for a payday loan.
            The average online <a href="/application">loan application</a> takes
            just under 5 minutes to get through, less than the line up at Tim
            Hortons during rush hour. Better yet GoDay is able to approve you
            for an online payday loan in just a few seconds, allowing you to get
            your online payday loan and get back to your life.
          </div>
        </div>
      ) : null;

    const MainReginaReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            We’re from Canada! GoDay.ca loves Regina and we want to make sure
            it’s simple and easy to apply for and receive an online payday loan.
            When you call our representatives they’re returning your call from
            Canada and genuinely care about your situation. GoDay.ca’s payday
            loans online in Regina are simple and easy. You don’t have to drive
            or use a fax machine, the only requirement is that you have an
            internet connection to get to the website! Get an online payday loan
            from GoDay.ca today!
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/4</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainReginaReason1 />
        <MainReginaReason2 />
        <MainReginaReason3 />
        <MainReginaReason4 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainReginaOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick and immediate decision</strong>
        <br />
        <small>regarding your online payday loan application</small>
      </h3>
    </section>
  );

  const MainReginaOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Bank level security</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainReginaOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>No hidden fees</strong>
        <br />
        <small>so you know what your payday loan costs</small>
      </h3>
    </section>
  );

  const MainReginaOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick money</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainReginaOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>No faxing or driving to a storefront required</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainReginaOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>We are licensed in Saskatchewan</strong>
        <br />
        <small>to issue online payday loans in Saskatchewan</small>
      </h3>
    </section>
  );

  const MainReginaSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainReginaInitialContent = () => (
    <section>
      <p>
        Whether it’s the rising cost of rent or the need to fix a broken water
        heater we understand that it can be expensive to get by in Saskatchewan.
        That’s why we offer you simple and easy online payday loans to help you
        get through tough stretches. We want you to be able to stop worrying
        about having the funds you need and get back to doing the things you
        love.
      </p>
      <p>
        Getting one of GoDay’s payday loans online is super simple - just visit
        our website and complete a short application form. The whole thing
        should take less than 5 minutes. Make sure that you put in the correct
        information into the online payday loan application form since otherwise
        we may not be able to move forward. Just to be sure, always take an
        extra 30 seconds to look over the information on your online payday loan
        application before pressing “Submit”. Failing to do this might make the
        process take significantly longer.
      </p>
    </section>
  );

  const MainReginaMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          After your online payday loan application has submitted and been
          received by our Toronto office we’ll reach out to you by email or give
          you a call to go over your online payday loan application with you.
          Then the automated GoDay online payday loan system will look over your
          online payday loan application and confirm how much we can approve you
          for. In Regina, online payday loans are regulated by the province of
          Saskatchewan, and we have a license to prove we’re a bona-fide
          Saskatchewan payday lender. Always check (or ask) whether your payday
          loan provider has a license. As as result of being regulated our
          payday loans range from $100 to $1,500 and is due on your next pay
          date with no extensions.
        </p>
        <p>
          After you’ve had an opportunity to review and sign your online payday
          loan agreement we will use the fastest tools in Canada to ensure that
          your online payday loan is processed. We want to make sure that you
          get your payday loan and get back to your life as quickly as possible.
          Although it depends on the time of day and the banks, we work as hard
          as possible to get you your online payday loan the same day. In some
          cases it may take the banks slightly longer to process our payment and
          you will receive it the next morning instead. A GoDay customer service
          agent can give you more information on when you’re most likely to
          receive the funds after your online payday loan has been approved by
          our system.
        </p>
        <p>
          No matter where you are in Saskatchewan, whether it’s Regina,
          Saskatoon or otherwise, GoDay.ca can get you a Regina payday loan, a
          Saskatoon payday loan and more faster than anyone else! Join the
          thousands of Canadians from that use and trust GoDay.ca for any and
          all online payday loans. Whether you’re filling out a Regina payday
          loan application on the train, in the passenger seat, or at home don’t
          let the cost of Regina or unexpected expenses prevent you from doing
          what you want to do.
        </p>
        <p>
          Now, we don’t expect you to take our word for how great we are. There
          are plenty of others talking about how great our online payday loan
          service is. Here are just a few of the great comments we’ve gotten
          from some of our Regina payday loan customers via TrustPilot - a third
          party review website. And make sure to take a look at{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            GoDay.ca TrustPilot Reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainReginaApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Regina
      </h1>
      <div className="location-text">
        <p>
          Unexpected expenses always tend to pop up at the worst possible times.
          Everyone’s had to go through the pain of dealing with a busted water
          heater, a broken down car, or an unwanted visit to the dentist.
          Fortunately, when these sorts of issues arise and you’re having
          trouble making ends meet payday loans online are available for you in
          Regina through GoDay.ca, Saskatchewan’s number one online{" "}
          <Link to="/">payday loan</Link> provider. GoDay.ca’s online payday
          loan application takes no time to complete and once you’ve completed
          your online payday loan application you’ll get your payday loan (up to
          $1,500) placed right into your account in less than 24 hours.
        </p>
        <p>
          A simple and secure <Link to="/blog/">online payday loan</Link> from
          GoDay.ca (one of the fastest online payday loan providers in Canada)
          is a good way to get through short term cash flow hiccups. An online
          payday loan is meant to help you manage unexpected expenses and get by
          until your next payday. We use the fastest tools in the industry to
          review your online payday loan application, build your GoDay online
          payday loan account, and make sure you have everything you need until
          your payday loan is in your bank account! Issuing Regina payday loans
          online since 2012, GoDay.ca is grateful for the chance to offer online
          payday loans to people across Saskatchewan. If you need cash, get a
          speedy smart and secure payday loan today.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why Regina uses GoDay.ca for quick online payday loans
        </h2>
        <MainReginaReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              Apply for a payday loan online today in Regina! We make it easy.
            </strong>
          </div>
          <div className="location-offers-text">
            Not only are we licensed provincially to issue payday loans, we’re
            one of the best in the business. Here are just some of the ways we
            make it simple for you to get an online payday loan:
          </div>
          <MainReginaOffer1 />
          <MainReginaOffer2 />
          <MainReginaOffer3 />
          <MainReginaOffer4 />
          <MainReginaOffer5 />
          <MainReginaOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          We get it, living in Saskatchewan can get expensive.
        </h2>
        <div className="location-details location-text">
          <MainReginaInitialContent />
          <MainReginaSeeMore />
          <MainReginaMoreContent />
          <MainReginaApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainRegina;
