import { Link } from "react-router-dom";
import "./sitemap.css";

const Sitemap = () => {
  const SitemapLinks = () => (
    <section className="sitemap-links">
      <div className="sitemap-links-group">
        <h4 className="sitemap-links-title">About</h4>
        <div className="sitemap-links-item">
          <Link to="/why-us">Mission / Values</Link>
        </div>
        <div className="sitemap-links-item">
          <a href="/careers">Careers</a>
        </div>
        <div className="sitemap-links-item">
          <Link to="/contact">Contact</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/location-services">Location Services</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/faq">FAQ</Link>
        </div>
        <div className="sitemap-links-item">
          <a href="/feedback">Feedback</a>
        </div>
        <div className="sitemap-links-item">
          <Link to="/contact">Business Hours</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/terms">Terms &amp; Conditions</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/privacy">Privacy Policy</Link>
        </div>
      </div>
      <div className="sitemap-links-group">
        <h4 className="sitemap-links-title">Lending Process</h4>
        <div className="sitemap-links-item">
          <Link to="/installment-loans">Installment Loan</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/installment-loans">Personal Loan</Link>
        </div>
        <div className="sitemap-links-item">
          <a href="/application">Application</a>
        </div>
        <div className="sitemap-links-item">
          <Link to="/privacy">Privacy Policy</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/terms">Terms of Use</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/faq">How Do I Get Paid?</Link>
        </div>
        <div className="sitemap-links-item">
          <a href="/application">Payday Loan</a>
        </div>
        <div className="sitemap-links-item">
          <a href="/application">Short Term Loan</a>
        </div>
      </div>
      <div className="sitemap-links-group">
        <h4 className="sitemap-links-title">Financial Planning</h4>
        <div className="sitemap-links-item">
          <Link to="/education">Responsible Lending / Borrowing</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/education">Long Term Financial Planning</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/education">Paying off Loans</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/education">Budgeting</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/education">Credit Education</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/education">Debt Management</Link>
        </div>
      </div>
      <div className="sitemap-links-group">
        <h4 className="sitemap-links-title">Why Us?</h4>
        <div className="sitemap-links-item">
          <Link to="/why-us">Fast Cash</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/why-us">Professional Service</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/why-us">No Hidden Fees</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/why-us">Avoid NSF Fees</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/why-us">Online Loan</Link>
        </div>
        <div className="sitemap-links-item">
          <Link to="/why-us">Cash Flow Solution</Link>
        </div>
      </div>
    </section>
  );

  const SitemapSocial = () => (
    <section className="sitemap-social">
      <div className="sitemap-social-title">Follow us</div>
      <nav className="sitemap-social-icons">
        <ul className="gallery">
          <li>
            <a
              href="https://www.facebook.com/GoDay.ca"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className="sitemap-social-icon"
                id="sitemap-social-icon-facebook"
              ></span>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/godayca"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className="sitemap-social-icon"
                id="sitemap-social-icon-twitter"
              ></span>
            </a>
          </li>
          <li>
            <a
              href="https://plus.google.com/109801055148329104490"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className="sitemap-social-icon"
                id="sitemap-social-icon-google-plus"
              ></span>
            </a>
          </li>
          <li>
            <a
              href="https://www.pinterest.com/GodayLoans"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className="sitemap-social-icon"
                id="sitemap-social-icon-pinterest"
              ></span>
            </a>
          </li>
        </ul>
      </nav>
      <div className="sitemap-social-title">Call us Toll Free:</div>
      <div className="sitemap-social-phone">888-984-6329</div>
    </section>
  );

  return (
    <section className="sitemap content">
      <SitemapLinks />
      <SitemapSocial />
    </section>
  );
};

export default Sitemap;
