import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainVancouver = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainVancouverReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainVancouverReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay uses top level encryption to ensure your personal information
            is safe and never used without your authorization. We never share
            your online payday loan application information with any other
            online payday loan operators, in person payday lenders, or any other
            third parties in Vancouver or otherwise.
          </div>
        </div>
      ) : null;

    const MainVancouverReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is a trusted source of online payday loans in Vancouver,
            British Columbia, and throughout Canada. If you don’t believe us, be
            sure to check out customer reviews on TrustPilot, view our A+
            accreditation by the Better Business Bureau, or review our British
            Columbia Payday Loan License before getting a payday loan online
            with us.
          </div>
        </div>
      ) : null;

    const MainVancouverReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            Our process is 100% online. You’ll never have to walk into a retail
            store in Vancouver for a payday loan again. Our average online
            payday loan application takes less than 5 minutes to complete. Even
            better, we can approve you for an online payday loan in seconds so
            that you can get your payday loan quickly and get back to what
            matters.
          </div>
        </div>
      ) : null;

    const MainVancouverReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            Canada is our house! GoDay.ca loves British Columbia and Vancouver
            and makes it as easy as possible to get an online payday loan there.
            When you call our customer’s service line or speak with a friendly
            representative they’re answering your call from right here in Canada
            and care about you! Whether it’s discussing the latest game weekend
            plans, our team cares about you and are experts in issuing online
            payday loans in Vancouver that meet your needs. Get your payday loan
            online with GoDay today!
          </div>
        </div>
      ) : null;

    const MainVancouverReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            GoDay.ca online payday loans in Vancouver. No driving, no faxing, no
            face to face communication, no parking tickets, no traffic, and no
            waiting in line for an online payday loan from GoDay.ca.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainVancouverReason1 />
        <MainVancouverReason2 />
        <MainVancouverReason3 />
        <MainVancouverReason4 />
        <MainVancouverReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainVancouverOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 instant decision</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainVancouverOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Secure online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainVancouverOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Upfront fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainVancouverOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Fast cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainVancouverOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>No paperwork or faxing required</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainVancouverOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>We are licensed</strong>
        <br />
        <small>to issue online payday loans in British Columbia</small>
      </h3>
    </section>
  );

  const MainVancouverSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainVancouverInitialContent = () => (
    <section>
      <p>
        Congratulations, you live in Vancouver, one of the best cities in
        Canada! By the way, did we mention how expensive Vancouver is? Did we
        mention that a drink could cost $7, or $10, or $12? Also, all the fun
        things you wanted to do -- visit the VanDusen Botanical Gardens, or the
        Capilano Suspension Bridge, grab cocktails in Yaletown, get great food
        in Chinatown, -- it all costs a lot of money!
      </p>
      <p>
        That’s why we offer quick and easy online payday loans to help you deal
        with the price tag of doing the things you love. Of course, there are
        plenty of free things to do that don’t require an online payday loan.
        Check out some of our staff’s favorite Vancouver more budget friendly
        suggestions - after all, our team has been living and providing the best
        way to get a payday loan online in Vancouver since 2012.
      </p>
    </section>
  );

  const MainVancouverMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          Vancouver is full of free activities that don’t require an online
          payday loan, if you have a pair of good shoes on your feet, a backpack
          with snacks and some water, and area ready for an adventure. Here are
          some of our team’s favorite activities that don’t require a payday
          loan.
        </p>
        <p>
          In the summer, we love Stanley Park, perhaps the most beautiful (and
          free) park in any major city, and Vancouver’s free Museums are great
          all year round.
        </p>
        <p>
          So now that you have some free (or nearly free) activity ideas in
          Vancouver that don’t require an online payday loan, let’s discuss what
          to do if you need to consider a payday loan online in Vancouver.
          What’s the process and how does it work?
        </p>
        <p>
          Getting one of our payday loans online is quick and easy - all you
          have to do is go to our website and fill out a simple application. The
          whole process only takes a few minutes. Be sure that you accurately
          and correctly enter your personal, banking, employment, and payroll
          information into the online payday loan application form. Your
          application for a payday loan online may be declined if the
          information you provide is inaccurate and/or unable to be validated by
          our online payday loan system. We recommend that you take an extra 30
          seconds to make sure your online payday loan application information
          on your is correct before pressing “Submit”. If you fail to do this we
          may not be able to offer you a payday loan online.
        </p>
        <p>
          Once our central online payday loan office has received your online
          payday loan, we will immediately contact you by phone or email to
          finalize your application and discuss funding options. Our automated
          and online payday loan system will review your online payday loan
          application and let you know how much we can approve you for. For all
          Vancouver residents, payday loans are regulated by the province of
          British Columbia. A licensed BC payday lender our Vancouver online
          payday loans range from $100 to $1,500 and is due on your next
          scheduled pay date.
        </p>
        <p>
          Once you’ve had a chance to look over and sign your online payday loan
          documents, we will utilize the fastest tools in the industry to make
          sure your payday loan is delivered as quickly as possible. Depending
          on the time of day you sign your online payday loan agreement as well
          as your financial institution, your online payday loan will either
          arrive the same day, or overnight. A GoDay representative will be able
          to give you more information once your application for an online
          payday loan has been approved by our system.
        </p>
        <p>
          No matter where you are in Vancouver, GoDay.ca can provide you with a
          Vancouver Payday Loan on the spot! Join the thousands of Canadians
          that trust GoDay.ca for any and all online payday loans. Whether
          you’re filling out a Vancouver payday loan application at home, on the
          bus, or at the office, we make it easy. Don’t let the cost of
          Vancouver stop you from doing what you want.
        </p>
        <p>
          For your peace of mind, we’re not the only ones who think our online
          payday loan service is great. Here is just some of the great feedback
          we’ve received from some of our Vancouver payday loan customers
          through TrustPilot - an independent third party website. Be sure to
          check out the{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            GoDay.ca TrustPilot Reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainVancouverApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Vancouver
      </h1>
      <div className="location-text">
        <p>
          Large, sudden expenses always come up when you least expect them.
          Whether you need to pay for a car repair, a broken heater, or an
          unwanted trip to the dentist you don’t have time to waste when you’re
          trying to make ends meet. For easy access to a{" "}
          <Link to="/">payday loan online</Link> in Vancouver, trust GoDay.ca.
          Our simple and quick online payday loan application takes just a few
          minutes to complete and if your online payday loan application is
          approved you’ll get your payday loan (up to $1,500) deposited into
          your bank account as soon as the same day.
        </p>
        <p>
          A quick <Link to="/education">online payday loan</Link> from GoDay.ca
          (the fastest and easiest online payday loan provider) can get you back
          on track. An online payday loan is an unsecured loan that’s designed
          to cover any unexpected expenses between today and your next pay day.
          We use the industry’s fastest tools to review your online payday loan
          application, set up your account, and make sure your payday loan is on
          it’s way! Issuing Vancouver payday loans online since 2012, GoDay.ca
          is proud to offer each resident access to a fast and easy payday loan
          process.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Reasons Vancouver trusts GoDay.ca for an online payday loan
        </h2>
        <MainVancouverReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            Go ahead Vancouver! Apply for a payday loan online. We make it easy.
            We’re provincially licensed to issue payday loans in Vancouver.
          </div>
          <div className="location-offers-text">
            We make it easy for you to get a payday loan by offering:
          </div>
          <MainVancouverOffer1 />
          <MainVancouverOffer2 />
          <MainVancouverOffer3 />
          <MainVancouverOffer4 />
          <MainVancouverOffer5 />
          <MainVancouverOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          You may need an online payday loan if you live in the 604...
        </h2>
        <div className="location-details location-text">
          <MainVancouverInitialContent />
          <MainVancouverSeeMore />
          <MainVancouverMoreContent />
          <MainVancouverApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainVancouver;
