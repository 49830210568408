import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./location.css";

const MainMississauga = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainMississaugaReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainMississaugaReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            Privacy and security are a top priority here at GoDay. Which is why
            we use the most advanced industry practices to make sure your
            personal information is never shared and only used with your
            authorization.
          </div>
        </div>
      ) : null;

    const MainMississaugaReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is a trusted name for online payday loans in Mississauga and
            across Canada. That’s why we have an A+ accreditation rating from
            the Better Business Bureau and hundreds of reviews from GoDay
            customers just like you. Take a look at our{" "}
            <a
              href="https://ca.trustpilot.com/review/goday.ca?utm_medium=trustbox&amp;utm_source=Carousel"
              target="_blank"
              rel="noopener noreferrer"
            >
              TrustPilot reviews
            </a>{" "}
            and our Ontario Payday Loan License before choosing us for your next
            payday loan.
          </div>
        </div>
      ) : null;

    const MainMississaugaReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            GoDay is an online company and available 24/7. So why walk into a
            retail store for a payday loan in Mississauga again? GoDay makes it
            easy for you to get the cash you need so you know that financial
            assistance is on the way. See our{" "}
            <a href="/application">online payday loan application</a> here.
          </div>
        </div>
      ) : null;

    const MainMississaugaReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            Even though GoDay is 100% online, any time you phone our customer
            service line you’ll be speaking with a real person from our office.
            Our customer service team is ready to help you with any questions
            you may have about your loan application process and any other
            questions you may have along the way.
          </div>
        </div>
      ) : null;

    const MainMississaugaReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            No matter where you’re located in Ontario, from downtown Mississauga
            or down by the lake, GoDay offers speedy, smart and secure online
            payday loans.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainMississaugaReason1 />
        <MainMississaugaReason2 />
        <MainMississaugaReason3 />
        <MainMississaugaReason4 />
        <MainMississaugaReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainMississaugaOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainMississaugaOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainMississaugaOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainMississaugaOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainMississaugaOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainMississaugaOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Ontario</small>
      </h3>
    </section>
  );

  const MainMississaugaSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainMississaugaInitialContent = () => (
    <section>
      <p>
        Looking at getting a payday loan with us? Look no further. GoDay offers
        speedy, smart and secure online payday loans in Mississauga and Ontario.
        The first step is to head to our website and complete our quick and easy
        online payday loan application. We will need a few details from you, so
        have things like your personal, banking, employment, and payroll
        information ready. Once you have provided us with all your correct
        information, our automated system will be able to tell you instantly if
        you have been approved and for how much you have been approved for.
      </p>
      <p>
        Now that you have completed your online payday loan application, our
        customer service team will reach out to help set up your GoDay account
        and let you know how to get the cash you need. GoDay’s online payday
        loans are regulated by the province of Ontario and as a licensed payday
        lender, we can offer online payday loans in Mississauga ranging from
        $100 to $1,500.
      </p>
    </section>
  );

  const MainMississaugaMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          Here at GoDay, we use the industry’s fastest tools to ensure your
          payday loan is delivered as quickly as possible. So, once you have
          reviewed and signed your online payday loan documents, we work with
          your financial institution to have your payday loan deposited into
          your account. You can count on GoDay to get the cash you need within
          24 hours. And depending on the time of day that we received your
          signed loan agreement and the business hours of your financial
          institution, your payday loan will either be delivered to you in the
          same day or the next morning. Have questions for us? A GoDay
          representative is available for any questions you may have along the
          way.
        </p>
        <p>
          Whether you are headed to Celebration Square or to the airport, GoDay
          can get you the cash you need from any device – anytime and anywhere.
          Join the thousands of Canadians from coast to coast that trust GoDay
          for their online payday loan needs. Want to learn more about us? See
          what our customers have to say about is here{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            TrustPilot reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainMississaugaApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Mississauga
      </h1>
      <div className="location-text">
        <p>
          You never know when a financial emergency can happen. Every year, a
          large proportion of Canadians face unplanned emergency expenses. And
          when those unplanned expenses come, do you have the savings set aside
          to ensure you can make all your payments? When it comes to making sure
          all your bills get paid and you’re not maxing out on all forms of
          payment methods, people turn to GoDay for an online payday loan.
        </p>
        <p>
          GoDay has been operating in Mississauga since 2012. Whether you are
          looking for financial assistance until your next paycheque or need
          help with a financial emergency, getting a payday loan in Mississauga
          could not be any simpler.
        </p>
        <p>
          We also operate 100% online. Our online loan applications take minutes
          to complete and will provide you with an instant decision about your
          loan. GoDay is here so you don’t ever have to walk into a physical
          store again. Get an online payday loan in Mississauga from the comfort
          of your own home.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why Mississauga trusts GoDay for online payday loans
        </h2>
        <MainMississaugaReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Mississauga. We
            make it simple for you to access a payday loan by offering:
          </div>
          <MainMississaugaOffer1 />
          <MainMississaugaOffer2 />
          <MainMississaugaOffer3 />
          <MainMississaugaOffer4 />
          <MainMississaugaOffer5 />
          <MainMississaugaOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Get your payday loan in Mississauga today
        </h2>
        <div className="location-text">
          <p>
            As one of the largest cities in both Ontario and across Canada,
            Mississauga is known for being a busy city! With being so close to
            Toronto, Mississauga has seen a huge boom in population over the
            years which also means the cost’s everyday things such as food and
            housing have also continued to rise. That’s why GoDay has been
            helping Mississauga resident’s sine 2012 with quick and easy online
            payday loans.
          </p>
          <p>
            There is always a lot happening in Mississauga! Whether you are
            shopping at Square One, hanging out in Streetsville, or heading down
            to the lake for dinner, there is always something to keep you busy
            in Mississauga. And GoDay is here to help you enjoy all of these
            things with our online payday loan services, so you can get back to
            what really matters.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          How to get an online payday loan in Mississauga
        </h2>
        <div className="location-details location-text">
          <MainMississaugaInitialContent />
          <MainMississaugaSeeMore />
          <MainMississaugaMoreContent />
          <MainMississaugaApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainMississauga;
