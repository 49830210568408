import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainKingston = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainKingstonReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainKingstonReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            At GoDay.ca, we use top level encryption to secure the safety of
            your personal information. Furthermore, we only use your information
            with your authorization and do not share it with any third party
            companies or organizations including other online payday loan
            contemporaries or in person payday loan lenders.
          </div>
        </div>
      ) : null;

    const MainKingstonReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay.ca is a trustworthy name in among those who give online payday
            loans throughout Kingston and the surrounding regions. Our reviews
            are posted on TrustPilot for customer accessibility and our site
            displays our A+ accreditation from the Better Business Bureau along
            with our Ontario Payday Loan License. You can take a look at this
            before applying for an online payday loan.
          </div>
        </div>
      ) : null;

    const MainKingstonReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            Everything is done 100% online. There’s no need to journey around
            Kingston or the surrounding region in order to get to a retail
            office for a payday loan with us. In a little less than 5 minutes
            you can complete our payday loan application online and our
            automated system can let you know in moments if you’re pre-approved.
            That way you can focus on what’s important in life, and we can focus
            on processing your online payday loan application as quickly as
            possible.
          </div>
        </div>
      ) : null;

    const MainKingstonReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            Kingston’s wonderful and, though it’s a bit of a way from Toronto
            (where our office is located), it’s still in the same time zone.
            This guarantees that if you need to contact our customer service
            department by phone or email, you’ll be speaking with a fellow
            Canadian who will be able to respond in real time. Our team of
            experts cares about your interests. So whether you want to take a
            stroll through Martello Alley, have a look at the Kingston
            Waterfront or take a cruise around the Thousand Islands, we will
            work fast to facilitate your online payday loan and get you funded!
          </div>
        </div>
      ) : null;

    const MainKingstonReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            From Kingston to Toronto to Regina to Vancouver, GoDay.ca is the
            most quick and easy option for online payday loans! We don’t have
            storefront or retail offices, and we make our online service quick
            and easy to use. No waiting in line, no face to face exchanges, just
            fast online services for online payday loans!
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainKingstonReason1 />
        <MainKingstonReason2 />
        <MainKingstonReason3 />
        <MainKingstonReason4 />
        <MainKingstonReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainKingstonOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 submissions</strong>
        <br />
        <small>for any time of day you need to apply</small>
      </h3>
    </section>
  );

  const MainKingstonOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Secure online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainKingstonOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Upfront fee disclosure</strong>
        <br />
        <small>so you know exactly what’s on the table</small>
      </h3>
    </section>
  );

  const MainKingstonOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Fast cash</strong>
        <br />
        <small>
          so you can focus on what’s important while your payday loan is on the
          way
        </small>
      </h3>
    </section>
  );

  const MainKingstonOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>No paperwork or faxing required</strong>
        <br />
        <small>with our online payday loans</small>
      </h3>
    </section>
  );

  const MainKingstonOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>We are licensed</strong>
        <br />
        <small>in Ontario to issue payday loans online</small>
      </h3>
    </section>
  );

  const MainKingstonSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainKingstonInitialContent = () => (
    <section>
      <p>
        Congratulations! As a resident of Kingston, one of the most attractive
        cities in Canada, you are eligible for an payday loan online. Some of
        the fun activities include the Kingston Trolley Tour, Kingston 1000
        Islands Cruises and The Grand Theatre. But what do all these things have
        in common? Money! And they’re all a bit pricey!
      </p>
      <p>
        An online payday loan from GoDay.ca will help you to find yourself in
        the city of Kingston without having to worry about unplanned expenses.
        You could also check out some less costly activities in Kingston and the
        surrounding area.
      </p>
    </section>
  );

  const MainKingstonMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          There are so many things to do in Kingston that don’t require much
          money. You could visit Queen’s University’s exquisite grounds,
          historic Fort Henry and Bellevue House (former home of Sir John A.
          MacDonald). If of course you wanted to venture out into this wonderful
          and vibrant city but happen to be short on funds, you can take care of
          your responsibilities with our help by applying for a payday loan
          online at GoDay.ca.
        </p>
        <p>
          If you did want an online payday loan in Kingston, here are the simple
          steps you need to take going forward.
        </p>
        <p>
          You’re already on our website, so all you have to do now is fill out
          our quick and easy online payday loan application. Make sure you fill
          out the online payday loan application form correctly in regards to
          your personal, banking, employment and payroll information. If any of
          the information is incomplete, inaccurate or unable to be verified,
          you might not be able to receive a payday loan online from GoDay.ca.
          Once you’ve completed the form, just take a quick 30 second look over
          before submitting it. The system would automatically decline you if
          anything is out of place, even accidentally, and we don’t want that.
          While first time customers can only receive between$100 and $500, a
          returning customer is eligible for up to $1,500 for their next online
          payday loan!
        </p>
        <p>
          The tools that we use are specific to our industry, reliable and able
          to ensure that your cash advance is delivered quickly, as soon as
          possible after you have reviewed and signed your online payday loan
          agreement and we have processed it. Receiving your payday loan on the
          same day or overnight depends on a few small factors: the time of day
          you are approved, the time you sign the loan agreement and your
          financial institution’s operating hours. After you application for an
          online payday loan has been approved, you’ll be able to confirm this
          with a GoDay.ca representative.
        </p>
        <p>
          Whatever your area code may be in or around Kingston, GoDay.ca will be
          able to issue you a loan as soon as the application process is
          successfully completed. Since 2012, thousands of Canadians have
          received first time online payday loans and returned for much higher
          amounts. You can be one of those individuals today! Once you’ve done
          the process, you can get back to the important things in life in no
          time. GoDay.ca is here for you, providing quick and easy online payday
          loans.
        </p>
      </section>
    );
  };

  const MainKingstonApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Kingston
      </h1>
      <div className="location-text">
        <p>
          Unplanned expenses can come out of nowhere at any time. Computer
          repairs, emergency plane tickets or unforeseen bill payments don’t
          typically leave any time for planning. In Kingston, GoDay.ca can get
          you on track with an online payday loan. You just have to submit a
          quick online payday loan application and you could receive as much as
          $500, deposited into your account on the same day.
        </p>
        <p>
          A <Link to="/">payday loan online</Link> with GoDay.ca can help you
          quickly get things back together. An online payday loan is there to
          help you take care of any sudden expenses that may pop up between
          today and your regularly scheduled pay date, and it’s unsecured. We
          would process your online payday loan application, set up an account
          with the information you provide and send you a quick{" "}
          <Link to="/blog/">online payday loan</Link> using our unique industry
          tools.
        </p>
        <p>
          GoDay.ca has been issuing loans since 2012, and have been proud to
          offer a fast and easy payday loan process, not only to residents of
          Kingston, but many other towns and cities.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Reasons Kingston trusts GoDay.ca for an online payday loan
        </h2>
        <MainKingstonReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              Let’s do it, Kingston! Apply for a payday loan online in a jiffy!
            </strong>
          </div>
          <div className="location-offers-text">
            The province of Ontario has licensed us to issue payday loans to
            residents of Kingston, and we make it easy for you to get an online
            payday loan by:
          </div>
          <MainKingstonOffer1 />
          <MainKingstonOffer2 />
          <MainKingstonOffer3 />
          <MainKingstonOffer4 />
          <MainKingstonOffer5 />
          <MainKingstonOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          You may need an online payday loan if you live in the 613 or 343...
        </h2>
        <div className="location-details location-text">
          <MainKingstonInitialContent />
          <MainKingstonSeeMore />
          <MainKingstonMoreContent />
          <MainKingstonApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainKingston;
