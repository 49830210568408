import { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import "./basic-why-us-2.css";

const InstallmentWhyUs2 = () => {
  const [slideIndex, setSlideIndex] = useState(1);

  const moveBack = () => setSlideIndex(Math.max(slideIndex - 1, 1));
  const moveForward = () => setSlideIndex(Math.min(slideIndex + 1, 4));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const BasicWhyUs2Reason1 = () => (
    <section className="basic-why-us-2-reason">
      <div
        className="basic-why-us-2-reason-icon gallery"
        id="basic-why-us-2-reason-icon-1"
      ></div>
      <h3 className="basic-why-us-2-reason-title">No hidden fees</h3>
      <div className="basic-why-us-2-reason-text">
        Our loans have no hidden fees. What you see is what you pay.
      </div>
    </section>
  );

  const BasicWhyUs2Reason2 = () => (
    <section className="basic-why-us-2-reason">
      <div
        className="basic-why-us-2-reason-icon gallery"
        id="basic-why-us-2-reason-icon-2"
      ></div>
      <h3 className="basic-why-us-2-reason-title">You’re in control</h3>
      <div className="basic-why-us-2-reason-text">
        You choose how much to borrow – from $1,000 to $15,000 with terms up to
        60 months.
      </div>
    </section>
  );

  const BasicWhyUs2Reason3 = () => (
    <section className="basic-why-us-2-reason">
      <div
        className="basic-why-us-2-reason-icon gallery"
        id="basic-why-us-2-reason-icon-3"
      ></div>
      <h3 className="basic-why-us-2-reason-title">All credit scores welcome</h3>
      <div className="basic-why-us-2-reason-text">
        We work with all levels of credit and will do our best to find the best
        financial solution for you.
      </div>
    </section>
  );

  const BasicWhyUs2Reason4 = () => (
    <section className="basic-why-us-2-reason">
      <div
        className="basic-why-us-2-reason-icon gallery"
        id="basic-why-us-2-reason-icon-4"
      ></div>
      <h3 className="basic-why-us-2-reason-title">Easy exit</h3>
      <div className="basic-why-us-2-reason-text">
        Our loans all have fixed payments with no prepayment penalties. Pay off
        and exit your loan at any time.
      </div>
    </section>
  );

  const BasicWhyUs2Navigator = () => {
    const moveBackClassName =
      slideIndex === 1 ? "navigator-first" : "navigator-previous";
    const moveForwardClassName =
      slideIndex === 4 ? "navigator-last" : "navigator-next";

    return (
      <nav className="basic-why-us-2-navigator">
        <ul className="gallery">
          <li>
            <span className={moveBackClassName} onClick={moveBack}></span>
          </li>
          <li>
            <span className={moveForwardClassName} onClick={moveForward}></span>
          </li>
        </ul>
      </nav>
    );
  };

  const resize = () => setSlideIndex(1);

  useEffect(() => {
    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, []);

  return (
    <section className="basic-why-us-2 content">
      <h2 className="basic-why-us-2-title">
        Advantages of a GoDay installment loan
      </h2>
      <div className="basic-why-us-2-content" {...slide}>
        <div
          className={`basic-why-us-2-reasons basic-why-us-2-reasons-${slideIndex}`}
        >
          <BasicWhyUs2Reason1 />
          <BasicWhyUs2Reason2 />
          <BasicWhyUs2Reason3 />
          <BasicWhyUs2Reason4 />
        </div>
      </div>
      <BasicWhyUs2Navigator />
    </section>
  );
};

export default InstallmentWhyUs2;
