import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./location.css";

const MainBritishColumbia = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainBritishColumbiaReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainBritishColumbiaReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay is an online company which means we take privacy and security
            seriously. We use best in class encryption to make sure that your
            personal information is only used with your authorization and that
            your payday loan application information is never shared with anyone
            else.
          </div>
        </div>
      ) : null;

    const MainBritishColumbiaReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is one of the top, and most trusted sources of online payday
            loans in British Columbia and across Canada. But you don’t have to
            take our word for it - take a look at our customer reviews on{" "}
            <a
              href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
              target="_blank"
              rel="noopener noreferrer"
            >
              TrustPilot
            </a>
            , check our A+ rating from the Better Business Bureau, or review our
            Ontario Payday Loan License before applying for a loan with us.
          </div>
        </div>
      ) : null;

    const MainBritishColumbiaReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            As we mentioned, we are 100% online. You don’t have to walk into a
            retail store for a payday loan in British Columbia ever again. The
            average GoDay{" "}
            <a href="/application">online payday loan application</a> takes five
            minutes to complete and our highly automated system works to ensure
            you receive the cash you need as soon as possible.
          </div>
        </div>
      ) : null;

    const MainBritishColumbiaReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            We’re here to help! Any time you phone our customer service line you
            will be speaking with a one of our amazing customer service
            representatives who will help you with any questions you may have.
            Let us do the work for you. <Link to="/contact">Contact us</Link>{" "}
            today.
          </div>
        </div>
      ) : null;

    const MainBritishColumbiaReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            No matter where you’re located in BC, GoDay offers speedy, smart and
            secure online payday loans.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainBritishColumbiaReason1 />
        <MainBritishColumbiaReason2 />
        <MainBritishColumbiaReason3 />
        <MainBritishColumbiaReason4 />
        <MainBritishColumbiaReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainBritishColumbiaOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainBritishColumbiaOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainBritishColumbiaOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainBritishColumbiaOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainBritishColumbiaOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainBritishColumbiaOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in British Columbia</small>
      </h3>
    </section>
  );

  const MainBritishColumbiaSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainBritishColumbiaInitialContent = () => (
    <section>
      <p>
        GoDay’s payday loans are speedy, smart and secure. So how do you get
        started with an online payday loan with us? The first step is to visit
        our website and apply using our quick and easily application tool. Our
        application will then as you to provide some personal information such
        as your banking information, employment and payroll details. Once you
        have submitted the most up to date and correct information, our
        automated system will give you an immediate decision to let you know if
        you have been approved.
      </p>
      <p>
        Now that you have completed your online payday loan application, our
        customer service team will reach out to help set up your GoDay account
        and let you know how to get the cash you need. Our automated and online
        cash advance system will review your application and let you know how
        much we can approve you for. GoDay’s online payday loans are regulated
        by the province of Ontario and as a licensed payday lender, we can offer
        online payday loans in British Columbia ranging from $100 to $1,500.
      </p>
    </section>
  );

  const MainBritishColumbiaMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          The next part of the process is simple. You will receive and sign your
          online payday loan documents and once completed, we use the industry’s
          most reliable tools to get you the cash you need as quickly as
          possible. Depending on the time of day we receive your signed loan
          application and the business hours of your financial institution, your
          payday loan will either be delivered to you in the same day or the
          next morning. That’s right, with GoDay you will have the cash you need
          within 24 hours.
        </p>
        <p>
          No matter where you are located in British Columbia, GoDay can provide
          you with an online payday loan on the spot! Have questions along the
          way? One of our GoDay representatives can provide you with the
          necessary information once your online payday loan is approved.
        </p>
        <p>
          Want to see what others have to say about us? Take a look at our{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            reviews on TrustPilot
          </a>{" "}
          from customers just like you.
        </p>
      </section>
    );
  };

  const MainBritishColumbiaApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a quick and easy online payday loan in British Columbia
      </h1>
      <div className="location-text">
        <p>
          Financial hardships happen. Unexpected costs and expenses pop up all
          the time and trying to stay afloat financially can be stressful. Here
          at GoDay we understand that life happens and is here for you when you
          need us. Whether you are needing help to get to your next paycheck or
          there has been a financial emergency, learn more about how our online
          payday loans in British Columbia are helping people like you.
        </p>
        <p>
          Getting a payday loan in British Columbia could not be any simpler!
        </p>
        <p>
          Since 2012, GoDay has been helping customers in British Columbia and
          across Canada with online payday loans. The best part about GoDay? Our
          online loan applications take less than five minutes to complete as we
          offer one of the fastest payday loan services in Canada. We accept
          online payday loan applications 24/7, which mean that you can apply
          from any device from comfort and in the privacy of your own home. This
          means, you don’t have to adjust to business hours or physically head
          to a store location to get a payday loan again. GoDay makes payday
          loans in BC easy so you can get back to what matters.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          British Columbia trusts GoDay for online payday loans
        </h2>
        <MainBritishColumbiaReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So, what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in British
            Columbia. We make it simple for you to access a payday loan by
            offering:
          </div>
          <MainBritishColumbiaOffer1 />
          <MainBritishColumbiaOffer2 />
          <MainBritishColumbiaOffer3 />
          <MainBritishColumbiaOffer4 />
          <MainBritishColumbiaOffer5 />
          <MainBritishColumbiaOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Get your quick and easy payday loan in BC today
        </h2>
        <div className="location-text">
          <p>
            Living in the beautiful province of BC can come with a cost. The
            price of everyday things such as food and housing are continuing to
            rise across British Columbia and to help you with BC’s rising costs;
            you can turn to a payday loan with GoDay.
          </p>
          <p>
            As Canada’s most western province and known for its mountainous
            views, British Columbia is one of Canada’s largest provinces with so
            much to do and see. So, whether you are headed to downtown
            Vancouver, the mountains in Whistler or wine country in Kelowna,
            GoDay is here to help you enjoy all of the things British Columbia
            has to offer and more with our online payday loan services.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          What is GoDay’s process and how does it work?
        </h2>
        <div className="location-details location-text">
          <MainBritishColumbiaInitialContent />
          <MainBritishColumbiaSeeMore />
          <MainBritishColumbiaMoreContent />
          <MainBritishColumbiaApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainBritishColumbia;
