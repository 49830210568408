import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./location.css";

const MainSurrey = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainSurreyReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainSurreyReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            As an online company, your privacy and security are our top
            priority. With GoDay, your private information, stays private and we
            use the most secure tools to make sure your personal information is
            never shared without your permission.
          </div>
        </div>
      ) : null;

    const MainSurreyReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is a trusted name for online payday loans in Surrey and across
            Canada. Before getting a loan with us, check out our credentials. We
            have an A+ accreditation rating from the Better Business Bureau and
            see what our customers have to say with our hundreds of reviews on{" "}
            <a
              href="https://ca.trustpilot.com/review/goday.ca?utm_medium=trustbox&amp;utm_source=Carousel"
              target="_blank"
              rel="noopener noreferrer"
            >
              TrustPilot
            </a>
            .
          </div>
        </div>
      ) : null;

    const MainSurreyReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            GoDay makes it easy for our customers in Surrey and across British
            Columbia to get the cash you need, when you need it. We are 100%
            online and our application tool is available 24/7. See our{" "}
            <a href="/application">online payday loan application</a> here.
          </div>
        </div>
      ) : null;

    const MainSurreyReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            Have questions for us? We may be a virtual company, but when you
            contact us, you will be speaking with one of our amazing customer
            service representatives. Don’t hesitate to reach out to us, we are
            here to help.
          </div>
        </div>
      ) : null;

    const MainSurreyReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            Since 2012, GoDay has been offering online payday loans from British
            Columbia’s west coast to the maritime provinces out east. No matter
            where you’re located - GoDay is here for you.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainSurreyReason1 />
        <MainSurreyReason2 />
        <MainSurreyReason3 />
        <MainSurreyReason4 />
        <MainSurreyReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainSurreyOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainSurreyOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainSurreyOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainSurreyOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainSurreyOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainSurreyOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Ontario</small>
      </h3>
    </section>
  );

  const MainSurreySeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainSurreyInitialContent = () => (
    <section>
      <p>
        Getting an online payday loan in Surrey has never been easier. Head to
        our website to get started by filling out our online payday loan
        application. It is quick and easy and will ask you for a few things such
        as your banking, employment, and payroll information. After you provide
        us with your personal information, our automated system will let you
        know if you have been approved and for how much you have been approved
        for, or if we need further details from you.
      </p>
      <p>
        Once you have completed our online payday loan application, our customer
        service team will connect with you to help set up your GoDay account and
        let you know our process about getting you the cash you need. Our payday
        loans are regulated by the province of British Columbia and as a
        licensed payday lender, we offer loans in the amounts of $100 to $1,500.
      </p>
    </section>
  );

  const MainSurreyMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          The next part of the process is simple. You will receive and sign your
          online payday loan documents and once completed, we use the industry’s
          most reliable tools to get you the cash you need as quickly as
          possible. When will you get your loan? Depending on the time of day we
          receive your signed loan application and the business hours of your
          financial institution, your payday loan will either be delivered to
          you in the same day or the next morning. That’s right, with GoDay you
          will have the cash you need within 24 hours. Have questions along the
          way? One of our GoDay representatives can provide you with the
          necessary information once your online payday loan is approved.
        </p>
        <p>
          No matter your address in the Metro Vancouver area, GoDay provides
          people with instant online payday loans. So, head to Redwood Park or
          stop by your favourite craft brewery in Surrey, because GoDay is here
          to for you when you need fast cash.
        </p>
        <p>
          Want to learn more about us? See what our customers have to say about
          is here{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            TrustPilot reviews
          </a>{" "}
          and become one of the thousands of Canadians who trust GoDay for their
          online payday loans.
        </p>
      </section>
    );
  };

  const MainSurreyApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Surrey
      </h1>
      <div className="location-text">
        <p>
          Life happens. And there are things you cannot always financially
          prepare for. Since 2012, Canadians have been turning to GoDay to help
          them get through their financially challenging times.
        </p>
        <p>
          With GoDay, you can get you the cash you need quickly and easily.
          Since we partner with the most reputable financial institutions and
          uses the fastest tools in the industry to make sure you get the
          speediest service possible. We are 100% online and our application
          tool is available 24/7, so you can apply for an online payday loan in
          Surrey or anywhere in British Columbia from your computer or mobile
          device and our highly automated system will do all it can to ensure
          you receive your funds as soon as possible.
        </p>
        <p>
          Getting an online payday loan in Surrey has never been simpler. If we
          can help you get through a few financial hurdles, terrific. If we can
          help you avoid them altogether, even better. Learn more about why
          people turn to GoDay.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Getting an online payday loan in Surrey has never been easier
        </h2>
        <MainSurreyReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So, what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Surrey. We make
            it simple for you to access a payday loan by offering:
          </div>
          <MainSurreyOffer1 />
          <MainSurreyOffer2 />
          <MainSurreyOffer3 />
          <MainSurreyOffer4 />
          <MainSurreyOffer5 />
          <MainSurreyOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why Surrey chooses GoDay for online payday loans
        </h2>
        <div className="location-text">
          <p>
            Since 2012, GoDay has been proud to issue payday loans online to
            people in Surrey, BC. With over 200 parks, getting outside on nature
            trails, bike parks or visiting Peace Arch Park that marks the border
            between the USA and Canada is something to be explored. With the
            resources of a big city, Surrey has grown into an accessible and
            sustainable city where people can live, visit, work and play and
            love to call home. And GoDay will be here for you when you need us,
            because you can always rely on GoDay’s online payday loans to make
            sure you always feel at home.
          </p>
          <p>
            We know that no matter where you are, the cost of living continues
            to rise. Especially in a growing city like Surrey. Whether your
            favourite spot is Crescent Beach or Darts Hill Garden, GoDay is here
            for you. If we can help you get through a few financial hurdles,
            terrific. If we can help you avoid them altogether, even better. Try
            our services and let us show you why thousands of Canadians turn to
            GoDay when they need a payday loan.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          So how do you get an online payday loan in Surrey?
        </h2>
        <div className="location-details location-text">
          <MainSurreyInitialContent />
          <MainSurreySeeMore />
          <MainSurreyMoreContent />
          <MainSurreyApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainSurrey;
