import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./location.css";

const MainWinnipeg = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainWinnipegReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainWinnipegReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay operates 100% online, which means we take your privacy and
            security seriously. We use the most advanced industry tools to make
            sure your personal information is never shared and only used with
            your authorization.
          </div>
        </div>
      ) : null;

    const MainWinnipegReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            GoDay is a trusted name for online payday loans in Winnipeg and
            across Canada. That’s why we have an A+ accreditation rating from
            the Better Business Bureau and hundreds of reviews from GoDay
            customers just like you. Take a look at our{" "}
            <a
              href="https://ca.trustpilot.com/review/goday.ca?utm_medium=trustbox&amp;utm_source=Carousel"
              target="_blank"
              rel="noopener noreferrer"
            >
              TrustPilot reviews
            </a>{" "}
            to see why people choose us for online payday loans.
          </div>
        </div>
      ) : null;

    const MainWinnipegReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            GoDay is an online company and our online application is available
            24/7. So why walk into a retail store for a payday loan in Winnipeg
            again? GoDay makes it easy for you to get the cash you need so you
            know that financial assistance is on the way. See our{" "}
            <a href="/application">online payday loan application</a> here.
          </div>
        </div>
      ) : null;

    const MainWinnipegReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            GoDay may be 100% online, but if you have any questions, give us a
            call and you can speak with our customer service team. Our team is
            ready to help you with any questions you may have about your loan
            application process and any other questions you may have along the
            way.
          </div>
        </div>
      ) : null;

    const MainWinnipegReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            No matter where you’re located in Manitoba from downtown Winnipeg or
            up to Grand Rapids, GoDay offers speedy, smart and secure online
            payday loans.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainWinnipegReason1 />
        <MainWinnipegReason2 />
        <MainWinnipegReason3 />
        <MainWinnipegReason4 />
        <MainWinnipegReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainWinnipegOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainWinnipegOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainWinnipegOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainWinnipegOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainWinnipegOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainWinnipegOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Manitoba</small>
      </h3>
    </section>
  );

  const MainWinnipegSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainWinnipegInitialContent = () => (
    <section>
      <p>
        Are you ready to get an online payday loan with us? Look no further.
        GoDay offers speedy, smart and secure online payday loans in Winnipeg
        and throughout all of Manitoba. The first step is to head to our website
        and complete our quick and easy online payday loan application. We will
        need a few details from you - things like your personal, banking,
        employment, and payroll information. Once you have provided us with your
        most up to date information, our automated system will give you an
        immediate decision on if you have been approved with us.
      </p>
      <p>
        Now that you have completed your online payday loan application, our
        customer service team will reach out to help set up your GoDay account
        and ensure you get your funds. GoDay’s online payday loans are regulated
        by the province of Manitoba and as a licensed payday lender, we can
        offer online payday loans in Winnipeg ranging from $100 to $1,500.
      </p>
    </section>
  );

  const MainWinnipegMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          Here at GoDay, we use the industry’s fastest tools to ensure your
          payday loan is delivered as quickly as possible, so you have the cash
          you need. Once you have reviewed and signed your online payday loan
          documents, we work with your financial institution to have your payday
          loan deposited into your account. You can count on GoDay to get the
          cash you need within 24 hours. And depending on the time of day that
          we received your signed loan agreement and the business hours of your
          financial institution, your payday loan will either be delivered to
          you in the same day or the next morning. Have questions for us? A
          GoDay representative is available for any questions you may have along
          the way.
        </p>
        <p>
          Go cheer on the Winnipeg Jets or enjoy events like Festival du
          Voyageur, GoDay can get you the cash you need from any device –
          anytime and anywhere. Join the thousands of Canadians from coast to
          coast that trust GoDay for their online payday loan needs. Want to
          learn more about us? See what our customers have to say about is here{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            TrustPilot reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainWinnipegApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast, online payday loan in Winnipeg
      </h1>
      <div className="location-text">
        <p>
          Each year, many Canadians face unplanned or emergency expenses. You
          never know when they can happen, but when they do, are you financially
          prepared with the savings set aside to ensure your payments get made?
          GoDay’s online payday loans are here for you when things get tough
          financially – from unplanned to emergency expenses and to help relieve
          you of your financial stress.
        </p>
        <p>
          GoDay has been helping Canadians with our online payday loans since
          2012. Whether you are looking for financial assistance until your next
          paycheque or need help with a financial emergency, getting a payday
          loan in Winnipeg could not be any easier. GoDay operates 100% online –
          our online loan applications take minutes to complete and will provide
          you with an instant decision. Don’t walk into a physical store in
          Winnipeg again with GoDay.
        </p>
        <p>
          Get an online payday loan in Winnipeg from the comfort of your own
          home today.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Winnipeg trusts GoDay for online payday loans
        </h2>
        <MainWinnipegReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So, what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Winnipeg. We
            make it simple for you to access a payday loan by offering:
          </div>
          <MainWinnipegOffer1 />
          <MainWinnipegOffer2 />
          <MainWinnipegOffer3 />
          <MainWinnipegOffer4 />
          <MainWinnipegOffer5 />
          <MainWinnipegOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          Get your payday loan in Winnipeg today
        </h2>
        <div className="location-text">
          <p>
            As the largest city and capital of Manitoba, there is always
            something happening in Winnipeg. As a tourist destination and a
            continually growing city, there are many parts of Winnipeg to enjoy.
            Whether you are visiting the zoo at Assiniboine Park, or taking a
            tour at the Canadian Museum for Human Rights, GoDay is here for
            everything Winnipeg has to offer.
          </p>
          <p>
            GoDay has been helping Canadian residents since 2012, with quick and
            easy online payday loans. Nice and warm in the summer, but classic
            Canadian cold in the winter, there is so much history in Winnipeg.
            As Winnipeg continues to grow, that also means there are growing
            costs on everyday things such as food and housing. Don’t let your
            finances get in the way of what Winnipeg has to offer - discover the
            many local restaurants or visiting The Forks because GoDay is here
            for you when you need us.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          How to get an online payday loan in Winnipeg
        </h2>
        <div className="location-details location-text">
          <MainWinnipegInitialContent />
          <MainWinnipegSeeMore />
          <MainWinnipegMoreContent />
          <MainWinnipegApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainWinnipeg;
