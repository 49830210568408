import "./basic-why-us-1.css";
import logo from "assets/images/logo.png";

const InstallmentWhyUs1 = () => {
  const BasicWhyUs1Tab1 = () => (
    <section className="basic-why-us-1-tab" id="basic-why-us-1-tab-1">
      <input
        type="radio"
        name="basic-why-us-1-tab-header"
        id="basic-why-us-1-tab-header-1"
        defaultChecked={true}
      />
      <label htmlFor="basic-why-us-1-tab-header-1">Speedy</label>
      <div className="basic-why-us-1-tab-content">
        <div className="basic-why-us-1-tab-text">
          We specialize in fast, online, installment loans. Since 2012, GoDay as
          been a market leader, offering and serving customers from coast to
          coast. Our online installment loan process is fast – we use industry
          leading tools to process your application and deliver funds in record
          time!
        </div>
      </div>
    </section>
  );

  const BasicWhyUs1Tab2 = () => (
    <section className="basic-why-us-1-tab" id="basic-why-us-1-tab-2">
      <input
        type="radio"
        name="basic-why-us-1-tab-header"
        id="basic-why-us-1-tab-header-2"
      />
      <label htmlFor="basic-why-us-1-tab-header-2">Smart</label>
      <div className="basic-why-us-1-tab-content">
        <div className="basic-why-us-1-tab-text">
          We deliver flexible loans designed to reduce stress and fit your
          budget. Taking an online personal loan with GoDay is designed for all
          your bigger purchases. With affordable payments, can you use your
          online personal loan to pay off credit cards, take care of unexpected
          expenses, or fund a major purchase.
        </div>
      </div>
    </section>
  );

  const BasicWhyUs1Tab3 = () => (
    <section className="basic-why-us-1-tab" id="basic-why-us-1-tab-3">
      <input
        type="radio"
        name="basic-why-us-1-tab-header"
        id="basic-why-us-1-tab-header-3"
      />
      <label htmlFor="basic-why-us-1-tab-header-3">Secure</label>
      <div className="basic-why-us-1-tab-content">
        <div className="basic-why-us-1-tab-text">
          The GoDay online personal loan application uses 256-bit encryption to
          ensure your data is secure and not used without your authorization.
          Since 2012, GoDay has partnered with Canada’s most reputable service
          and security providers to ensure we take every step to operate a
          robust and secure lending system.
        </div>
      </div>
    </section>
  );

  const BasicWhyUs1Reason1 = () => (
    <section className="basic-why-us-1-reason">
      <div
        className="basic-why-us-1-reason-icon gallery"
        id="basic-why-us-1-reason-icon-1"
      ></div>
      <h4 className="basic-why-us-1-reason-title">24/7 instant pre-approval</h4>
    </section>
  );

  const BasicWhyUs1Reason2 = () => (
    <section className="basic-why-us-1-reason">
      <div
        className="basic-why-us-1-reason-icon gallery"
        id="basic-why-us-1-reason-icon-2"
      ></div>
      <h4 className="basic-why-us-1-reason-title">Upfront fee disclosure</h4>
    </section>
  );

  const BasicWhyUs1Reason3 = () => (
    <section className="basic-why-us-1-reason">
      <div
        className="basic-why-us-1-reason-icon gallery"
        id="basic-why-us-1-reason-icon-3"
      ></div>
      <h4 className="basic-why-us-1-reason-title">Easy and fast experience</h4>
    </section>
  );

  const BasicWhyUs1Reason4 = () => (
    <section className="basic-why-us-1-reason">
      <div
        className="basic-why-us-1-reason-icon gallery"
        id="basic-why-us-1-reason-icon-4"
      ></div>
      <h4 className="basic-why-us-1-reason-title">No paperwork required</h4>
    </section>
  );

  const BasicWhyUs1Sidebar = () => (
    <section className="basic-why-us-1-sidebar">
      <div className="basic-why-us-1-sidebar-line-1">
        Apply In
        <br />
        Less Than
      </div>
      <div className="basic-why-us-1-sidebar-line-2">5 Minutes</div>
      <div className="basic-why-us-1-sidebar-apply-now button texture">
        <a href="/application">Get Started</a>
      </div>
    </section>
  );

  return (
    <section className="basic-why-us-1 content">
      <h2 className="basic-why-us-1-title">Fast installment loans</h2>
      <div className="basic-why-us-1-reasons">
        <div className="basic-why-us-1-tabs">
          <div className="basic-why-us-1-logo">
            <img src={logo} alt="GoDay" />
          </div>
          <BasicWhyUs1Tab1 />
          <BasicWhyUs1Tab2 />
          <BasicWhyUs1Tab3 />
        </div>
        <BasicWhyUs1Reason1 />
        <BasicWhyUs1Reason2 />
        <BasicWhyUs1Reason3 />
        <BasicWhyUs1Reason4 />
        <BasicWhyUs1Sidebar />
      </div>
    </section>
  );
};

export default InstallmentWhyUs1;
