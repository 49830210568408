import { useState } from "react";
import "./installment.css";

const InstallmentWhyUs3 = () => {
  const [isFullContent, setIsFullContent] = useState(false);

  const showFullContent = () => setIsFullContent(true);

  const BasicWhyInstallmentSeeMore = () =>
    !isFullContent ? (
      <section className="basic-installment-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons basic-installment-see-more-arrow">
          expand_more
        </i>
      </section>
    ) : null;

  const BasicWhyInstallmentInitialContent = () => (
    <section>
      <p>
        Looking for flexible options when it comes to your money? Then a GoDay
        installment loan may be right for you. Different than our payday loans,
        GoDay’s online installment loans are a great option for those looking to
        take out a larger sum of money with lower monthly payments. Installment
        loans offer a different kind of flexibility by allowing you to pay your
        loan back over several regular payments, rather than all at once.
      </p>
    </section>
  );

  const BasicWhyInstallmentMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`basic-installment-group ${className}`}>
        <p>
          GoDay offers easy installment loans online for up to $15,000. And like
          our payday loans, you can apply for installment loans online. A GoDay
          installment loan gives you the freedom to pay back your loan in full
          at any time, without penalties or fees. We all need help bridging the
          gap from time to time. That’s why there is GoDay.
        </p>
      </section>
    );
  };

  return (
    <section className="basic-installment basic-installment-block content">
      <h2 className="basic-installment-title">Why GoDay Installment Loans?</h2>
      <div className="basic-installment-text">
        <BasicWhyInstallmentInitialContent />
        <BasicWhyInstallmentSeeMore />
        <BasicWhyInstallmentMoreContent />
      </div>
    </section>
  );
};

export default InstallmentWhyUs3;
