import { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import "./basic-why-us-2.css";

const BasicWhyUs2 = () => {
  const [slideIndex, setSlideIndex] = useState(1);

  const moveBack = () => setSlideIndex(Math.max(slideIndex - 1, 1));
  const moveForward = () => setSlideIndex(Math.min(slideIndex + 1, 4));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const BasicWhyUs2Reason1 = () => (
    <section className="basic-why-us-2-reason">
      <div
        className="basic-why-us-2-reason-icon gallery"
        id="basic-why-us-2-reason-icon-1"
      ></div>
      <h3 className="basic-why-us-2-reason-title">Modern security</h3>
      <div className="basic-why-us-2-reason-text">
        Rest assured that GoDay’s online payday loan application process uses
        top level encryption to ensure your personal information is safe and
        never used without your authorization.
      </div>
    </section>
  );

  const BasicWhyUs2Reason2 = () => (
    <section className="basic-why-us-2-reason">
      <div
        className="basic-why-us-2-reason-icon gallery"
        id="basic-why-us-2-reason-icon-2"
      ></div>
      <h3 className="basic-why-us-2-reason-title">All online</h3>
      <div className="basic-why-us-2-reason-text">
        You’ll never have to walk into a retail store for a payday loan again.
        Our average <a href="/application">short term loan application</a> takes
        less than 5 minutes to complete. Even better, our automated system can
        deliver a decision on your payday loan application in seconds!
      </div>
    </section>
  );

  const BasicWhyUs2Reason3 = () => (
    <section className="basic-why-us-2-reason">
      <div
        className="basic-why-us-2-reason-icon gallery"
        id="basic-why-us-2-reason-icon-3"
      ></div>
      <h3 className="basic-why-us-2-reason-title">No hidden fees</h3>
      <div className="basic-why-us-2-reason-text">
        What you see is what you pay. All payday loan pricing is displayed
        clearly on our homepage and on your loan agreement.
      </div>
    </section>
  );

  const BasicWhyUs2Reason4 = () => (
    <section className="basic-why-us-2-reason">
      <div
        className="basic-why-us-2-reason-icon gallery"
        id="basic-why-us-2-reason-icon-4"
      ></div>
      <h3 className="basic-why-us-2-reason-title">We’re your biggest fan!</h3>
      <div className="basic-why-us-2-reason-text">
        Every one of our team members is available to help along the way. No
        question or concern is too large. <Link to="/contact">Contact Us</Link>.
      </div>
    </section>
  );

  const BasicWhyUs2Navigator = () => {
    const moveBackClassName =
      slideIndex === 1 ? "navigator-first" : "navigator-previous";
    const moveForwardClassName =
      slideIndex === 4 ? "navigator-last" : "navigator-next";

    return (
      <nav className="basic-why-us-2-navigator">
        <ul className="gallery">
          <li>
            <span className={moveBackClassName} onClick={moveBack}></span>
          </li>
          <li>
            <span className={moveForwardClassName} onClick={moveForward}></span>
          </li>
        </ul>
      </nav>
    );
  };

  const resize = () => setSlideIndex(1);

  useEffect(() => {
    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, []);

  return (
    <section className="basic-why-us-2 content">
      <h2 className="basic-why-us-2-title">More reasons to love us</h2>
      <div className="basic-why-us-2-content" {...slide}>
        <div
          className={`basic-why-us-2-reasons basic-why-us-2-reasons-${slideIndex}`}
        >
          <BasicWhyUs2Reason1 />
          <BasicWhyUs2Reason2 />
          <BasicWhyUs2Reason3 />
          <BasicWhyUs2Reason4 />
        </div>
      </div>
      <BasicWhyUs2Navigator />
    </section>
  );
};

export default BasicWhyUs2;
