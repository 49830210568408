import { makeStyles } from "@material-ui/styles";

const HeaderDivider = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: 62,
      [theme.breakpoints.up(1000)]: {
        paddingTop: 0,
      },
    },
    rule: {
      border: "none",
      height: 2,
      backgroundColor: "#f5f5f5",
    },
  }));

  const classes = useStyles();

  return (
    <div className="content">
      <div className={classes.root}>
        <hr className={classes.rule} />
      </div>
    </div>
  );
};

export default HeaderDivider;
