import { useState } from "react";
import { Link } from "react-router-dom";
import "./basic-faq.css";

const BasicFAQ = () => {
  const [isFullContent, setIsFullContent] = useState(false);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);
  const [isExpanded3, setIsExpanded3] = useState(false);
  const [isExpanded4, setIsExpanded4] = useState(false);
  const [isExpanded5, setIsExpanded5] = useState(false);
  const [isExpanded6, setIsExpanded6] = useState(false);
  const [isExpanded7, setIsExpanded7] = useState(false);
  const [isExpanded8, setIsExpanded8] = useState(false);
  const [isExpanded9, setIsExpanded9] = useState(false);
  const [isExpanded10, setIsExpanded10] = useState(false);

  const BasicFAQSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="basic-faq-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons basic-faq-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const BasicFAQEntry1 = () => {
    const bullet = isExpanded1 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded1 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded1(!isExpanded1);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>What
          service does GoDay provide?
        </h3>
        {isExpanded1 && (
          <p>
            GoDay provides Canadians with online payday loans. Our payday loans
            are not intended to provide a long-term financial solution. Rather,
            they are intended to solve urgent but temporary cash-flow needs.
          </p>
        )}
      </div>
    );
  };

  const BasicFAQEntry2 = () => {
    const bullet = isExpanded2 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded2 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded2(!isExpanded2);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>How
          do I apply for a GoDay payday loan?
        </h3>
        {isExpanded2 && (
          <>
            <p>
              You can apply for an online payday loan with GoDay by completing
              our simple <a href="/application">online application form</a>{" "}
              24/7. The process is quick and easy; we are committed to offering
              one of the fastest, payday loan services in Canada. Within seconds
              of submitting your application, we will let you know if you have
              been pre-approved.
            </p>
            <p>
              Should we need any additional information from you, we’ll be in
              touch promptly by phone or email. If approved, we will use the
              industry’s fastest tools to transfer your online payday loan as
              soon as possible. Our Toronto based Customer Service team is
              available to help along the way should you have any questions or
              concerns. Get started by submitting a loan application{" "}
              <a href="/application">here</a>.
            </p>
          </>
        )}
      </div>
    );
  };

  const BasicFAQEntry3 = () => {
    const bullet = isExpanded3 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded3 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded3(!isExpanded3);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>How
          quickly can I receive my payday loan?
        </h3>
        {isExpanded3 && (
          <p>
            GoDay partners with the most reputable financial institutions and
            uses the fastest tools in the industry to ensure you get the
            speediest payday loan possible. Your loan is quite literally on it’s
            way to you once you receive an email from us confirming it is in
            transit. GoDay funds payday loans by direct deposit and email money
            transfer. Actual time to receive your funds depends on your
            financial institution and the time of your application. Once your
            application has been funded, our Customer Service Team will be happy
            to provide an estimated delivery time for your cash advance.
          </p>
        )}
      </div>
    );
  };

  const BasicFAQEntry4 = () => {
    const bullet = isExpanded4 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded4 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded4(!isExpanded4);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>How
          much can I borrow?
        </h3>
        {isExpanded4 && (
          <p>
            Our payday loans range from $100 to $1,500. Please note that there
            are a variety of factors that we consider when reviewing your payday
            loan application. In order to find out how much you qualify for, you
            will need to submit an <a href="/application">online application</a>
            .
          </p>
        )}
      </div>
    );
  };

  const BasicFAQEntry5 = () => {
    const bullet = isExpanded5 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded5 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded5(!isExpanded5);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>What
          do I need in order to get a GoDay payday loan?
        </h3>
        {isExpanded5 && (
          <>
            <p>To get a GoDay payday loan, you must:</p>
            <p>
              <ul>
                <li>be a Canadian resident over the age of 18</li>
                <li>
                  have an open bank account with a Canadian bank or credit union
                </li>
                <li>get paid by direct deposit</li>
                <li>have your own, valid email account</li>
                <li>provide us with your home or cell phone number</li>
                <li>
                  get paid by direct deposit with “recieve a steady source of
                  income”
                </li>
              </ul>
            </p>
          </>
        )}
      </div>
    );
  };

  const BasicFAQEntry6 = () => {
    const bullet = isExpanded6 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded6 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded6(!isExpanded6);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>Do I
          need to be employed?
        </h3>
        {isExpanded6 && (
          <p>
            No! We are accepting customers and funding loans on a variety of
            incomes including your employment income, Canada Child Benefit,
            Disability Tax Credit, Old Age Security, private pensions, and
            Canadian Pension Plan. Complete a fast{" "}
            <a href="/application">payday loan application</a> so we can
            determine if you qualify for an online loan!
          </p>
        )}
      </div>
    );
  };

  const BasicFAQEntry7 = () => {
    const bullet = isExpanded7 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded7 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded7(!isExpanded7);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>Can I
          get a payday loan if I am not a homeowner?
        </h3>
        {isExpanded7 && (
          <p>
            Yes. Renters are welcome. As long as you can provide a current
            address, details of a regular income, and a guarantee to repay your
            payday loan, we’re happy to consider your application.
          </p>
        )}
      </div>
    );
  };

  const BasicFAQEntry8 = () => {
    const bullet = isExpanded8 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded8 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded8(!isExpanded8);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>Do I
          need a bank account to get an online payday loan from GoDay?
        </h3>
        {isExpanded8 && (
          <p>
            Yes. To take out an online payday loan with GoDay, you must have an
            open bank account with a Canadian bank or credit union so we have
            somewhere to deposit your funds!
          </p>
        )}
      </div>
    );
  };

  const BasicFAQEntry9 = () => {
    const bullet = isExpanded9 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded9 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded9(!isExpanded9);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>What
          personal information does GoDay need?
        </h3>
        {isExpanded9 && (
          <p>
            GoDay considers personal contact information, employment
            information, and banking information when reviewing online
            applications for payday loans. All information collected is held in
            strict confidence in accordance with user information and security
            guidelines.{" "}
            <Link to="/privacy">
              View our online payday loan privacy policy
            </Link>
            .
          </p>
        )}
      </div>
    );
  };

  const BasicFAQEntry10 = () => {
    const bullet = isExpanded10 ? "remove" : "add";
    const id = `basic-faq-entry-question-${
      isExpanded10 ? "expanded" : "collapsed"
    }`;
    const onExpand = () => setIsExpanded10(!isExpanded10);

    return (
      <div className="basic-faq-entry">
        <h3 className="basic-faq-entry-question" id={id} onClick={onExpand}>
          <i className="material-icons basic-faq-entry-bullet">{bullet}</i>Can I
          visit your office to get a payday loan?
        </h3>
        {isExpanded10 && (
          <p>
            No need to visit us! All applications for payday loans are collected
            and processed online. As such, we do not accept in person
            applications or dispense cash at our office locations. If you wish
            to get an online payday loan you must complete an application{" "}
            <a href="/application">here</a>. If you have questions along the way
            about your application for an online cash advance, give us a call
            toll free at 888-984-6329.
          </p>
        )}
      </div>
    );
  };

  const BasicFAQApplyNow = () => (
    <section className="basic-faq-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  const BasicFAQInitialContent = () => (
    <section className="basic-faq-left-group">
      <BasicFAQEntry1 />
      <BasicFAQEntry2 />
      <BasicFAQEntry3 />
      <BasicFAQEntry4 />
      <BasicFAQEntry5 />
    </section>
  );

  const BasicFAQMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`basic-faq-right-group ${className}`}>
        <BasicFAQEntry6 />
        <BasicFAQEntry7 />
        <BasicFAQEntry8 />
        <BasicFAQEntry9 />
        <BasicFAQEntry10 />
      </section>
    );
  };

  return (
    <section className="basic-faq content">
      <h2 className="basic-faq-title">Frequently asked questions</h2>
      <BasicFAQInitialContent />
      <BasicFAQSeeMore />
      <BasicFAQMoreContent />
      <BasicFAQApplyNow />
    </section>
  );
};

export default BasicFAQ;
