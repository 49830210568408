import "./offices.css";

const Offices = () => {
  const OfficesToronto = () => (
    <section className="offices-address">
      <h3 className="offices-address-title">Toronto</h3>
      <div className="offices-address-text">
        500 Danforth Avenue, Unit 304, Toronto, Ontario, Canada, M4K 1P6
      </div>
      <iframe
        className="offices-address-map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1443.451659388826!2d-79.384179!3d43.6501798!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34cd94188f63%3A0x448dad4ac750093c!2s121+Richmond+St+W%2C+Toronto%2C+ON+M5H+2K1%2C+Canada!5e0!3m2!1sen!2sph!4v1439472452798"
        title="Toronto"
      ></iframe>
    </section>
  );

  const OfficesVancouver = () => (
    <section className="offices-address">
      <h3 className="offices-address-title">Vancouver</h3>
      <div className="offices-address-text">
        550 Burrard Street Suite 2300, Bentall 5, Vancouver, BC V6C 2B5
      </div>
      <iframe
        className="offices-address-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.5079884277043!2d-123.11828469999999!3d49.2857195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54867182231242cb%3A0x1ac85e846cfc76b0!2s550+Burrard+St%2C+Vancouver%2C+BC+V6C%2C+Canada!5e0!3m2!1sen!2sph!4v1439472552786"
        title="Vancouver"
      ></iframe>
    </section>
  );

  const OfficesCalgary = () => (
    <section className="offices-address">
      <h3 className="offices-address-title">Calgary</h3>
      <div className="offices-address-text">
        1600, 421 7th Avenue SW, Calgary, AB T2P 4K9
      </div>
      <iframe
        className="offices-address-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2508.3708768034107!2d-114.07421018437217!3d51.04623935213689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53716fe3337dbcff%3A0xd8b12b2308338713!2s421+7+Ave+SW+%231600%2C+Calgary%2C+AB+T2P+4K9!5e0!3m2!1sen!2sca!4v1532928134334"
        title="Calgary"
      ></iframe>
    </section>
  );

  const OfficesRegina = () => (
    <section className="offices-address">
      <h3 className="offices-address-title">Regina</h3>
      <div className="offices-address-text">
        1500-1881 Scarth Street, Regina, SK S4P 4K9
      </div>
      <iframe
        className="offices-address-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2540.5737482845566!2d-104.61267948439209!3d50.4490397953477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x531c1e46fdbb45db%3A0x19a511dc3db942b!2s1881+Scarth+St%2C+Regina%2C+SK+S4P+4K9!5e0!3m2!1sen!2sca!4v1485287165001"
        title="Regina"
      ></iframe>
    </section>
  );

  const OfficesFredericton = () => (
    <section className="offices-address">
      <h3 className="offices-address-title">Fredericton</h3>
      <div className="offices-address-text">
        77 Westmorland Street, Fredericton, NB E3B 5B4
      </div>
      <iframe
        className="offices-address-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2773.3696905519696!2d-66.64818028436122!3d45.963875179110055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ca4189920be321d%3A0x27a158beeb0141cc!2s77+Westmorland+St%2C+Fredericton%2C+NB+E3B+6Z3!5e0!3m2!1sen!2sca!4v1533011199632"
        title="Fredericton"
      ></iframe>
    </section>
  );

  const OfficesDartmouth = () => (
    <section className="offices-address">
      <h3 className="offices-address-title">Dartmouth</h3>
      <div className="offices-address-text">
        175 Main Street Suite 209, Dartmouth, NS B2X 1S1
      </div>
      <iframe
        className="offices-address-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2836.7848143370197!2d-63.53780668440256!3d44.68317367909944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b5a240e856ca259%3A0x467f33335a4519f5!2s175+Main+St%2C+Dartmouth%2C+NS+B2X+1S1!5e0!3m2!1sen!2sca!4v1533011310002"
        title="Dartmouth"
      ></iframe>
    </section>
  );

  return (
    <section className="offices content">
      <h2 className="offices-title">Address</h2>
      <div className="offices-text">
        GoDay does not accept in personal applications or dispense cash at our
        office locations. Please call 888-984-6329 if you wish to make an
        appointment to meet with a GoDay representative.
      </div>
      <OfficesToronto />
      <OfficesVancouver />
      <OfficesCalgary />
      <OfficesRegina />
      <OfficesFredericton />
      <OfficesDartmouth />
    </section>
  );
};

export default Offices;
