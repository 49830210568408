import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./header.css";

const Header = (props: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = () => setIsMenuOpen(true);
  const closeMenu = () => setIsMenuOpen(false);

  useEffect(() => {
    const resize = () => {
      const width = window.innerWidth;
      if (width >= 1000) {
        closeMenu();
      }
    };

    resize();
    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, []);

  const homeClassName = props.id === "home" ? "header-selected-page" : "";
  const howItWorksClassName =
    props.id === "how-it-works" ? "header-selected-page" : "";
  const whyUsClassName = props.id === "why-us" ? "header-selected-page" : "";
  const educationClassName =
    props.id === "education" ? "header-selected-page" : "";

  return (
    <section className="header content">
      <Link className="header-logo" to="/" />
      <div className="header-menu-horizontal">
        <nav className="header-menu-full">
          <ul>
            <li className={homeClassName}>
              <Link to="/">Our Loans</Link>
            </li>
            <li className={howItWorksClassName}>
              <Link to="/how-it-works">How It Works</Link>
            </li>
            <li className={whyUsClassName}>
              <Link to="/why-us">Why Us?</Link>
            </li>
            <li className={educationClassName}>
              <Link to="/education">Education</Link>
            </li>
            <li className="button texture">
              <a href="/application">Apply Now</a>
            </li>
          </ul>
        </nav>
        <nav className="header-menu-compact">
          <ul>
            <li>
              <a href="/login">Login</a>
            </li>
            <li className="gallery">
              {isMenuOpen ? (
                <span
                  className="header-menu-hamburger"
                  id="header-menu-hamburger-close"
                  onClick={closeMenu}
                ></span>
              ) : (
                <span
                  className="header-menu-hamburger"
                  id="header-menu-hamburger-open"
                  onClick={openMenu}
                ></span>
              )}
            </li>
          </ul>
        </nav>
      </div>
      {isMenuOpen && (
        <div className="header-menu-vertical">
          <nav className="header-menu-dropdown">
            <ul>
              <li>
                <Link to="/">Payday Loans</Link>
              </li>
              <li>
                <Link to="/installment-loans">Installment Loans</Link>
              </li>
              <li>
                <Link to="/how-it-works">How It Works</Link>
              </li>
              <li>
                <Link to="/why-us">Why Us?</Link>
              </li>
              <li>
                <Link to="/education">Finance 101</Link>
              </li>
              <li>
                <a href="/account">My Account</a>
              </li>
            </ul>
          </nav>
          <div className="header-menu-mask"></div>
        </div>
      )}
    </section>
  );
};

export default Header;
