import { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import "./basic-how-it-works.css";

const InstallmentHowItWorks = () => {
  const [slideIndex, setSlideIndex] = useState(1);

  const moveBack = () => setSlideIndex(Math.max(slideIndex - 1, 1));
  const moveForward = () => setSlideIndex(Math.min(slideIndex + 1, 3));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const BasicHowItWorksStep1 = () => (
    <section className="basic-how-it-works-step">
      <div
        className="basic-how-it-works-step-icon gallery"
        id="basic-how-it-works-step-icon-1"
      ></div>
      <h3 className="basic-how-it-works-step-title">Apply online</h3>
      <div className="basic-how-it-works-step-text">
        No paperwork or faxing. The application takes less than 5 minutes to
        complete.
      </div>
    </section>
  );

  const BasicHowItWorksStep2 = () => (
    <section className="basic-how-it-works-step">
      <div
        className="basic-how-it-works-step-icon gallery"
        id="basic-how-it-works-step-icon-2"
      ></div>
      <h3 className="basic-how-it-works-step-title">Flexible terms</h3>
      <div className="basic-how-it-works-step-text">
        With loan terms from 6 to 60 months, we will display all available
        options designed to fit your budget.
      </div>
    </section>
  );

  const BasicHowItWorksStep3 = () => (
    <section className="basic-how-it-works-step">
      <div
        className="basic-how-it-works-step-icon gallery"
        id="basic-how-it-works-step-icon-3b"
      ></div>
      <h3 className="basic-how-it-works-step-title">Speedy delivery</h3>
      <div className="basic-how-it-works-step-text">
        Once approved, we will get funds to you as soon as possible.
      </div>
    </section>
  );

  const BasicHowItWorksNavigator = () => {
    const moveBackClassName =
      slideIndex === 1 ? "navigator-first" : "navigator-previous";
    const moveForwardClassName =
      slideIndex === 3 ? "navigator-last" : "navigator-next";

    return (
      <nav className="basic-how-it-works-navigator">
        <ul className="gallery">
          <li>
            <span className={moveBackClassName} onClick={moveBack}></span>
          </li>
          <li>
            <span className={moveForwardClassName} onClick={moveForward}></span>
          </li>
        </ul>
      </nav>
    );
  };

  const BasicHowItWorksGetStarted = () => (
    <section className="basic-how-it-works-get-started button texture">
      <a href="/application">Get Started</a>
    </section>
  );

  const resize = () => setSlideIndex(1);

  useEffect(() => {
    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, []);

  return (
    <section className="basic-how-it-works content">
      <h2 className="basic-how-it-works-title">Our installment loan process</h2>
      <div className="basic-how-it-works-text">
        Use your personal loan for your big purchase or expense and repay your
        loan in affordable fixed payments that fit your budget.
      </div>
      <div className="basic-how-it-works-content" {...slide}>
        <div
          className={`basic-how-it-works-steps basic-how-it-works-steps-${slideIndex}`}
        >
          <BasicHowItWorksStep1 />
          <BasicHowItWorksStep2 />
          <BasicHowItWorksStep3 />
        </div>
      </div>
      <BasicHowItWorksNavigator />
      <BasicHowItWorksGetStarted />
    </section>
  );
};

export default InstallmentHowItWorks;
