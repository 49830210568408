import { useState } from "react";
import { Link } from "react-router-dom";
import "./installment.css";

const InstallmentLoanProcess = () => {
  const [isFullContent, setIsFullContent] = useState(false);

  const showFullContent = () => setIsFullContent(true);

  const BasicHowInstallmentWorksSeeMore = () =>
    !isFullContent ? (
      <section className="basic-installment-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons basic-installment-see-more-arrow">
          expand_more
        </i>
      </section>
    ) : null;

  const BasicHowInstallmentWorksInitialContent = () => (
    <section>
      <p>
        We understand that life happens and when you need cash, you need it
        fast. At GoDay, we are a licensed online lender, a proud member of your
        community, and we care about you as our customer. Try our services and
        let us show you why Canadians turn to GoDay when they need help bridging
        the gap.
      </p>
    </section>
  );

  const BasicHowInstallmentWorksMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`basic-installment-group ${className}`}>
        <h3 className="basic-installment-subtitle">
          Speedy. Smart. Secure. Get your installment loan with GoDay the GoDay
          way.
        </h3>
        <div className="basic-installment-text">
          <p>
            <strong>Step 1</strong> – You have done your homework and know that
            an installment loan is right for you. From there, you will submit a
            GoDay installment loan application online.
          </p>
          <p>
            <strong>Step 2</strong> – Once you have submitted your application,
            we will let you know if you have been pre-approved. Installment loan
            applications are accepted and processed instantly by our automated
            system 24/7. Should we need any additional information from you, our
            system will let you know and the GoDay team will be in touch with
            you by phone or email. Once your application has been approved, you
            will work with the GoDay team to specify the terms and conditions of
            your installment loan, including how you would like your funds
            delivered and how much you would like to borrow.
          </p>
          <p>
            <strong>Step 3</strong> – We are here to help you with your
            installment loan every step of the way. If you have questions or
            concerns before or after applying, just reach out to one of our
            friendly customer service representatives. They’re standing by at
            888-984-6329 or by email{" "}
            <a href="mailto:customerservice@goday.ca">
              customerservice@goday.ca
            </a>
            . Please feel free to <Link to="/contact">contact us</Link> for
            additional information.
          </p>
          <p>
            <strong>Step 4</strong> – At GoDay, what you see is what you pay. No
            one likes secrets when it comes to your money, that is why at GoDay
            all installment loan pricing is disclosed to you on your loan
            agreement (subject to a review of your application). Before giving
            you money, we promise to disclose the full terms of your installment
            loan, including loan interest, loan fees, and any penalties you’ll
            be required to pay should you default on your installment loan and
            this information will be disclosed to you before you sign your loan
            agreement.
          </p>
          <p>
            <strong>Step 5</strong> – Once you are approved and have signed with
            us, we will advance your cash using the fastest tools in the
            industry to ensure you get the speediest service possible. On the
            agreed repayment date, we will then collect funds owing directly
            from your bank account. It’s that simple.
          </p>
        </div>
      </section>
    );
  };

  return (
    <section className="basic-installment-block content">
      <h2 className="basic-installment-title">
        How a GoDay Installment Loan works?
      </h2>
      <div className="basic-installment-text">
        <BasicHowInstallmentWorksInitialContent />
        <BasicHowInstallmentWorksSeeMore />
      </div>
      <BasicHowInstallmentWorksMoreContent />
    </section>
  );
};

export default InstallmentLoanProcess;
