import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import "./location.css";

const MainCharlottetown = () => {
  const [reasonIndex, setReasonIndex] = useState(1);
  const [isFullContent, setIsFullContent] = useState(false);

  const moveBack = () => setReasonIndex(Math.max(reasonIndex - 1, 1));
  const moveForward = () => setReasonIndex(Math.min(reasonIndex + 1, 5));

  const slide = useSwipeable({
    onSwipedRight: moveBack,
    onSwipedLeft: moveForward,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const MainCharlottetownReasons = () => {
    const upArrowClassName = reasonIndex > 1 ? "enabled" : "disabled";
    const downArrowClassName = reasonIndex < 5 ? "enabled" : "disabled";

    const MainCharlottetownReason1 = () =>
      reasonIndex === 1 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #1</h3>
          <div className="location-reason-text">
            GoDay take privacy and security seriously and use the most advanced
            industry practices to make sure your personal information is never
            shared and only used with your authorization.
          </div>
        </div>
      ) : null;

    const MainCharlottetownReason2 = () =>
      reasonIndex === 2 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #2</h3>
          <div className="location-reason-text">
            As a trusted name for online payday loans in Charlottetown and coast
            to coast across Canada, GoDay has an A+ accreditation rating from
            the Better Business Bureau. Want to hear from other GoDay customers
            about our service? Take a look at our hundreds of reviews on
            TrustPilot before applying for a loan with us.
          </div>
        </div>
      ) : null;

    const MainCharlottetownReason3 = () =>
      reasonIndex === 3 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #3</h3>
          <div className="location-reason-text">
            Why walk into a retail store for a payday loan in Charlottetown
            again? GoDay is 100% online and our automated system will provide
            you with a decision instantly so you can have peace of mind knowing
            that financial assistance is on the way. Take a look at our{" "}
            <a href="/application">online payday loan application</a>.
          </div>
        </div>
      ) : null;

    const MainCharlottetownReason4 = () =>
      reasonIndex === 4 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #4</h3>
          <div className="location-reason-text">
            Our customer service team is ready to help you. GoDay is a virtual
            company, but any time you phone our customer service line you’ll be
            speaking with a real person from our office who will help you with
            any questions you may have about your loan or process along the way.
          </div>
        </div>
      ) : null;

    const MainCharlottetownReason5 = () =>
      reasonIndex === 5 ? (
        <div className="location-reason-group clearfix">
          <h3 className="location-reason-title">Reason #5</h3>
          <div className="location-reason-text">
            No matter where you’re located in Prince Edward Island, from
            downtown Charlottetown or off the coast of the Atlantic, GoDay
            offers speedy, smart and secure online payday loans.
          </div>
        </div>
      ) : null;

    return (
      <section className="location-reasons clearfix" {...slide}>
        <div className="location-reasons-icon">
          <div className="location-reasons-icon-disk">
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${upArrowClassName}`}
              onClick={moveBack}
            >
              <i className="material-icons">arrow_drop_up</i>
            </div>
            <div className="location-reasons-icon-numbers">
              <span className="location-reasons-icon-index">{reasonIndex}</span>
              <span className="location-reasons-icon-total">/5</span>
            </div>
            <div
              className={`location-reasons-icon-arrow location-reasons-icon-arrow-${downArrowClassName}`}
              onClick={moveForward}
            >
              <i className="material-icons">arrow_drop_down</i>
            </div>
          </div>
        </div>
        <MainCharlottetownReason1 />
        <MainCharlottetownReason2 />
        <MainCharlottetownReason3 />
        <MainCharlottetownReason4 />
        <MainCharlottetownReason5 />
        <div className="location-reasons-security gallery">
          <span></span>
        </div>
      </section>
    );
  };

  const MainCharlottetownOffer1 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-1"
      ></div>
      <h3 className="location-offer-title">
        <strong>24/7 immediate decisions</strong>
        <br />
        <small>for your online payday loan application</small>
      </h3>
    </section>
  );

  const MainCharlottetownOffer2 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-2"
      ></div>
      <h3 className="location-offer-title">
        <strong>Top-level encrypted online processing</strong>
        <br />
        <small>of your online payday loan application</small>
      </h3>
    </section>
  );

  const MainCharlottetownOffer3 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-3"
      ></div>
      <h3 className="location-offer-title">
        <strong>Transparent fee disclosure</strong>
        <br />
        <small>so you know what you’re getting</small>
      </h3>
    </section>
  );

  const MainCharlottetownOffer4 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-4"
      ></div>
      <h3 className="location-offer-title">
        <strong>Quick cash</strong>
        <br />
        <small>
          so you can get your payday loan funds and get back to life
        </small>
      </h3>
    </section>
  );

  const MainCharlottetownOffer5 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-5"
      ></div>
      <h3 className="location-offer-title">
        <strong>Zero paperwork or faxing needed</strong>
        <br />
        <small>to get a payday loan online</small>
      </h3>
    </section>
  );

  const MainCharlottetownOffer6 = () => (
    <section className="location-offer">
      <div
        className="location-offer-icon gallery"
        id="location-offer-icon-6"
      ></div>
      <h3 className="location-offer-title">
        <strong>Government licensed</strong>
        <br />
        <small>to issue online payday loans in Prince Edward Island</small>
      </h3>
    </section>
  );

  const MainCharlottetownSeeMore = () => {
    const showFullContent = () => setIsFullContent(true);

    return !isFullContent ? (
      <section className="location-see-more" onClick={showFullContent}>
        See More{" "}
        <i className="material-icons location-see-more-arrow">expand_more</i>
      </section>
    ) : null;
  };

  const MainCharlottetownInitialContent = () => (
    <section>
      <p>
        When it comes to getting a GoDay payday loan, it’s speedy, smart and
        secure. The first step is to head to our website and complete our quick
        and easy online payday loan application. Once you have provided all your
        correct information - personal, banking, employment, and payroll details
        into our online payday loan application form, our automated system will
        let you know instantly if you are approved.
      </p>
      <p>
        Once your online payday loan application process has been completed, our
        customer service team will reach out to help set up your account and
        discuss how funding works. Your application will be reviewed by our
        automated and online cash advance system to determine how much we can
        approve you for. For all Charlottetown residents, online payday loans
        are regulated by the province of Prince Edward Island and as a licensed
        payday lender, we can offer online payday loans in Charlottetown ranging
        from $100 to $1,500.
      </p>
    </section>
  );

  const MainCharlottetownMoreContent = () => {
    const className = isFullContent ? "" : "hidden";

    return (
      <section className={`location-group ${className}`}>
        <p>
          Once you have reviewed and signed your online payday loan documents,
          we will use the industry’s fastest tools to ensure your payday loan is
          delivered as quickly as possible. Depending on the time of day that we
          received your signed loan agreement and the business hours of your
          financial institution, your payday loan will either be delivered to
          you in the same day or the next morning. Yes, within 24 hours you will
          have the cash you need. From there, a GoDay representative can provide
          you all the necessary information once your online payday loan is
          approved.
        </p>
        <p>
          No matter where you are in Prince Edward Island, GoDay can provide you
          with an online payday loan on the spot! Join the thousands of
          Canadians from coast to coast that trust GoDay for their online payday
          loan needs. Enjoy the history of Charlottetown and a mix of the city
          and island life all while knowing GoDay can provide you with a
          financial solution quickly and easily.
        </p>
        <p>
          Want to learn more about us? Learn more about what our customers have
          to say about us by checking out some of our{" "}
          <a
            href="https://www.trustpilot.com/review/goday.ca?utm_medium=Trustbox&amp;utm_source=Carousel"
            target="_blank"
            rel="noopener noreferrer"
          >
            TrustPilot reviews
          </a>
          .
        </p>
      </section>
    );
  };

  const MainCharlottetownApplyNow = () => (
    <section className="location-apply-now button texture">
      <a href="/application">Apply Now</a>
    </section>
  );

  return (
    <main className="location content">
      <h1 className="location-title">
        Get a fast online payday loan in Charlottetown
      </h1>
      <div className="location-text">
        <p>
          Everyday Canadians are facing financial difficulties. Which is why
          GoDay is here to help. We understand that unexpected costs can come at
          any time and we provide financial solutions that Canadians trust. Now
          operating in Charlottetown, whether you are looking for financial
          assistance until your next paycheque or need help with a financial
          emergency, learn more about how our online payday loans in
          Charlottetown are helping people like you.
        </p>
        <p>Getting a payday loan in Charlottetown could not be any simpler!</p>
        <p>
          Since 2012, GoDay has been offering financial solutions to Canadians.
          And the best part? We’re all online! This means, you can apply for a
          payday loan with us from virtually anywhere and don’t have to go a
          retail store to apply for a loan again. Our online loan applications
          take a few minutes to complete as we offer one of the fastest payday
          loan services in Canada. We accept online payday loan applications
          24/7 and our automated system will keep you connected to ensure you
          receive your funds as soon as possible.
        </p>
        <p>
          We make payday loans in Charlottetown quick and easy so you can get
          back to what matters.
        </p>
      </div>
      <div>
        <h2 className="location-subtitle">
          Why you should choose GoDay for payday loans in Charlottetown
        </h2>
        <MainCharlottetownReasons />
        <div className="location-offers">
          <div className="location-offers-title">
            <strong>
              So what are you waiting for? Apply for a payday loan online. We
              make it simple.
            </strong>
          </div>
          <div className="location-offers-text">
            We’re provincially licensed to issue payday loans in Charlottetown.
            We make it simple for you to access a payday loan by offering:
          </div>
          <MainCharlottetownOffer1 />
          <MainCharlottetownOffer2 />
          <MainCharlottetownOffer3 />
          <MainCharlottetownOffer4 />
          <MainCharlottetownOffer5 />
          <MainCharlottetownOffer6 />
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          You may need an payday loan if you live in Charlottetown
        </h2>
        <div className="location-text">
          <p>
            As the largest city in Prince Edward Island, Charlottetown plays a
            historic role in Canada. But as the cost’s everyday things such as
            food and housing continue to rise throughout all of Canada, you can
            turn to a payday loan with GoDay.
          </p>
          <p>
            Charlottetown is known for so many historical things! From Oyster
            tonging with experts to Cruising on the Charlottetown harbour as the
            sun goes down, there is always something to see and do around
            Charlottetown. And GoDay is here to help you enjoy all of these
            things with our online payday loan services, so you can get back to
            what really matters.
          </p>
        </div>
      </div>
      <div>
        <h2 className="location-subtitle">
          So how do you get an online payday loan in Charlottetown?
        </h2>
        <div className="location-details location-text">
          <MainCharlottetownInitialContent />
          <MainCharlottetownSeeMore />
          <MainCharlottetownMoreContent />
          <MainCharlottetownApplyNow />
        </div>
      </div>
    </main>
  );
};

export default MainCharlottetown;
